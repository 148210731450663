import React, { useState, useRef, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { ImageContainer } from '../shared/ImageContainer'
import { useParams, useLocation } from "react-router-dom";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BASE_URI } from '../shared/Constants';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import { MakeContext } from '../shared/contexts/MakeContext'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(() => ({
    singleHeader: {
        padding: '10rem 0'
    },
    background: {
        backgroundColor: '#dcdcdc !important',
        padding: '0px !important',
        margin: '0px !important',
    },
    largeButton: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        '& h6': {
            fontSize: '28.8px'
        }
    },
    buttonIconLg: {
        fontSize: '5rem',
        color: '#39679f'
    },
    sectionPadding: {
        margin: '3rem 0'
    },
    paragraphPadding: {
        color: '#707070 !important',
        fontSize: '20px !important',
        paddingLeft: '3rem !important',
        paddingBottom: '3rem !important',
        paddingRight: '0px !important',
        paddingTop: '0px !important'
    },
    paragraphPaddingLeft: {
        paddingLeft: '10px !important',
        paddingBottom: '3rem !important',
        paddingRight: '0px !important',
        paddingTop: '0px !important',
        color: '#fff !important'
    },
    heading: {
        textTransform: 'capitalize',
        paddingLeft: '.5rem',
        fontWeight: '700',
        display: 'inline-block',
        marginTop: '1rem',
    },
    blueHeading: {
        color: '#6aa6d8 !important',
    },
    primaryHeading: {
        color: `#2b356e !important`,
    },
    whiteHeading: {
        color: '#fff !important',
    },
    borderLeft: {
        borderLeft: `50px solid #6aa6d8 !important`,
    },
    marginLeftBorder: {
        marginLeft: `50px !important`,
    },
    primaryButton: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    },
    yellowBorder: {
        borderBottom: ({ primaryColor }) => `5px solid ${primaryColor} !important`,
    },
    secondaryHeading: {
        textTransform: 'uppercase',
        paddingBottom: '1rem',
        fontWeight: '400'
    },
    slideMargin: {
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 20px)',
        left: '10px',
        top: '10px',
    },
    topHeader: {
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '2.5rem'
    },
    yellow: {
        color: ({ primaryColor }) => `${primaryColor} !important`
    },
    specs: {
        '& .specs': {
            borderBottom: '0 !important',
            marginTop: '-25px'
        },
    },
    formControl: {
        minWidth: 400,
        '@media (max-width: 450px)': {
            minWidth: '100%'
        }
    },
    headerLine: {
        height: '1.5px',
        width: '50%',
        backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
        padding: '0 !important'
    },
    smallHeading: {
        fontSize: '1.3rem'
    },
    stepPaddingColours: {
        margin: "1em",
        display: 'flex',
        width: '25%',
        flexDirection: 'column',
        alignItems: 'center'
    },
    colorsContainer: {
        display: 'flex',
        flexWrap:'wrap',
     
    },
    colourName: {
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        marginTop: '0.6rem',
        width: '90%',
        textAlign: 'center',
    },
    colourPrice: {
        fontWeight: 700
    },
    stepOneButtons: {
        display: 'flex',
        '@media (max-width: 500px)': {
            flexDirection: 'column',
            alignItems: 'center',
            '& > a': {
                marginLeft: '0 !important',
                marginRight: '0 !important'
            }
        }
    },
    topDetailSection: {
        '@media screen and (max-width: 959px)': {
            display: 'flex',
            flexDirection: 'column',
            '& > div:first-child': {
                order: 2,
                marginTop: '2rem'
            },
            '& > div:nth-child(2)': {
                order: 3
            },
            '& > div:nth-child(3)': {
                order: 4
            },
            '& > div:nth-child(4)': {
                marginTop: '-1.5rem'
            }
        }
    },
    largeButtonHolder:{
        position: 'relative',
        display:'flex',
        flex: 1,
        flexDirection:'row',
        width: '100%',
        flexWrap: 'wrap'
  
    },
    largeButtonDownload:{
        color: '#2a356c !important',
        backgroundColor: '#fff !important',
        margin:'15px',
        width: '200px',
        borderRadius: '20px !important',
        border: '1px solid #2a356c !important',
        fontWeight:'bold',
    },
    activebtn:{
        background:"#39679f",
        color:'white',
        fontWeight:'bold',
        '&:hover': {
            background:'#39679fe6',
        },
    },
    backgroundImage: {
        backgroundImage: ({HeroImage}) => `linear-gradient(rgba(121, 121, 121, 0), rgba(121, 121, 121, 0)), 
        url(${HeroImage})`,
        width: '100vw',
        height: '80vh',
        display: 'flex',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingBottom: '20px',
    },
    greyBackground: {
        backgroundColor: '#dcdcdc !important'
    },
    blueBackground: {
        background: `linear-gradient(#2a356c, #181f3f) !important`,
        display: 'flex',
        flexDirection: 'column'
    },
    blueBackgroundBottom: {
        background: `linear-gradient(#181f3f,#181f3f) !important`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    darkGrayBackground: {
        backgroundColor: '#c4c4c4 !important'
    },
    buttonInvert: {
        width: '200px',
        height: '42px',
        bottom: '0px',
        right: '0px',
        borderRadius: '35px !important',
        fontWeight:'normal!important',
        textAlign: 'center',
        justifyContent: "center",
        alignContent: 'flex-end',
        color: `#2e3975 !important`,
        backgroundColor:"#fff !important",
        boxShadow: 'none',
        textTransform:'none',
        fontSize: 16,
        padding: '6px 6px',
        marginRight: '60px',
        border: '3px solid',
        lineHeight: 1.5,  
        borderColor: '#2e3975',
        '&:hover': {
            backgroundColor: '#2e3975 !important',
            borderColor: '#fff',
            boxShadow: 'none',
            color: '#fff !important'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#fff',
            borderColor: '#fff',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.1rem rgba(46,57,117,.5)',
        },
    },
}));

export default function CarProfileContainer() {
    let { carId, modelId, dealId } = useParams();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const { make, makeColors } = useContext(MakeContext);
    const [loading, setLoading] = useState(true);
    const mountedRef = useRef(true);
    const { enqueueSnackbar } = useSnackbar();
    const [financeOptions, setFinanceOptions] = useState([]);
    const [financeDetails, setFinanceDetails] = useState({});
    const [modelInfo, setModelInfo] = useState(null);
    const [modelData, setModelData] = useState(null);
    const [variantId, setVariantId] = useState(0);
    const colorData  = [];
    const [selectedColor, setSelectedColor] = React.useState();
    const colorId = 0;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })

    let { setGlobalFinance, setGlobalVehicle, setGlobalColor, setGlobalVariantId } = useContext(FinanceContext);

    const calculateMonthly = (data) => {
        var deposit = data?.purchasePrice * (data?.deposit / 100);
        var totalPrice = data?.purchasePrice;
        var initFee = 0;
        var principal = totalPrice - deposit + initFee;

        var balloonPerc = data?.balloonPayment || 0;

        var balloonAmt = totalPrice * balloonPerc / 100;

        var interestRate = data?.rate || 0;
        var interestPM = interestRate / 100 / 12;

        var repaymentPeriod = data?.term || 0;
        var days = 1;

        var v = 1 / (1 + interestPM);
        var d = 1 - v;
        var y = Math.pow(v, repaymentPeriod - 1);
        var comp = (1 - y) / d;
        var fp = principal * (Math.pow(1 + interestPM, (days / (365 / 12).toFixed())));
        var monthly = 0;

        if (parseInt(balloonAmt) > 0) {
            var comp = (1 - (Math.pow(v, repaymentPeriod - 1))) / d;
            monthly = (fp - (balloonAmt * Math.pow(v, repaymentPeriod - 1))) / comp;
        }
        else {
            var comp = (1 - (Math.pow(v, repaymentPeriod))) / d;
            monthly = fp / comp;
        }

        return round(monthly) + initFee;
    }

    function round(x) {
        return Math.round(x * 100) / 100;
    }

    useEffect(() => {

        if (colorId == 0) {
            return
        }

        var tmpFinanceDetails;
        var color = colorData.filter(itm => itm.id == colorId)[0];

        if (color.price > 0) {
            tmpFinanceDetails = { ...financeDetails, purchasePrice: color.price };
        }
        else {
            tmpFinanceDetails = { ...financeDetails, purchasePrice: modelData.price };
        }

        let monthly = calculateMonthly(tmpFinanceDetails);

        tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };
        
        setFinanceDetails(tmpFinanceDetails);
        setGlobalFinance(tmpFinanceDetails);
        setGlobalColor(color);
    }, [colorId]);

    useEffect(() => {
        
        if (!modelId) {
            setLoading(false);
            return;
        }

        const CancelToken = axios.CancelToken;
        setLoading(true)
        const source = CancelToken.source();

        var getVariantData = axios.get(`${BASE_URI}/dealervariants?dealerModelId=${modelId}`, {
            cancelToken: source.token
        }).then(result => {            

            let data = result.data.list
            setVariantId(data[0].id);
            setModelData({ price: data[0].modelPrice, id: data[0].modelId });
        });

        var getModelInfo = axios.get(`${BASE_URI}/DealerModelInformation/GetForDealerModel/${modelId}`, {
            cancelToken : source.token
        }).then(result => {

            if (result?.data) {
                setModelInfo(result?.data);
            }

        });

        Promise.allSettled([
            getVariantData,
            getModelInfo
        ]).then(() => {
          
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
        
    }, [carId]);

    useEffect(() => {

        if (!variantId || variantId === 0) {
            return;
        }

        setGlobalVariantId(variantId);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();


    }, [variantId])

    useEffect(() => {
        
        if (!modelData || !modelInfo)
            return;

        const CancelToken = axios.CancelToken;
        setLoading(true)
        const source = CancelToken.source();
        const getOffer = async () => {
            try {
                setLoading(false)
                const result = await axios.get(`${BASE_URI}/Offers/${dealId}`, {
                    cancelToken: source.token
                })
                let data = result.data

                let tmpFinanceOptions = []

                tmpFinanceOptions.push({
                    variant: result.data.variant,
                    variantId: result.data.variantId,
                    monthlyInstallment: result.data.monthlyInstallment,
                    id: result.data.id,
                    model: result.data.model
                })

                setFinanceOptions(tmpFinanceOptions)

                let tmpFinanceDetails = {
                    purchasePrice: data.price,
                    monthlyInstallment: data.monthlyInstallment,
                    term: data.term,
                    rate: data.rate,
                    deposit: data.deposit,
                    balloonPayment: data.balloonPayment,
                    termsAndConditions: data.termsAndConditions,
                }

                setFinanceDetails(tmpFinanceDetails)
                setGlobalFinance(tmpFinanceDetails)

            } catch (error) {
                setLoading(false)
                enqueueSnackbar("Unable to get offer details", { variant: 'error' });
            }
        }
        
        setGlobalVehicle(modelInfo)

        if (dealId) {

            getOffer().then(() => {

                window.scrollTo(0, 0);

                setLoading(false)

                if (!mountedRef.current) return null
            })
        } else {

           

            let financeDetails = {
                deposit: modelInfo?.dealer.deposit,
                rate: modelInfo?.dealer.offerInterestRate,
                balloonPayment: modelInfo?.dealer.balloonPayment,
                term: 72,
                purchasePrice: modelData.price
            };

            let monthly = calculateMonthly(financeDetails);
            
            financeDetails.monthlyInstallment = monthly || 0

            let financeOptions = {
                id: 0,
                modelId: modelData.id,
                monthlyInstallment: monthly || 0
            };

            setFinanceDetails(financeDetails)
            setGlobalFinance(financeDetails)
            setFinanceOptions(financeOptions)

            setLoading(false)
        }

        return () => {
            mountedRef.current = false
            source.cancel();
        };
    }, [modelData, modelInfo])


    const getMainImage = () => {

        var image = modelInfo?.contentImages?.find(img => img.imageName.toLowerCase().trim() == "main")?.image?.imageUrl;

        if (selectedColor) {
            image = selectedColor.image;
        }

        return image;
    }

    const HeroImage = getMainImage()

    const classes = useStyles({ ...makeColors, HeroImage});

    const getSmallImage = () => {

        var image = modelInfo?.contentImages?.find(img => img.imageName.toLowerCase().trim() == "small")?.image?.imageUrl;

        return image;
        
    };

    const getMedium1Image = () => {

        var image = modelInfo?.contentImages?.find(img => img.imageName.toLowerCase().trim() == "medium1")?.image?.imageUrl;

        return image;
        
    };

    const getMedium2Image = () => {

        var image = modelInfo?.contentImages?.find(img => img.imageName.toLowerCase().trim() == "medium2")?.image?.imageUrl;

        return image;
        
    };

    const splitTitle = (title) => {
        const titleArr = title.split(' ')

        let remainingTitle = ''
        titleArr.map((word, index) => {
            if(index > 0){
                remainingTitle += ` ${word}`
            }
        })
        return ([titleArr[0], remainingTitle])
           
    }

    return (
        <React.Fragment>
            <div className={classes.backgroundImage}>
            <Button 
                        variant="contained"
                        className={classes.buttonInvert} 
                        size="large" 
                        href="/contact-us"
                    >
                        ENQUIRE NOW
                    </Button>
            </div>
            <Container maxWidth="false" style={{padding:"0px", background: 'linear-gradient(0deg, rgba(174,174,174,1) 0%, rgba(207,207,207,1) 40%, rgba(223,223,223,1) 100%)',}}>
                    {
                        loading &&
                        <Grid item container justifyContent='center' justify='center' alignContent='center' xs={12}>
                            <CircularProgress size={80} />
                        </Grid>
                    }
                    {
                        !loading && !modelInfo &&
                        <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" className={classes.singleHeader}>
                            <Box mb={5} className={classes.topHeader}>
                                {(modelId > 0 ? "Please load brochure information in DASH" : "No linked dealer model found")}
                            </Box>
                        </Typography>
                    }
                    {
                        !loading && modelInfo &&
                        <div className={classes.background}>
                            <Grid container spacing={4} className={`${classes.greyBackground}`}>
                                <Grid item container alignContent='left' justify='left' xs={12} className={`${classes.greyBackground}`}>
                                    <Typography 
                                        className={`${classes.heading} ${classes.borderLeft} ${classes.primaryHeading}`} 
                                        align='left'
                                        id="discover"
                                        gutterBottom 
                                        variant="h3" 
                                        fontWeight="500"
                                        >
                                        {make.name} {params.get("title")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className={`${classes.greyBackground}`}>
                                    <Typography className={classes.paragraphPadding}>
                                        {modelInfo?.introductionDesc}
                                    </Typography>
                                </Grid>

                                
                                <Grid item xs={12} md={4} className={classes.blueBackground} style={{ padding: '20px 20px 20px 40px',  display: 'flex', justifyContent:  'center', alignItems: 'center'}}>
                                    <div>
                                        <ImageContainer width='100%' src={getSmallImage()} alt='SmallImage' style={{  borderRadius: '15px',  width: 300, height: 280}}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={8} container className={isTabletOrMobile ? classes.blueBackgroundBottom : classes.blueBackground } >
                                    <div>
                                <Typography 
                                        className={`${classes.heading} ${classes.blueHeading}`} 
                                        align='left'
                                        id="discover"
                                        gutterBottom 
                                        variant="h3" 
                                        fontWeight="500"
                                        style={{color:  '#6aa6d8 !important'}}
                                        >
                                        {modelInfo?.contentTitle1 ? splitTitle(modelInfo?.contentTitle1)[0] : 'Click Here'}
                                    </Typography>
                                    <Typography 
                                        className={`${classes.heading} ${classes.whiteHeading}`} 
                                        align='left'
                                        id="discover"
                                        gutterBottom 
                                        variant="h3" 
                                        fontWeight="500"
                                        >
                                        {modelInfo?.contentTitle1 ? splitTitle(modelInfo?.contentTitle1)[1] : "for more info"}
                                    </Typography>
                                    <Typography className={`${classes.paragraphPadding} ${classes.paragraphPaddingLeft}`}>
                                        {modelInfo?.contentDesc1}
                                    </Typography>
                                    </div>
                                    <div className={classes.largeButtonHolder} style={{ width: 'initial', justifyContent: isTabletOrMobile ? 'center': 'flex-start', alignItems: isTabletOrMobile ? 'center': 'flex-start',}}>
                                        {modelInfo?.brochures?.length > 0 && modelInfo.brochures.map((brochure, index) => (
                                            <Box mb={3} mt={3}>
                                                <Button className={classes.largeButtonDownload} variant="contained" gutterBottom onClick={() => {
                                                    window.open(brochure.document?.filePath, '_blank')
                                                }}>
                                                    Download Brochure {index + 1}
                                                </Button>
                                            </Box>
                                        ))}
                                        <Box mb={3} mt={3}>
                                            <Link href='/apply-for-finance' style={{ width: '100%' }}>
                                                <Button className={classes.largeButtonDownload} gutterBottom variant="contained" >
                                                    Apply for Finance
                                                </Button>
                                            </Link>
                                        </Box>
                                    </div>
                                </Grid>


                               {modelInfo?.contentTitle2?.length > 0 && 
                               <>
                                <Grid item xs={12} md={8} container className={classes.darkGrayBackground} >
                                    <Typography  
                                        className={`${classes.heading} ${classes.marginLeftBorder} ${classes.primaryHeading}`} 
                                        align='left'
                                        id="discover"
                                        gutterBottom 
                                        variant="h3" 
                                        fontWeight="500"
                                        >
                                            {modelInfo?.contentTitle2}
                                        </Typography>
                            
                                        <Typography className={`${classes.paragraphPadding}`}>
                                            {modelInfo?.contentDesc2}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={4} className={classes.darkGrayBackground} style={{ padding: '20px 20px 20px 40px'}}>
                                        <ImageContainer height='100%' src={getMedium1Image()} alt='SmallImage' style={{  borderRadius: '15px',  width: 340, height: 280}}/>
                                    </Grid>
                                </>}
                                

                                {modelInfo?.contentTitle3?.length > 0 && 
                                <>
                                <Grid item xs={12} md={4} className={classes.blueBackground} style={{ padding: '20px 20px 20px 40px'}}>
                                    <ImageContainer aspectRatio={'340/280'} src={getMedium2Image()} alt='SmallImage' style={{  borderRadius: '15px', width: 340, height: 280}}/>
                                </Grid>
                                <Grid item xs={12} md={8} container className={classes.blueBackground} >
                                <Typography 
                                        className={`${classes.heading} ${classes.blueHeading}`} 
                                        align='left'
                                        id="discover"
                                        gutterBottom 
                                        variant="h3" 
                                        fontWeight="500"
                                        style={{color:  '#6aa6d8 !important'}}
                                        >
                                        {modelInfo?.contentTitle3 ? splitTitle(modelInfo?.contentTitle3)[0] : 'Heading'}
                                    </Typography>
                                    <Typography 
                                        className={`${classes.heading} ${classes.whiteHeading}`} 
                                        align='left'
                                        id="discover"
                                        gutterBottom 
                                        variant="h3" 
                                        fontWeight="500"
                                        >
                                        {modelInfo?.contentTitle3 ? splitTitle(modelInfo?.contentTitle3)[1] : "Three"}
                                    </Typography>
                                    <Typography className={`${classes.paragraphPadding} ${classes.paragraphPaddingLeft}`}>
                                        {modelInfo?.contentDesc3}
                                    </Typography>
                                
                                </Grid>
                                </>
                                }
                            </Grid>
                        </div>
                    }
                </Container>
        </React.Fragment>
    );
}