import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI, DEALERID, MASTER_DEALERID, MOTORGROUPID } from "./Constants"
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import { Switch, Checkbox, RadioGroup } from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { FinanceContext } from './contexts/FinanceContext';
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { DealerContext } from './contexts/DealerContext'
import { MakeContext } from './contexts/MakeContext'
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingBelow: {
        marginRight: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingButton: {
        marginTop: "0.5rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    flex: {
        display: "flex"
    },
    button: {
        width: "100%",
        background: 'black',
        color: 'white',
        fontWeight: 'bold',
        '&:hover': {
            background: '#000000d1',
        },
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        minWidth: "100%",
        height: 550,
    },
    uppercase: {
        textTransform: 'uppercase',
        color: 'red'
    },
    activebtn: {
        background: "#39679f",
        color: 'white',
        fontWeight: 'bold',
        width: "100%",
        '&:hover': {
            background: '#39679fe6',
        },
    }
}));

export default function GetInfoContainer(props) {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { globalDealer ,dealerList} = useContext(DealerContext);
    const { make } = useContext(MakeContext);
    const { globalFinance, setGlobalLead, globalColor, globalModel } = useContext(FinanceContext);

    const [creditCheck, setCreditCheck] = useState(false)
    const [dealers, setDealers] = useState([])
    const [popiCheck, setPopiCheck] = useState(false)
    const [licenseCheck, setLicenseCheck] = useState(false)
    const [debtReview, setDebtReview] = useState(false)
    const [preQualSuccess, setPreQualSuccess] = useState(0)
    const [loader, setLoader] = useState(false)

    const idRegex = `(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))`
    const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

    const initialFormValues = {
        id: "",
        name: "",
        lastName: "",
        email: '',
        phone: '',
        netSalary: "",
        totalExpenses: "",
        dealerId: globalDealer?.id !== DEALERID ? globalDealer?.id : '',
        dealer: globalDealer?.id !== DEALERID ? globalDealer?.name : ''
    }

    const validationSchema = Yup.object({
        id: Yup.string("Enter an ID number").required(
            "ID number is required"
        ).matches(idRegex, "ID Number invalid"),
        name: Yup.string("Enter a name").required(
            "Name is required"
        ),
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        phone: Yup.string("Enter your phone number")
            .required("Phone number is required")
            .matches(phoneRegExp, "Phone number is not valid")
    });

    const checkValid = (validForm) => {
        return (validForm && creditCheck && licenseCheck && !debtReview && popiCheck)
    }

    function createLead(values) {

        var sourceId = 5;

        if (document.referrer) {
            if (document.referrer.indexOf("facebook") > -1) {
                sourceId = 2;
            }
        }

        setLoader(true);

        let params = {}

        params.leadStatusId = 1
        params.dealerId = values.dealerId || globalDealer.id;
        params.name = values.name + ' ' + values.lastName
        params.firstName = values.name;
        params.lastName = values.lastName;
        params.phoneNumber = values.phone
        params.leadSourceId = sourceId
        params.TypeCode = "NEW"
        params.leadTypeId = 1
        params.notes = ""

        if (globalModel && globalModel.stockId) {
            params.stockId = globalModel.stockId;
            params.leadTypeId = 2;
        }

        let m = new Date()
        m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
        params.dateUpdated = new Date().toISOString();

        params.emailAddress = values.email
        setGlobalLead(params);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${BASE_URI}/leads`,
            data: params,
            cancelToken: source.token
        }).then((response) => {
            values.leadId = response.data.id;

            if (globalModel && globalModel.variantId) {
                createVehicleLead(values);
            }
            else {
                submitForPQ(values);
            }

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to complete the request", { variant: 'error' });
            setLoader(false);

        })
    }

    const createVehicleLead = (values) => {

        let vehicleParam = {}
        vehicleParam.LeadId = values.leadId;
        vehicleParam.VariantId = globalModel ? globalModel.variantId : 0;
        vehicleParam.OwnedVariantExteriorsId = globalColor ? globalColor.id : 0

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${BASE_URI}/leadVehicles`,
            data: vehicleParam,
            cancelToken: source.token
        }).then((responseSecond) => {
            submitForPQ(values);
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get complete the request", { variant: 'error' });
            //setLoader(false);
        })
    }

    function submitForPQ(values) {

        let params = {}
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        params.idNumber = values.id;
        params.leadId = parseInt(values.leadId);
        params.netSalary = parseInt(values.netSalary);
        params.totalExpenses = parseInt(values.totalExpenses);
        params.desiredAmount = globalFinance ? globalFinance.purchasePrice : 162900;
        params.underDebtReview = debtReview;
        params.licenseCheck = licenseCheck;

        axios({
            method: 'POST',
            url: `${BASE_URI}/applications/pq`,
            data: params,
            cancelToken: source.token
        }).then((response) => {

            fbq('track', 'Lead');  // eslint-disable-line
            setLoader(false);

            window.location.href = response.data;

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar(`Unable to complete request. ${error}`, { variant: 'error' });
            setLoader(false);
        })
    }

    useEffect(() => {

        let url = `${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`;

        if (make) {
            url += '/' + make.id;
        }

        axios.get(url, {}).then((result) => {

            var filtered = result.data.filter(itm => itm.dealerId != MASTER_DEALERID);

            setDealers(filtered)
        });

    }, []);

    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, value.props.id)
    }

    return (
        <React.Fragment>
            <MuiThemeProvider>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Card className={classes.content} elevation={0}>
                            <CardContent>
                                <Formik
                                    isInitialValid={false}
                                    initialValues={initialFormValues}
                                    enableReinitialize={true}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values, actions) => {
                                        actions.resetForm()
                                        createLead(values)
                                    }}
                                >
                                    {(props) => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            isValid,
                                            handleBlur,
                                            handleChange,
                                            setFieldValue,
                                        } = props;
                                        return (
                                            <Form>
                                                <Grid container className={classes.root} direction="row">
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12}>
                                                        <TextField
                                                            id="id"
                                                            name="id"
                                                            label="Your SA ID"
                                                            required
                                                            value={values.id}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.id ? errors.id : ""}
                                                            error={touched.id && Boolean(errors.id)}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    >
                                                        <TextField
                                                            id="name"
                                                            name="name"
                                                            label="First Name"
                                                            required
                                                            value={values.name}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.name ? errors.name : ""}
                                                            error={touched.name && Boolean(errors.name)}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    >
                                                        <TextField
                                                            id="lastName"
                                                            name="lastName"
                                                            label="Last Name"
                                                            required
                                                            value={values.lastName}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.lastName ? errors.lastName : ""}
                                                            error={touched.lastName && Boolean(errors.lastName)}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12}>
                                                        <TextField
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            label="Email"
                                                            fullWidth
                                                            required
                                                            value={values.email}
                                                            variant="outlined"
                                                            helperText={touched.email ? errors.email : ""}
                                                            error={touched.email && Boolean(errors.email)}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12}>
                                                        <TextField
                                                            id="phone"
                                                            name="phone"
                                                            type="tel"
                                                            label="Cellphone"
                                                            fullWidth
                                                            required
                                                            value={values.phone}
                                                            variant="outlined"
                                                            helperText={touched.phone ? errors.phone : ""}
                                                            error={touched.phone && Boolean(errors.phone)}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12}>
                                                        <TextField
                                                            id="netSalary"
                                                            name="netSalary"
                                                            label="Your Nett Salary"

                                                            value={values.netSalary}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.netSalary ? errors.netSalary : ""}
                                                            error={touched.netSalary && Boolean(errors.netSalary)}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12}>
                                                        <TextField
                                                            id="totalExpenses"
                                                            name="totalExpenses"
                                                            label="Your Total Expenses"

                                                            value={values.totalExpenses}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.totalExpenses ? errors.totalExpenses : ""}
                                                            error={touched.totalExpenses && Boolean(errors.totalExpenses)}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="dealer"
                                                            select={globalDealer.id == MASTER_DEALERID}
                                                            readOnly={globalDealer.id != MASTER_DEALERID}
                                                            required
                                                            label="Dealership"
                                                            value={values.dealer}
                                                            helperText={errors.dealer && touched.dealer ? errors.dealer : ''}
                                                            error={errors.dealer && touched.dealer}
                                                            onBlur={handleBlur("dealer")}
                                                            onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'dealer', 'dealerId')}
                                                        >
                                                            {dealerList?.map((option) => (
                                                                 <MenuItem key={option.dealerId} id={option.dealerId} value={option.dealerId}>
                                                                    {console.log("option",option)}
                                                                     {option.name}
                                                                 </MenuItem>
                                                             ))}
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="lisecenseCheck"
                                                                    onClick={() => {
                                                                        setLicenseCheck(licenseCheck ? false : true)
                                                                    }}
                                                                />
                                                            }

                                                        />
                                                        <span>
                                                            Do you have a valid South African Driver’s License? *
                                                        </span>
                                                    </Grid>
                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="creditCheck"
                                                                    onClick={() => {
                                                                        setCreditCheck(creditCheck ? false : true)
                                                                    }}
                                                                />
                                                            }

                                                        />
                                                        <span>
                                                            Do you consent to a credit check? *
                                                        </span>
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12} style={{ marginLeft: '65px', }}>

                                                        Are you under debt review? *
                                                            <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>
                                                            <Field component={RadioGroup} name="debtReview" style={{ display: 'flex', flexDirection: 'row', }}>

                                                                <FormControlLabel
                                                                    value="Yes"

                                                                    control={
                                                                        <Radio onClick={() => {
                                                                            setDebtReview(true)
                                                                        }} />

                                                                    }
                                                                    label="Yes"

                                                                />
                                                                <FormControlLabel
                                                                    value="No"
                                                                    control={
                                                                        <Radio onClick={() => {
                                                                            setDebtReview(false)
                                                                        }} />

                                                                    }
                                                                    label="No"

                                                                />

                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="popi"
                                                                    onClick={() => {
                                                                        setPopiCheck(popiCheck ? false : true)
                                                                    }}
                                                                />
                                                            }

                                                        />
                                                        <span>
                                                            By submitting this form I agree to the <Link component={RouterLink} to="/terms" className={classes.link}>terms and conditions</Link> and <Link component={RouterLink} to="/privacy" className={classes.link}>privacy policies. *</Link>
                                                        </span>
                                                    </Grid>
                                                    <Grid container direction="row">
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>

                                                            {!loader &&
                                                                <Button className={classes.activebtn} variant={"contained"}

                                                                    disabled={!checkValid(isValid)}
                                                                    type="submit"
                                                                >
                                                                    Apply now
                                                        </Button>
                                                            }
                                                            {loader == true && (
                                                                <Button className={classes.activebtn} variant={"contained"}
                                                                    color="primary"
                                                                    disabled={true}
                                                                >
                                                                    <CircularProgress />
                                                                </Button>
                                                            )}

                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>

                                {preQualSuccess === -1 && <Typography variant="body1" align={'center'} className={classes.uppercase}>Unfortunately, You do not qualify to purchase this vehicle.</Typography>}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        </React.Fragment>
    );
}
