import React, { useState, useEffect, useContext } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import VehicleController from './VehicleController';
import { DealerContext } from '../shared/contexts/DealerContext'
import { MakeContext } from '../shared/contexts/MakeContext'
import { MASTER_DEALERID } from "../shared/Constants"
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: 'transparent',
        padding: theme.spacing(0, 0, 6),
    },
    testParam: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    },
    dividerTitle: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
        width: '33%',
        marginBottom: '30px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    title: {
        fontSize: '1.5rem',
        '&>span': {
            fontWeight: 400
        }
    },
    vehicleContainer: {
        '& h5, & p': {
            color: '#7c7c7c',
        },
        '&:hover img, &:hover p, &:hover h5': {
            color: '#1f2532',
            cursor: 'pointer',
            textDecoration: 'none'
        }
    },
    btn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        width:"75%",
        objectFit: 'contain',
    },
    Carimage:{
        maxWidth:"350px",
        maxHeight:"350px",
        width:"350px",
        height:"350px",
        display:'block',
        margin:'0 auto',
        '@media (max-width: 780px)': {
            flexDirection: '100%'
        }
    },
    card: {
        textAlign: "center",
        fontWeight: 'bold',
        display:'flex',
        justifyContent:'center',
        minHeight:'200px',
        display:'flex',
        alignItems:'center',
        cursor:'pointer',
        backgroundColor: 'transparent',
    },
    shop: {
            display:'flex',
            alignContent:'center',
            justifyContent:'center',
            border: '14px solid',
            borderColor: '#2e3975',
            borderRadius: '50%',
            height: '250px',
            width: '250px',
            backgroundColor: 'white',
            '&:hover': {
                borderColor: '#fff',
            }
    }
}));

const OurVehiclesHomePage = () => {
    const { setActiveGlobalMake, makeColors } = useContext(MakeContext)
    let classes = useStyles({ ...makeColors });
  
    const { vehicles } = VehicleController()
    const [groupedMakes, setGroupedMakes] = useState()
    const { globalDealer } = useContext(DealerContext)

    useEffect(() => {
        let filteredVehicles = vehicles.filter(itm => findMake(itm.make));
        let makes = groupBy(filteredVehicles, 'make');
     

        setGroupedMakes(makes);
        
    }, [vehicles, globalDealer.id])

    // this is causing a error
    // useEffect(() => {
    //     if (window.location.pathname === OUR_VEHICLES_PAGE_PATH) {
    //         setActiveGlobalMake(null);
    //     } else if (make != null) {
   
    //         window.location.href = '/make/' + make?.name.toLowerCase() + '/our-vehicles';
    //     }
    // }, [])

    const cleanName = (dealerName) => {
        return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }


    const handleMakeClick = (make, makeName) => {
        setActiveGlobalMake(make);
         if(globalDealer.name !== MASTER_DEALERID){

            window.location.href=`/dealer/${cleanName(globalDealer.name)}/our-vehicles`
             
         }else{
            window.location.href='/our-vehicles'
         }
    }

    const findMake = (make) => {

        if (!globalDealer.makes) {
            return;
        }

        let foundMakes = globalDealer.makes.filter(itm => itm.name.toLowerCase() == make.toLowerCase());

        return foundMakes.length > 0;
    }

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    return (
        <Container maxWidth="lg">
            <div className={classes.content}>
            <Typography 
                            align="left" 
                            paragraph 
                            component="h3" 
                            variant="h3"
                            gutterBottom 
                            color="#707070"
                            style={{ 
                                fontWeight:'bold',
                                color: '#fff !important',
                                textTransform:'none',
                                fontSize: 16,
                                padding: '0px 20px',
                                lineHeight: 1.5,  
                            }}>
                            SHOP BY BRAND
                        </Typography>
                <Grid container spacing={2} style={{display:'flex',justifyContent:'center'}}>
                        {groupedMakes && Object.keys(groupedMakes).map((makeName, index) => {
                            let models = groupedMakes[makeName];
                            let tmpMake = globalDealer?.makes?.find((m) => {
                                return m?.name?.toLowerCase() === makeName?.toLowerCase()
                            })
                            return (
                                <>
                                    <Grid item xs={12} sm={6} md={3} key={index} className={classes.card} onClick={() => { handleMakeClick(tmpMake, makeName) }}>
                                    {tmpMake?.logo && 
                                        <div 
                                            className={classes.shop}
                                        >
                                        <Box 
                                            style={{
                                                display:'flex',
                                                alignContent:'center',
                                                justifyContent:'center',
    
                                            }}>
                                                <img 
                                                    className={classes.image} 
                                                    width='auto' 
                                                    height="auto" 
                                                    alt={'logo'}
                                                    src={tmpMake?.logo} 
                                                /> 
                                        </Box>
                                        </div>
                                        }
                                    </Grid>
                                </>)
                        })}
                </Grid>
            </div>
        </Container>
    )
}

export default OurVehiclesHomePage;
