import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ServiceImage from '../shared/assets/service.png';
import PartsImage from '../shared/assets/parts.png';
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles((theme) => ({
    info: {
        paddingBottom: '1rem'
    },
    service: {
        backgroundImage:
            `linear-gradient(rgba(201, 201, 201, 0.75), rgba(201, 201, 201, 0.75)), 
        url(${ServiceImage})`,
        width: '100vw',
        height: '80vh',
        display: 'flex',
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    parts: {
        backgroundImage:
            `linear-gradient(rgba(25, 31, 64, 0.75), rgba(25, 31, 64, 0.75)), 
        url(${PartsImage})`,
        width: '100vw',
        height: '80vh',
        display: 'flex',
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    backgroundImage: {
        padding: '60px 0px'
    },
    ServiceHolder: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 780px)': {
            flexFlow: 'column',
        }
    },
    button: {
        width: '200px',
        height: '42px',
        borderRadius: '35px !important',
        fontWeight: 'normal!important',
        textAlign: 'center',
        justifyContent: "center",
        color: `#fff !important`,
        backgroundColor: "2e3975 !important",
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 0px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: '#2e3975',
        '&:hover': {
            backgroundColor: '#fff !important',
            borderColor: '#2e3975',
            boxShadow: 'none',
            color: '#2e3975 !important'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#2e3975',
            borderColor: '#2e3975',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.1rem rgba(46,57,117,.5)',
        },
    },
    buttonInvert: {

        width: '200px',
        height: '42px',
        borderRadius: '35px !important',
        fontWeight: 'normal!important',
        textAlign: 'center',
        justifyContent: "center",
        color: `#2e3975 !important`,
        backgroundColor: "#fff !important",
        boxShadow: 'none',
        textTransform: 'uppercase',
        fontSize: 16,
        padding: '6px 0px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: '#2e3975',
        '&:hover': {
            backgroundColor: '#2e3975 !important',
            borderColor: '#fff',
            boxShadow: 'none',
            color: '#fff !important'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#fff',
            borderColor: '#fff',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.1rem rgba(46,57,117,.5)',
        },
    },
    serviceHeader: {
        color: '#2e3975',
        fontWeight: 'bold',
        padding: '0rem 0rem',
        paddingTop: '4rem',
    },
}));

const AfterSalesPage = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery({ query: '(max-width: 624px)' })

    return (
        <div className={classes.backgroundImage}>


            <Typography gutterBottom component="h1" variant={isMobile ? "h2" : "h1"} color="primary" fontWeight="900" align='center' style={{ color: '#2e3975', fontWeight: 'bold', }}>
                AFTER SALES
            </Typography>
            <div className={classes.ServiceHolder}>
                <div className={classes.service}>
                    <Typography component="h1" variant={isMobile ? "h2" : "h1"} align="center" gutterBottom className={classes.serviceHeader}>
                        SERVICE
                    </Typography>
                    <Button
                        variant="contained"
                        className={classes.button}
                        size="large"
                        href="/service"
                    >
                        BOOK NOW
                    </Button>
                </div>
                <div className={classes.parts}>
                    <Typography component="h1" variant={isMobile ? "h2" : "h1"} align="center" gutterBottom style={{ color: '#fff', fontWeight: 'bold', padding: '0rem 0rem', paddingTop: '4rem' }}>
                        PARTS
                    </Typography>
                    <Button
                        variant="contained"
                        className={classes.buttonInvert}
                        size="large"
                        href="/parts"
                    >
                        ENQUIRE NOW
                    </Button>
                </div>
            </div>

        </div>
    )
}

export default AfterSalesPage;