import React, { useState, useContext, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import Carousel from './CarouselNew';
import { Carousel } from 'react-responsive-carousel';
import { BASE_URI } from '../shared/Constants'
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import SlidePromotion from './SlidePromotion'
import { useMediaQuery } from 'react-responsive'
import useMediaQuery2 from "../shared/useMediaQuery";
import OurVehicles from '../ourVehiclesPage/OurVehiclesHomePage';
import { DealerContext } from '../shared/contexts/DealerContext';
import VehicleController from '../ourVehiclesPage/VehicleController';
import HomescreenImage from '../shared/assets/Homescreen.png';
import ServiceImage from '../shared/assets/service.png';
import PartsImage from '../shared/assets/parts.png';
import GetInTouch from '../shared/assets/ContactUs.png';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ContactHomeContainer from '../shared/ContactHomeComponent'
import ContactComponentCompact from '../shared/ContactComponentCompact';
import { MakeContext } from '../shared/contexts/MakeContext';
import _ from 'lodash'
import { makeDefault, vehicleDefault } from './defaultValues';
import backGround from '../shared/assets/backgroundCarousel.png';
import Link from '@material-ui/core/Link';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const testimonials = [
    {
        text: `To date we’ve had zero downtime. The reaction time from ELT is between two and three hours if there is an issue and the problem gets sorted out immediately.`,
        user: `Andries Pretorius`,
        title: `Co-owner Tron Logistics`,
    },
    {
        text: `We will recommend the new FAW to all fleet owners, if you look at the price of the FAW compared to the competitors, the maintenance contract, the rand-per-kilometer  on the maintenance plan, and the fuel consumption, that makes absolutely a hundred percent sense for anybody to use the new JH6 500.`,
        user: `Almero Theart`,
        title: `Co-owner Tron Logistics`,
    },
    {
        text: `After sales service with ELT was a very good experience that we encountered. The cost per kilometer running the FAW is far less than any vehicle that’s on the road at the moment. As a transporter and logistics owner the price per kilometre running an FAW compared to running any other brand on the road is a far way better experience. FAW ELT will see more business from Rubtrans Logistics.`,
        user: `Rushil Ahir`,
        title: `Director of Rubtrans Logistics`,
    },
];

const useStyles = makeStyles((theme) => ({
    backgroundImage: {
        backgroundImage: `linear-gradient(rgba(121, 121, 121, 0.6), rgba(121, 121, 121, 0.6)), 
        url(${HomescreenImage})`,
        width: '100vw',
        height: '80vh',
        maxHeight: '80vh',
        display: 'flex',
        backgroundSize: 'cover',
        position: 'relative',
        '@media (max-width: 768px)': {
            minHeight: '60vh',
        }

    },
    service: {
        backgroundImage:
            `linear-gradient(rgba(201, 201, 201, 0.75), rgba(201, 201, 201, 0.75)), 
        url(${ServiceImage})`,
        width: '100vw',
        height: '50vh',
        display: 'flex',
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px'
    },
    serviceHeader: {
        color: '#2e3975',
        fontWeight: 'bold',
        padding: '0rem 0rem',
        paddingTop: '4rem',
    },
    parts: {
        backgroundImage:
            `linear-gradient(rgba(25, 31, 64, 0.75), rgba(25, 31, 64, 0.75)), 
        url(${PartsImage})`,
        width: '100vw',
        height: '50vh',
        display: 'flex',
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px'
    },
    getInTouch: {
        backgroundImage:
            `linear-gradient(rgba(121, 121, 121, 0.6), rgba(121, 121, 121, 0.6)),  
        url(${GetInTouch})`,
        width: '100vw',
        height: 'auto',
        display: 'flex',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    marqueeHolder: {
        background: "#2e3975",
        height: '20px'
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    introContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 20, 6),
        '@media (max-width: 768px)': {
            padding: theme.spacing(4, 2, 6),
        }
    },
    heroButtons: {
        marginTop: theme.spacing(8),
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 490px)': {
            flexDirection: 'column'
        }
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        marginBottom: theme.spacing(0),

    },
    cardGridFlex: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        marginBottom: theme.spacing(0),
        display: 'flex',
        flexWrap: "wrap",
        justifyContent: 'center',
    },
    cardGridHeading: {
        background: '#2e3975',
        textAlign: "center",
        color: 'white',
        padding: '10px',
    },
    cardFlex: {
        margin: '5px',
        '@media (max-width: 425px)': {
            width: '100%',
        },
    },
    cardMedia: {
        paddingTop: '56.25%',
    },
    cardContent: {
        display: "flex",
        alignItems: 'center',
        textAlign: 'center',
        '@media (max-width: 768px)': {
            justifyContent: 'center',
            flexDirection: 'column'
        }
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    threeSteps: {
        backgroundColor: ({ secondaryColor }) => `${secondaryColor}`,
    },
    blackBtn: {
        backgroundColor: ({ secondaryColor }) => `${secondaryColor}`,
        color: '#000000',
        fontWeight: 'bold',
        margin: '10px 0px',
        '&:hover': {
            backgroundColor: '#F8EB4C'
        }
    },
    featureDeal: {
        borderBottom: '1px solid #cccccc',
        paddingTop: '20px',
        paddingBottom: '20px',
        margin: '0px 20px',
        cursor: "pointer",
        borderRadius: '50px',
        '&:last-child': {
            borderBottom: 0
        }
    },
    play: {
        marginRight: '20px',
    },
    xlbuttonTopHolder: {
        width: 'calc(100% / 2 - 20px)',
        '@media (max-width: 490px)': {
            width: 'calc(100% / 1 )',
        }
    },
    xlbuttonTop: {
        width: '100%',
        fontWeight: 'bold!important',
        textAlign: 'left',
        justifyContent: "flex-start",
        color: '#707070 !important',
        backgroundColor: "transparent !important",
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 0px',
        borderBottom: '1px solid',
        borderTop: '1px solid',
        lineHeight: 1.5,
        borderColor: '#707070',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: '#2e3975 !important',
            borderColor: '#2e3975',
            boxShadow: 'none',
            color: 'white !important'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#2e3975',
            borderColor: '#2e3975',
        },
        '@media (max-width: 490px)': {
            marginBottom: '20px'
        }
    },
    xlbutton: {
        padding: '13px 50px',
        fontSize: '1.1rem',
        fontWeight: 'normal!important',
        color: 'white!important',
        backgroundColor: "#345e90",
        margin: '40px 0px',
        textTransform: 'uppercase',
        '&:hover': {
            color: 'white!important'
        }
    },
    noPadding: {
        paddingBottom: 0
    },
    featureDealImage: {
        transition: 'all 0.2s',
        '@media (max-width: 599px)': {
            height: 'auto'
        },
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    centeredContent: {
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 1279px)': {
            justifyContent: 'center'
        }
    },
    specialsHolder: {
        position: "relative",
        backgroundColor: 'black',
        height: '200px',
        minHeight: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    specialsPrice: {
        backgroundColor: 'red',
        position: "absolute",
        left: '0',
        top: '30%',
        transition: 'all 1s ease',
    },
    specialsCar: {
        backgroundColor: 'blue',
        position: "absolute",
        transition: 'all 1s ease',
    },
    specialsLogo: {
        backgroundColor: 'green',
        position: "absolute",
        right: '1%',
        bottom: '30%',
        transition: 'all 2s ease',
    },
    footerIconSection: {
        background: '#2e3975',
        color: 'white',
        padding: '20px'
    },
    footerIconSectionLink: {
        background: '#2e3975',
        color: 'white',
    },
    footerIconSectionIcon: {

        color: 'white',
    },
    promise: {
        textAlign: 'center'
    },
    link: {
        color: 'white!important',
        textDecoration: 'none',
    },
    parnterCardHolder: {
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'center',
    },
    parnterCard: {
        padding: '40px',
        margin: " 10px ",
        background: '#dddddd',
    },
    thrEasteps: {
        background: '#2e3975',
        color: 'white',
    },
    redbtn: {
        background: 'white!important',
        color: '#2e3975!important',
    },
    ServiceHolder: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 780px)': {
            flexFlow: 'column',
        }
    },
    section: {
       
        bottom: '-50px',
        zIndex: 10,
        backgroundColor: `#000b3d !important`,
        opacity: 0.85,
        borderRadius: '10px',
        color: 'white',
        '@media (min-width: 780px)': {
            position: 'absolute',
        }
        
    },
    button: {
        width: '200px',
        height: '42px',
        borderRadius: '35px !important',
        fontWeight: 'normal!important',
        textAlign: 'center',
        justifyContent: "center",
        color: `#fff !important`,
        backgroundColor: "2e3975 !important",
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 0px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: '#2e3975',
        '&:hover': {
            backgroundColor: '#fff !important',
            borderColor: '#2e3975',
            boxShadow: 'none',
            color: '#2e3975 !important'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#2e3975',
            borderColor: '#2e3975',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.1rem rgba(46,57,117,.5)',
        },
    },
    buttonInvert: {
        textTransform: 'uppercase',
        width: '200px',
        height: '42px',
        borderRadius: '35px !important',
        fontWeight: 'normal!important',
        textAlign: 'center',
        justifyContent: "center",
        color: `#2e3975 !important`,
        backgroundColor: "#fff !important",
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 0px',
        border: '1px solid',
        lineHeight: 1.5,
        borderColor: '#2e3975',
        '&:hover': {
            backgroundColor: '#2e3975 !important',
            borderColor: '#fff',
            boxShadow: 'none',
            color: '#fff !important'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#fff',
            borderColor: '#fff',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.1rem rgba(46,57,117,.5)',
        },
    },
    vCardButtonNavFlex: {
        display: 'flex',
        width: '80px'
    },
    vCardButtonNav: {
background:"#2e3975",
        border: ({ websiteColors }) => `2px soild ${websiteColors.primaryColor}`,
        padding: "10px 10px",
    

        margin: "10px 0px",
        fill: `white`,

        cursor: 'pointer'
    },
    vCardHolder: {
        overflowX: "clip",
position:'relative',
        margin: '0 auto'
    },
    vCardHolderInner: {
        display: 'flex',
        alignItems: 'end',
        position: 'relative',
        transition: 'transform 0.5s ease',

        transform: ({ slidePosition }) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
        "@media (max-width: 600px)": {
            transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 1}%)` : `translateX(0%)`),
        },
        "@media (min-width: 601px) and (max-width: 1333px)": {
            transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 1}%)` : `translateX(0%)`),
        },
    },
    vCardHeadingOutterHolderMainTop: {
        backgroundImage: `url(${backGround})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
    }
    ,

    vCard: {
        minWidth: "calc(100% / 1 )",
        cursor: "pointer",
        display: 'flex',
        flexDirection: 'column',

        justifyContent: 'space-between',

        transition: '0.5s all ease',


        "@media (max-width: 600px)": {
            minWidth: "calc(100% / 1 )",

        },

        "@media (min-width: 601px) and (max-width: 1333px)": {
            minWidth: "calc(100% / 1 )",
        
        },
    },

    vCardTextHolder: {
        padding: '20px',
        background: '#cecece',
        minHeight: '450px',
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-between',

    },
    vCardButtonHolder: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: "20px"
    },
    vCardImgHolder: {
        background: 'white',
    },
    vCardHeading: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '20px',
        textTransform: 'capitalize'
    },
    vCardButtonTop: {
        border: '1px  solid #2e3975',
        padding: '10px 20px',
        borderRadius: '50px!important',
        color: '#2e3975',
        fontWeight: 'bold'
    },
    vCardButtonTopBlue: {
        background: '#2e3975',
        padding: '10px 20px',
        borderRadius: '50px!important',
        color: 'white',
        fontWeight: 'bold',
        border: '1px  solid #2e3975',
    },
    bulletTest: {
        fontSize: '12px',
        color: "#707070",
        textTransform: 'None',
        lineHeight: '0.5'
    },
    vCarImg: {
        height: '300px',
        bottom: 0,
        margin: 'auto',
        position: 'relative',
        objectFit: 'contain',
        objectPosition: 'center'
    },
    vCardButtonNavHolderTop:{
    position: 'absolute',
    zIndex: '2',
    right:"10px",
    bottom:'0px'
}
}));

export default function HomeComponent() {
    const { setActiveGlobalMake } = useContext(MakeContext)
    const { vehicles } = VehicleController()
    const { globalDealer, websiteColors } = useContext(DealerContext)
    const history = useHistory()
    const [loading, setLoading] = useState(true);
    const [deals, setDeals] = useState([])
    const mountedRef = useRef(true)
    const [slides, setSlides] = useState([])

    const [groupedMakes, setGroupedMakes] = useState()
    const [desc, setDesc] = useState('')
    const [openContactUs, setOpenContactUs] = useState(false)

    const [slideIndex, setSlideIndex] = useState(0)
    const [slidePosition, setSlidePosition] = useState(0)

    const classes = useStyles({ websiteColors, slideIndex, slidePosition });

    useEffect(() => {
        let filteredVehicles = vehicles.filter(itm => findMake(itm.make));
        let makes = groupBy(filteredVehicles, 'make');

        setGroupedMakes(makes)
    }, [vehicles, globalDealer.id])

    useEffect(() => {
        console.log("slidePosition", slidePosition)
    }, [slidePosition])


    const cleanName = (dealerName) => {
        return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }

    const handleMakeClick = (make, makeName) => {

        setActiveGlobalMake(make);

        //  if(globalDealer.name !== MASTER_DEALERID){

        //     window.location.href=`/dealer/${cleanName(globalDealer.name)}/our-vehicles`

        //  }else{
        //     window.location.href='/our-vehicles'
        //  }

    }



    let currentDate = new Date()

    useEffect(async () => {
        const CancelToken = axios.CancelToken
        setLoading(true)
        const source = CancelToken.source()
        const getOffer = async () => {
            try {
                const params = {
                    pageNumber: 0,
                    pageSize: 100,
                    status: 'active',
                    dealerId: globalDealer.id
                }

                const result = await axios({
                    method: 'GET',
                    url: `${BASE_URI}/Offers`,
                    params,
                    cancelToken: source.token
                })

                setLoading(false)
                let data = result.data
                setDeals(data.list)

            } catch (error) {
                setLoading(false)
            }
        }


        const getSlides = async () => {
            try {
                const params = {
                    pageNumber: 0,
                    pageSize: 100,
                    status: 'active',
                    dealerId: globalDealer.id
                }

                const result = await axios({
                    method: 'GET',
                    url: `${BASE_URI}/slide`,
                    params,
                    cancelToken: source.token
                })

                setLoading(false)

                var slides = result.data.list.map(itm => {

                    return {
                        img: itm.slideImageUrl,
                        content: <SlidePromotion vehicleName={itm.make} name={itm.name} tagline={itm.cta} price={""} url={itm.url} callToAction={itm.buttonText} category={itm.category} endDate={itm.endDate} startDate={itm.startDate} />
                    }
                });


                let filteredSlides = slides.sort((a, b) => a.content.props.category - b.content.props.category)
                let filterd = slides.filter(d => d.content.props.startDate <= currentDate.toISOString() && d.content.props.endDate >= currentDate.toISOString())

                setSlides(slides);

            } catch (error) {
                setLoading(false)
            }
        }


        getOffer().then(() => {
            setLoading(false)

            if (!mountedRef.current)
                return null
        })

        getSlides()


        return () => {
            mountedRef.current = false
            source.cancel();
        };

    }, [])

    var Price = document.getElementById('Price')
    var Car = document.getElementById('Car')
    var Logo = document.getElementById('Logo')

    const AnimEnter = () => {
        Price.style.transform = "scale(1.5) translateX(7px)";
        Car.style.transform = "scale(2)";
        Logo.style.top = "10%";
        Logo.style.bottom = "unset";
    }
    const AnimLeave = () => {
        Price.style.transform = "scale(1) translateX(0px)";
        Car.style.transform = "scale(1)";
        Logo.style.top = "unset";
        Logo.style.bottom = "30%";
    }
    const isMobileS = useMediaQuery2('(max-width: 600px)');
    const isTablet = useMediaQuery2('(min-width: 601px) and (max-width: 1333px)');
    const isDesktop = useMediaQuery2('(min-width: 1334px)');

    const isMobile = useMediaQuery({ query: '(max-width: 624px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 912px)' })
    const isXlarge = useMediaQuery({ query: '(min-width: 1700px)' })

    const cleanModel = (model) => {

        return model.toLowerCase().replace('/', '-')
    }

    useEffect(() => {

        axios.get(`${BASE_URI}/Dealers/DealerContactDetails/${globalDealer.id}`, {
        }).then((result) => {
            setDesc(result.data.description)
        });
    }, [])

    const findMake = (make) => {

        if (!globalDealer.makes) {
            return;
        }

        let foundMakes = globalDealer.makes.filter(itm => itm.name.toLowerCase() == make.toLowerCase());

        return foundMakes.length > 0;
    }

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };



    // useEffect(() => {
    //     // if(!make){
    //     //     setActiveGlobalMake({
    //     //         "id": 8180,
    //     //         "name": "Daewoo",
    //     //         "primaryColor": "#1468B3",
    //     //         "secondaryColor": "#1468B3",
    //     //         "accentColor": "#1468B3",
    //     //         "logo": "https://c2marketdealer.blob.core.windows.net/images/d49c5cc8-5c2d-447e-93ad-9ab0f1249594.png",
    //     //         "heroImage": "https://c2marketdealer.blob.core.windows.net/images/e1da0dce-10ed-42be-931c-691a2afb9482.png",
    //     //         "roadSideAssistTel": "112559600"
    //     //     })
    //     // }
    //     // if(groupedMakes){
    //         // if(make){
    //         //     const groupArr = convertGroupToSlide(groupedMakes[make.name])
    //         //     setGroupVehicles(groupArr)
    //         // } else {
    //             const groupArr = convertGroupToSlide(groupedMakes['Daewoo'])
    //             console.log('Here group 2', groupArr)
    //             setGroupVehicles(groupArr)
    //         // }
    //     // }
    // }, [groupedMakes, make])

    const convertGroupToSlide = (data) => {
        return data.map((vehicle) => {
            return {
                content: '',
                img: vehicle?.image,
                make: vehicle?.make,
                model: vehicle?.model,
                title: vehicle?.title,
                price: vehicle?.price,
                modelId: vehicle?.id,
                info: vehicle?.ownedModelInformation,
            }
        })
    }


 

    const handleBack = () => {

        if (slidePosition < 0) {
            if (isDesktop) {
                setSlidePosition(slidePosition + (100 / 1))
                setSlideIndex(slideIndex - 1)
            }

            if (isMobileS) {
                setSlidePosition(slidePosition + (100 / 1))
                setSlideIndex(slideIndex - 1)
            }

            if (isTablet) {
                setSlidePosition(slidePosition + (100 / 1))
                setSlideIndex(slideIndex - 1)
            }

        }

    }




    const handleNext = () => {


        if (isDesktop) {

            if (slideIndex < slides.length - 1) {

                setSlidePosition(slidePosition - (100 / 1))
                setSlideIndex(slideIndex + 1)
            }
            if (slideIndex === slides.length - 1) {
                setSlidePosition(0)
                setSlideIndex(0)
            }
        }

        if (isMobileS) {

            if (slideIndex < slides.length - 1) {

                setSlidePosition(slidePosition - (100 / 1))
                setSlideIndex(slideIndex + 1)
            }

            if (slideIndex === slides.length - 1) {
                setSlidePosition(0)
                setSlideIndex(0)
            }
        }

        if (isTablet) {

            if (slideIndex < slides.length - 1) {

                setSlidePosition(slidePosition - (100 / 1))
                setSlideIndex(slideIndex + 1)
            }
            if (slideIndex === slides.length - 1) {
                setSlidePosition(0)
                setSlideIndex(0)
            }
        }

    }


    const handleRedirectContact = () =>{
        history.push("/contact-us")
    }
    const handleRedirect = (slide) =>{
        console.log()
        let path = slide?.content.props.url
        history.push(path)
    }

    const renderTestimonial = (testimonial) => {
        const { text, user, title } = testimonial;
        return (
            <div style={{ padding: 10, paddingBottom: 50 }}>
                <Typography style={{ padding: 10 }}>{text}</Typography>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
                    <Typography style={{ fontWeight: 'bold' }}>{user} | </Typography>
                    <Typography>{title}</Typography>
                </div>
            </div>
        );
    }

    const renderTestimonials = () => {
        return (
            <Container maxWidth="md" style={{ paddingBottom: '20px' }}>
                <Typography component="h4" variant="h3" align="center" gutterBottom style={{ color: '#2e3975', fontWeight: 'bold', padding: '0rem 0rem', paddingTop: '4rem' }}>
                    TESTIMONIALS
                </Typography>
                <Carousel
                    autoPlay
                    interval={10000}
                    emulateTouch
                    infiniteLoop
                    showStatus={false}
                >
                    {testimonials.map(t => renderTestimonial(t))}
                </Carousel>
            </Container>
        );
    }
    
    return (
        <React.Fragment>
            <main>
                <Container maxWidth="false" style={{ padding: "0px", background: 'linear-gradient(0deg, rgba(174,174,174,1) 0%, rgba(207,207,207,1) 40%, rgba(223,223,223,1) 100%)', }}>
                  

                    <div className={classes.vCardHeadingOutterHolderMainTop}  >
                
                <div className={classes.vCardHolder}>
                    <div className={classes.vCardHolderInner}>

                        {slides.map((v) => {
                            return (
                                <div className={classes.vCard} onClick={() => { handleRedirect(v) }}>
                              
                                    <img src={v.img} width="100%"/>
                                </div>
                            )
                        })}

                       
                    </div>
           
                    <div className={classes.vCardButtonNavHolderTop}>


<div className={classes.vCardButtonNavFlex}>
    <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBack() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
    <svg className={classes.vCardButtonNav} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNext() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>

</div>
</div>
                   
                </div>

            </div> 
                 
                    <Container maxWidth="lg" style={{ paddingBottom: '20px' }} >
                        <div className={classes.heroButtons}>

                            <div className={classes.xlbuttonTopHolder} >
                                <Button
                                    className={classes.xlbuttonTop}
                                    size="large"
                                    variant="contained"
                                    onClick={() => handleRedirectContact()}
                                    style={{ boxShadow: openContactUs && '0 0 0 0.2rem rgba(0,123,255,.5)' }}
                                >
                                    <>
                                        <PlayArrowIcon className={classes.play} />
                                        GET IN TOUCH TODAY
                                    </>
                                </Button>
                            </div>

                            <div className={classes.xlbuttonTopHolder} >
                                <Button
                                    className={classes.xlbuttonTop}
                                    variant="contained"
                                    href="/our-dealers"
                                >
                                    <PlayArrowIcon className={classes.play} />
                                    FIND US NOW
                                </Button>
                            </div>

                        </div>
                        <Typography component="h1" variant={isMobile ? "h4" : "h3"} align="center" gutterBottom style={{ color: '#2e3975', fontWeight: 'bold', padding: '0rem 0rem', paddingTop: '4rem' }}>
                            FOR ALL YOUR COMMERCIAL VEHICLE NEEDS
                        </Typography>


                        <OurVehicles />

                        <Typography component="h1" variant="h3" align="center" gutterBottom style={{ color: '#2e3975', fontWeight: 'bold', padding: '0rem 0rem', paddingTop: '4rem' }}>
                            ABOUT THE ELT GROUP
                        </Typography>
                        <br />
                        <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.4rem' }}>
                            {desc}
                        </Typography>
                    </Container>
                    {renderTestimonials()}
                </Container>
               

              

                <Container className={`${classes.cardGridFlex} `} maxWidth="lg">

                    <div className={classes.ServiceHolder}>
                        <div className={classes.service}>
                            <Typography component="h1" variant={isMobile ? "h2" : "h1"} align="center" gutterBottom className={classes.serviceHeader}>
                                SERVICE
                            </Typography>
                            <Button
                                variant="contained"
                                className={classes.button}
                                size="large"
                                href="/service"
                            >
                                BOOK NOW
                            </Button>
                        </div>
                        <div className={classes.parts}>
                            <Typography component="h1" variant={isMobile ? "h2" : "h1"} align="center" gutterBottom style={{ color: '#fff', fontWeight: 'bold', padding: '0rem 0rem', paddingTop: '4rem' }}>
                                PARTS
                            </Typography>
                            <Button
                                variant="contained"
                                className={classes.buttonInvert}
                                size="large"
                                href="/parts"
                            >
                                ENQUIRE NOW
                            </Button>
                        </div>
                    </div>
                </Container>
                <div className={classes.marqueeHolder}>

                </div>
                <div className={classes.getInTouch}>
                    <Typography component="h3" variant="h3" align="center" style={{ color: '#2e3975', fontWeight: 'bold', padding: '0rem 0rem', paddingTop: '2rem' }}>
                        GET IN TOUCH WITH US TODAY
                    </Typography>
                    <div style={{ width: '80vw' }}>
                        <ContactHomeContainer
                            leadTypeId={3}
                            bottomHomeScreen
                        />
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
}