import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HeroImage from '../shared/assets/RoadSideHero.png'
import Image1 from '../shared/assets/RoadsideAssistance.png'
import Image2 from '../shared/assets/Roadside Assistance 2.png'
import { useMediaQuery } from 'react-responsive'
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    backgroundImage: {
        backgroundImage: `linear-gradient(rgba(7, 14, 77, 0.75), rgba(7, 14, 77, 0.75)), 
        url(${HeroImage})`,
       
        width: '100vw',
        height: '80vh',
        display: 'flex',
        backgroundSize: 'cover',
        position: 'relative'
    },
    info: {
        paddingBottom: '1rem'
    },
    header: { 
        color: '#2e3975', 
        fontWeight: 'bold',
    },
    paragraph: {
        fontSize: '1.4rem', 
        width: '90%', 
        justifyItems: 'center' ,
        marginBottom: '20px', 
        color: "black",
        '@media (max-width: 425px)': {
            fontSize: '0.9rem', 
        }
    }
}));

const RoadsideAssistancePage = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery({ query: '(max-width: 624px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 912px)' })
    return (
    <>
        <div className={classes.backgroundImage}/>
        <React.Fragment>
            <div style={{background: 'linear-gradient(0deg, rgba(174,174,174,1) 0%, rgba(207,207,207,1) 40%, rgba(223,233,233,1) 100%)', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Typography gutterBottom component="h1" variant={isMobile? "h4" : isTabletOrMobile ? "h3" :"h2"} color="black" fontWeight="900" align='center' className={classes.header}>
                    OUR 27/7 ROADSIDE ASSISTANCE
                </Typography>
                <Typography align="left" paragraph gutterBottom className={classes.paragraph}>
                    As a truck dealership, we understand the importance of keeping our customers on the road and their trucks running smoothly.
                </Typography>
                <Typography align="left" paragraph gutterBottom className={classes.paragraph}>
                That's why we offer a 24 hour roadside assist package to all of our customers.
                </Typography>
                <Typography align="left" paragraph gutterBottom className={classes.paragraph}>
                Our roadside assist package is designed specifically for truck drivers and includes a wide range of services to help them deal with unexpected problems while on the road.
                </Typography>
                <Typography align="left" paragraph gutterBottom className={classes.paragraph}>
                With our 24 hour roadside assist package, you'll have access to a variety of services including:
                    <ul>
                        <li>Emergency roadside assistance: Our team of experienced technicians will be on hand to provide assistance in the event of a breakdown.</li>
                        <li>Fuel delivery: If you run out of gas, we'll deliver a small amount of fuel to get you to the next gas station.</li>
                        <li>Mobile repair: In some cases, our technicians will be able to make repairs on-site, getting back on the road as quickly as possible.</li>
                        <li>Winching and extraction: If your truck is stuck in a ditch or a snowbank, we'll help pull it out.</li>
                        <li>Towing: If your truck is unsafe for the road we will tow your truck to the nearest service point.</li>
                    </ul>
                </Typography>
                <Grid container spacing={4} className={`${classes.greyBackground}`} style={{padding: '10px'}}>
                    <Grid item container alignContent='center' justify='center' lg={6} md={6} sm={12} className={`${classes.greyBackground}`}>
                        <img style={{ width: '100%', padding: '20px' }} alt="RoadSideAssistace" src={Image1} />
                    </Grid>
                    <Grid item container alignContent='center' justify='center' lg={6} md={6} sm={12} className={`${classes.greyBackground}`}>
                        <img style={{ width: '100%', padding: '20px' }} alt="RoadSideAssistace" src={Image2} />
                    </Grid>
                </Grid>
                <Typography align="left" paragraph gutterBottom className={classes.paragraph}>
                    With our 24 hour roadside assist package, you'll have peace of mind knowing that you can rely on us in case of an emergency.
                    Our experienced technicians are available 24/7 to assist you and get you back on the road as quickly as possible.
                    Don't let unexpected breakdowns and emergencies slow you down.
                    Sign up for our 24 hour roadside assist package today and keep your truck running smoothly. 
                </Typography>
            </div>
        </React.Fragment>
    </>
    )
}

export default RoadsideAssistancePage;