import React from 'react';
import RoadsideAssistance from './RoadsideAssistancePage';
import AboutUs from './AboutUs';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AfterSales from './AfterSales';
import Typography from '@material-ui/core/Typography';

const AfterSalesComponent = props => {

    const section = () => {

        if (props.match.path === '/roadside-assistance') return <RoadsideAssistance />

        if (props.match.path === '/about-us') return <AboutUs />

        if (props.match.path === '/after-sales') return <AfterSales />
        
        return ( <Typography component="h1" variant={"h1"} align="center"  gutterBottom style={{ color: '#2e3975', fontWeight: 'bold', padding: '0rem 0rem',paddingTop: '4rem' }}>
                Sorry- Page not found
            </Typography>)

    }

    if (props.match.path === '/about-us' || props.match.path === '/roadside-assistance'  || props.match.path === '/after-sales'){
        return (
            <Grid container style={{background:'#f3f4f6',paddingLeft:'0px !important',paddingRight:'0px !important'}}>
                <Grid item sm={12} md={12}>
                    {section()}
                </Grid>
            </Grid>
        )
    }

    return (
        <Container maxWidth="lg" style={{ marginTop:'-3px',marginBottom:'34px', }}>
            <Grid container style={{background:'#f3f4f6',paddingLeft:'0px !important',paddingRight:'0px !important'}}>
                <Grid item sm={12} md={12}>
                    {section()}
                </Grid>
            </Grid>

        </Container>
    )

}

export default AfterSalesComponent;