import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "material-ui";
import Container from '@material-ui/core/Container';
import ApplySteps from '../shared/ApplySteps';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import PaymentDetails from '../shared/PaymentDetails'
import { VIRTUAL_URI_APPLICATION } from "../shared/Constants";
import { useParams, useHistory } from "react-router-dom";

const CustomCheckbox = withStyles({
    root: {
        '&$checked': {
            color: '#2e3975',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "2rem",
        marginRight: "2rem",
        marginTop: "2rem",
    },
    stepPaddingButton: {
        marginLeft: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingImage: {
        marginLeft: "1rem",
        marginRight: "2rem",
    },
    left: {
        float: "left"
    },
    right: {
        float: "right"
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        width: "100%",
        height: "auto",
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    header: {
        borderBottom: '1px solid #dedede',
        marginBottom: '40px',
        paddingBottom: '24px'
    },
    table: {
        '& tr > th:last-child': {
            fontWeight: 700
        },
        '& tr > td:last-child': {
            fontWeight: 700
        }
    },
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    blue: {
        color: '#2e3975'
    },
    dividerTitle: {
        backgroundColor: "#2e3975",
        width: '33%',
        marginBottom: '30px',
        height: '3px',
        margin: '20px auto'
    },
    activebtn:{
        background:"#39679f",
        color:'white',
        fontWeight:'bold',
        '&:hover': {
            background:'#39679fe6',
        },
    }

}));

export default function PreApplication() {
    const classes = useStyles();
    const [loader, setLoader] = useState(false)
    const { leadId } = useParams()
    let { globalFinance, globalVehicle, globalColor } = useContext(FinanceContext);

    const [state, setState] = React.useState({
        checkboxA: false,
        checkboxB: false,
        checkboxC: false,
        checkboxD: false,
        checkboxE: false,
        checkboxF: false,
        checkboxG: false,
        checkboxI: false
    });

    const { checkboxA, checkboxB, checkboxC, checkboxD, checkboxE, checkboxF, checkboxG, checkboxI } = state;

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: !state[event.target.name] });
    };

    const submit = (event) => {
    
        if (state.checkboxA && state.checkboxB && state.checkboxC && state.checkboxD && state.checkboxE && state.checkboxF && state.checkboxG && state.checkboxI) {

            window.location.href = VIRTUAL_URI_APPLICATION.replace(':leadId', leadId);
        }
    }

    const stepsState = {
        one: {
            active: false,
            completed: true
        },
        two: {
            active: false,
            completed: true
        },
        three: {
            active: false,
            completed: true
        }
    }

    return (
        <Container maxWidth="lg">
            <div className={classes.content}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Box mb={4}>
                            <ApplySteps horizontal disabledStates={stepsState} />
                        </Box>
                        <Box mb={6}>
                            <Typography variant="h5" component="h2" align={'center'}>
                                You can return to this page at any time. Your reference number is <span className={classes.blue}>REV-{leadId}</span>
                            </Typography>
                            <Typography variant="h5" component="h2" align={'center'}>
                                If you require assitance, our live chat agents are available 24/7. Check your phone for further details.
                            </Typography>
                        </Box>

                    </Grid>

                    {globalFinance && globalFinance.purchasePrice &&
                        <Grid item className={classes.stepPaddingImage} xs={12} md={6} lg={6}>
                            <Card className={classes.content} elevation={0}>
                                <CardActionArea>
                                    <CardMedia className={classes.image}
                                        component="img"
                                        alt="Features"
                                        image={globalColor.image}
                                        title={'title'}
                                    />
                                </CardActionArea>
                            </Card>
                            <Grid container direction="row">
                                <Card elevation={0} className={classes.card}>
                                    <CardActionArea>
                                        <CardHeader
                                            title={'Summary'}
                                        />
                                        <CardContent>
                                            <PaymentDetails displayPrice={false} details={globalFinance ? globalFinance : globalVehicle.dealDetails} />
                                        </CardContent>

                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    }

                    <Grid item xs={12} md={5} lg={5}>
                            <>
                                <Box mb={3}>
                                    <Typography align="center" variant="h5">
                                        You are applying for the following offer
                                    </Typography>
                                </Box>
                                <Typography align="center">
                                    <span style={{fontSize: '1.2rem'}}>Please confirm the following</span>
                                </Typography>
                                <Divider className={classes.dividerTitle} />
                                <div className={classes.root}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<CustomCheckbox onChange={handleChange} name="checkboxA" />}
                                                label="You are not a minor"
                                            />
                                            <FormControlLabel
                                                control={<CustomCheckbox onChange={handleChange} name="checkboxB" />}
                                                label="You have never been declared mentally unfit by a court"
                                            />
                                            <FormControlLabel
                                                control={<CustomCheckbox onChange={handleChange} name="checkboxC" />}
                                                label="You are not subject to an administration order"
                                            />
                                            <FormControlLabel
                                                control={<CustomCheckbox onChange={handleChange} name="checkboxD" />}
                                                label="You do not have any current debt re-arrangement in existence"
                                            />
                                            <FormControlLabel
                                                control={<CustomCheckbox onChange={handleChange} name="checkboxE" />}
                                                label="You have not previously applied for a debt re-arrangement"
                                            />
                                            <FormControlLabel
                                                control={<CustomCheckbox onChange={handleChange} name="checkboxF" />}
                                                label={"You do not have applications pending for credit, nor do you have any open quotations under section 92 of the National Credit Act 34 of 2005 (\" National Credit Act \")"}
                                            />
                                            <FormControlLabel
                                                control={<CustomCheckbox onChange={handleChange} name="checkboxG" />}
                                                label="You are not currently in debt counselling"
                                            />
                                            <FormControlLabel
                                                control={<CustomCheckbox onChange={handleChange} name="checkboxI" />}
                                                label="I consent to the Vehicle Finance Terms & Conditions"
                                            />
                                        </FormGroup>
                                    </FormControl>

                                    <Grid container direction="row">
                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>

                                        {!loader &&
                                            <Button   className={classes.activebtn}  variant={"contained"}
                                                    onClick={() => submit()}>
                                                    Continue
                                                </Button>
                                            }

                                            {loader == true && (
                                                <Button   className={classes.activebtn} variant={"contained"}
                                               
                                                    disabled={true}
                                                >
                                                    <CircularProgress />
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                
                                </div>
                            </>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
