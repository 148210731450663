import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    link: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none'
        }
    }
}));

const Terms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <div className={classes.content}>
                <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                ELT Group Group CONFLICTS OF INTEREST MANAGEMENT POLICY
                </Typography>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Introduction
                </Typography>
                <p>Board notice No.80 of 2003 and amended by Board Notice.58 of 2010-The General Code of Conduct for authorized Financial Service Providers and Representatives requires that when a provider renders a financial service the provider and any representative must avoid, and where this is not possible mitigate, any conflict of interest between the provider and a client or the representative and the client.</p>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Purpose
                </Typography>
                <p>The executive management of ELT Group Group sees itself as an expert in short term insurance and therefore a professional advice- giving company. Therefore the company’s actions must be open to scrutiny by clients, potential clients and in some cases members of the public.</p>
                <p>Our business comprises a network of franchised commercial and passenger vehicle dealerships that retail new and used trucks and provide after sales support representing most of the major vehicle brands in South Africa.</p>
                <p>Consequently, there has to exist a duty of loyalty and fidelity by management and staff who have the responsibility of administering the company’s affairs honestly and prudently, and of exercising their best care, skill, and judgement for the sole benefit of clients. Those persons must exercise the utmost good faith in all transactions involved in their duties, and they must not use their positions within the company or knowledge gained there from for their personal benefit. The interests of the company and its clients must be the first priority in all decisions and actions.</p>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Persons Concerned
                </Typography>
                <p>For the purpose of this document, the persons concerned are those involved in the selling of vehicles and the ancillary function of credit arrangement and insurance facilitation. The term employee includes directors, managers and all permanent staff as well as contract staff who can influence the actions of others. For example, in addition to those of our staff that have direct dealings with clients and recommend products to them, this would include all who make purchasing decisions and anyone who has proprietary information concerning a client.</p>
                    <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Areas in which conflicts may arise
                </Typography>
                <p>Conflicts of interest may arise in the relations of employees with any of the following third parties and any company that ELT Group Group or its employees have an association, by shareholding or any other interest including:</p>
                <ul>
                    <li>Persons and firms supplying goods and services to the company</li>
                    <li>Persons and firms from whom the company leases property and equipment</li>
                    <li>Competing companies;</li>
                    <li>Agencies, organizations and associations including insurers, underwriting managers, administrators and other brokers with whom the company transacts business;</li><li>Family members, friends, and other employees.</li>
                </ul>
                <p>Conflicts of interest may also arise in the method by which we remunerate our staff, particularly if we provide incentives to our representatives for the quantity of business secured without appropriate quality assurance mechanisms in place. For this reason, we ensure that the advice and services we provide are regularly quality tested.</p>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Nature and Conflicting Interest
                </Typography>
                <p><strong>A conflict of interest is defined as:</strong></p>
                <p>‘Any situation in which our company or a representative of our company has an actual interest that may, in rendering a financial service to a client,-</p>
                <ul>
                    <li>Influence the objective performance of his, her or its obligations to that client; or</li>
                    <li>Prevent our company or our representatives from rendering an unbiased and fair financial service to that client, or from acting in the interests of that client,</li>
                </ul>
                <p><strong>Including, but not limited to –</strong></p>
                <ul>
                    <li>A financial interest;</li>
                    <li>An ownership interest;</li>
                    <li>Any relationship with a third party’</li>
                </ul>
                <p><strong>In respect of employees, possible conflicts are:</strong></p>
                <ul>
                    <li>An employee owning shares or holding debt or other proprietary interests in any third party or associated company.</li>
                    <li>Holding office, serving on the board, participating in management, or otherwise employed (or formally employed) with any third party or associated company.</li>
                    <li>An employee receiving remuneration for services from another company.</li><li>An employee using our company’s time, personnel, equipment, supplies,or goodwill for purposes other than approved activities, programs,and purposes.</li>
                    <li>An employee receiving gifts for birthdays or special occasions;</li>
                    <li>An employee receiving money, vouchers, or anything that can be converted to money for ‘selling’ specified services or products;</li>
                    <li>An employee being invited on lunches/dinners/shows and other entertainment events;</li>
                    <li>An employee providing leads to businesses owned by family and friends;</li>
                    <li>An employee distributing products and /or services provided by businesses owned by family and friends;</li>
                    <li>An employee distributing products and/or services provided by businesses owned by family or friends;</li>
                    <li>Any activity involving clients by which family and friends can financially benefit.</li>
                    <li>An employee receiving personal gifts or loans from any other companies or persons dealing or competing with our company.</li>
                </ul>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Interpretation:
                </Typography>
                <p>These areas of conflicting interest listed above and the relations in those areas which may give rise to conflict are not exhaustive. Conflicts might arise in other areas or through other relations. It is assumed that the employees will recognize such areas and relation by analogy. All identified possible conflicts of interest have been listed in the ‘conflict of interest summary’ at the end of this document.</p>
                <p>The fact that one of the interests described above exists does not necessarily mean that a conflict exists, or that the conflict, if it exists, is material enough to be of practical importance, or if material, that upon full disclosure of all relevant facts and circumstances it is necessarily adverse to the company’s interests or interests of clients.</p>
                <p>However, it is the policy of the company that the existence of any of the interests described above shall be disclosed before any transaction is consummated. It shall be the continuing responsibility of the employees to scrutinize their transactions and outside business interests and relationships for potential conflict and to immediately make such disclosures.</p>
                <p>Similarly it is the responsibility of all employees to identify and report on possible conflicts of interest that may emanate from the working relationship that this company has with any of its associates or other business partners, whether there is a financial interest or otherwise.</p>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Disclosure
                </Typography>
                <p>Transactions with parties with whom a conflicting interest exists may be undertaken only if all of the following are observed:</p>
                <ul>
                    <li>The conflict of interest has been identified and attempts to avoid it have not been successful;</li>
                    <li>The conflict of interest has been mitigated as far as possible;</li>
                    <li>The conflict of interest is fully disclosed to clients when appropriate;</li>
                    <li>An employee with the conflict of interest is excluded from the discussion and approval of such is provided by the Managing Director;</li>
                    <li>A competitive quotation, bid or comparable valuation exists and has been properly evaluated and where involving a client has been properly explained;</li>
                    <li>The Managing Director has determined that the transaction is in the best interest of the company and its clients.</li>
                </ul>
                <p>Disclosure of possible conflict of interest transactions should be made to the Managing Director who shall determine whether a conflicts exists and in the case of an existing conflict, whether the completed transaction may be authorized as just, fair, and reasonable.</p>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Disclosure to clients
                </Typography>
                <p>We will disclose all conflicts of interest and potential conflicts of interest to our clients in at least one of the following ways:</p>
                <ul>
                    <li>The conflict of interest has been identified and attempts to avoid it have not been successful;</li>
                    <li>By declaring them in writing as soon as practicably possible following a sale.</li>
                </ul>
                <p>In selecting one or both of the above, we will take into account the type of conflict and the impact it might have on the client’s decision to purchase or decline our products/services. All allowable incentives will be disclosed at the point of sale, as will the responsibilities of each and every company in the service delivery chain.</p>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                   ASSOCIATE COMPANIES AND/OR RELATIONSHIPS IN RESPECT OF THE SERVICES WE PROVIDE IN TERMS OF THE FAIS ACT
                </Typography>
                <p>Cell Captive within Guardrisk Insurance Company – 50% owned by our company and 50% owned by Wesbank. This company provides insurance products in respect of credit protection for our motor customers. The policy types include, but are not limited to credit life and ancillary covers, credit shortfall and extended warranty insurance.</p>
                <p>Possible conflicts arising through the use of associated companies have been identified and eliminated as far as possible. Administrators have been appointed that are totally Independent from our company and who have no financial interest in the underwriting results of the cell captives.</p>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    CONFLICT OF INTEREST DISCLOSURE STATEMENT
                </Typography>
                <p>Every employee is requested to sign a statement of disclosure, which statement also requires employees to provide information with respect to business and/or parties that are related to them, including:</p>
                <ul>
                    <li>A spouse, domestic partner, child, mother, father, brother or sister</li>
                    <li>Any corporation or organization of which the employee is a board member, a partner, participates in management or is employed by, or is, directly or indirectly, a debt holder or the beneficial owner of any class of equity securities, and</li>
                    <li>Any trust or other estate in which an employee has a substantial beneficial owner of any class of equity securities; and this is included in every employees service contract</li>
                </ul>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                   TRAINING OF EMPLOYEES
                </Typography>
                <p>As this policy links to and forms a part of every employees employment contract, we include training regarding this conflict of interest management policy in every orientation programme that we host in respect of new employees, as well as providing regular and continuous updates within our ongoing professional development programmes for existing employees.</p>

                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                   ADOPTION BY BOARD OF DIRECTORS
                </Typography>
                <p>This policy was adopted by the executive having been provided to do so by the Board of Directors.</p>
            </div>
        </Container>
    )
}

export default Terms