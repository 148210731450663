import React, { useEffect, useState, useContext } from "react";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ComingSoon from "../shared/assets/comingsoon.jpg";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import VehicleController from '../usedVehicles/VehicleController';
import { ImageContainerUsedVehicle } from "../shared/ImageContainerUsedVehicle";
import Button from '@material-ui/core/Button';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Remove';
import { ImageContainer } from '../shared/ImageContainer'
import { CurrencyValue } from '../shared/TextMask'
import {CompareContext} from '../shared/contexts/CompareContext';
import {FavouriteContext} from '../shared/contexts/FavouriteContext';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  filterLoadingContainer: {
    height: "576px",
    position: "absolute",
    width: "210px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: "10",
    boxShadow: "inset 0 0 0 200px rgb(255 255 255 / 5%)",
    filter: "blur(10px)",
  },
 
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
    display:'flex',
    flexWrap:'wrap',
    '@media (max-width: 769px)': {
        flexWrap:'wrap',
     
    }
  },
  dividerTitle: {
    backgroundColor: "#2e3975",
    width: "60%",
    marginBottom: "30px",
    height: "3px",
    margin: "0px auto",
  },
  carddividerTitle: {
    backgroundColor: "#2e3975",
    width: "100%",
    marginBottom: "10px",
    height: "3px",
    margin: "5px auto",
  },
  bold: {
    fontWeight: 700,
  },
  lighter: {
    fontWeight: "lighter",
  },
  flex: {
    display: "flex",
    flexDirection: "row!important",
    justifyContent: "space-between",
  },
  clear: {
    padding: "0px!important",
    fontSize: "14px",
     
    textAlign:'center',
  },
  price: {
    fontSize: "1.8rem",
    textAlign:'center',
    margin:'10px',
    color:'black!important',
    "&>span": {
      fontWeight: 400,
    },
  },
  pricepm: {
    fontSize: "0.8rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  title: {
    fontSize: "1.0rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  vehicleContainer: {
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
  },
  btn1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
    background: "white",
    border: "1px solid black",
  },
  btn2: {
    minWidth: "100%",
  },
  image: {
    padding:'0px 0px',
    "& > div > div": {
      backgroundColor: "#ffffff !important",
    },
  },
 
  carsLoadContainer: {
    display: "flex",
    justifyContent: "center",
  },
  CarsLoad: {
    margin: "auto",
  },
  carHolder:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow:' 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)',
    padding:'0px 10px',
    paddingBottom:'10px',
    margin:'10px',
    maxWidth:'30% ',
    textAlign:'center',
    '@media (max-width: 426px)': {
      maxWidth:'100%',
   },
  },
  flexHolder:{
    display:'flex',
    background:'#f2f2f2',
  color:'black'
  },
  flexbtn: {
    display:'flex',
    justifyContent: 'center',
    textAlign:'center',
    padding:'10px 0px',
       width: "50%",
       '&:hover': {
        backgroundColor: '#2e3975',
        opacity: 0.8
    }
     },
     unset:{
      flexWrap:'unset',
      '@media (max-width: 959px)': {
        flexWrap:'wrap',
     },
},
mainTitle:{
  padding:'0px',
  margin:'0px',
  fontWeight:'normal',
  fontSize:'1.3rem',
  textTransform: 'uppercase',
},
location:{
  display:'flex',
  justifyContent:'center',
},
warrantyHolder:{
  background:'#3e3f40',
  color:'white',
  display:'flex',
  padding:'10px',
  fontSize:'0.8rem',
  '@media (max-width: 426px)': {
    fontSize:'1rem',
 },
 '@media (min-width: 427px) and (max-width: 768px)': {
  fontSize:'0.8rem',
},
},
yellow:{
  color:'#2e3975',
},
warrantyYearHolder:{
  borderLeft:'2px #535455 solid',
  paddingLeft:'10px'
},
warrantyTextHolder:{

  paddingRight:'5px'
},
dividerTitle: {
  backgroundColor: `#2e3975`,
  width: '33%',
  marginBottom: '0px',
  height: '3px',
  margin: '20px auto'
},

}
));

const Compare = () => {
    const classes = useStyles();
    const { loading, error, vehicles, total } = VehicleController()
    let {compareVal} = useContext(CompareContext)
    const [activeVehicle,setActiveVehicle] = useState(null);
    let compareData = []

    const {setCompareVal} = useContext(CompareContext)
 
  
    const {setFavouriteVal} = useContext(FavouriteContext)
    const {favouriteVal} = useContext(FavouriteContext)
    useEffect(()=>{
       
        compareData = [...compareVal]
        console.log(compareData)
    },[compareVal])
   



  
    const buildName = (vehicle) => {
        if (!vehicle) return "";
    
        return vehicle.brand + " " + vehicle.model;
        };
    return (
        <>

        <Container maxWidth="lg" className={classes.content}>
        <Grid item xs={12} sm={12} md={12}>
                        
                        
            <Typography
                        gutterBottom
                        variant="h5"
                        align="center"
                        color="textPrimary"
                        fontWeight="500"
                    >
                        COMPARE
                    </Typography>
                  
               
                    <Divider className={classes.dividerTitle} />
                      </Grid>
                   
                 
                {compareVal.map((data, index) => {
                        return (
                      
                     
                            <Grid item xs={12} sm={6} md={4} key={index} className={classes.carHolder}>
                          <Link
                                underline="none"
                                component={RouterLink}
                                to={"/stock/" + data.stockId}
                          >
                                <Box className={classes.vehicleContainer}>
                                  <Box className={classes.image}
                                   onClick={()=>setActiveVehicle(data)}>
                                    <ImageContainerUsedVehicle
                                      aspectRatio="auto auto"
                                      height="222px"
                                      position="relative"
                                      src={data.image ? data.image : data.mainUrls[0] }
                                      alt={buildName(data.title)}
                                      
                                    />
                                  </Box>
                                  <Box></Box>
                                  <Box
                                    mt={2}
                                    mb={0}
                                    textAlign="left"
                                    component="h5"
                                    className={`${classes.title} ${classes.bold}`}
                                  >
                                    {buildName(data.title)}
                                  </Box>
      
                                  <Grid
                                    item
                                    md={12}
                                    mt={0}
                                    mb={0}
                                    className={` ${classes.bold}`}
                                  >
                                    <Grid item xs={12} sm={12} md={12}>
                                    <p className="clear" className={classes.mainTitle}>{data.brand}<br/>
                            {data.model}</p>
                                      <Box
                                    mt={0}
                                    mb={0}
                                    textAlign="center"
                                    component="p"
                                    className={`${classes.title} ${classes.lighter} `}
                                  >
                                    <p className="clear">
                            {data.year} &nbsp;|&nbsp; {data.mileage} km &nbsp;| &nbsp;{data.newUsed}
                          </p>
                                  </Box>
                                  <div className={classes.location}>
                       <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-location-twitter-flatart-icons-outline-flatarticons.png"/>
                            <p className="clear">{data.dealershipName}</p>
                         </div>
                                    </Grid>
      
                                    <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            
                            justify="center"
                            className={`${classes.price} ${classes.bold}`}
                          >
                            <CurrencyValue value={data.price} className={`${classes.price} ${classes.bold}`}/>

                           
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            style={{color:'black',fontSize:'0.8rem',padding:"10px 10px",fontWeight:'lighter'}}
                            justify="center"
                    
                          >
                            R {Math.round(data.price / 60) .toFixed(0)} pm -10% deposit, 60 months, <br/>7% interest

                           
                          </Grid>
                                  </Grid>
                                  </Box>
                                </Link>
                             

                              <Box mb={0} className={classes.flexHolder}>
                              <div
                                      variant="contained"
                                      gutterBottom
                                      className={classes.flexbtn}
                                      color="primary" endIcon={<AddIcon />}
                                              color="primary"
                                              onClick={(()=>{
                                              
                                                  setFavouriteVal([...favouriteVal,data])
                                           
                                             
                                              })}
                                    >
                                       favourites &nbsp;  <img src="https://img.icons8.com/material-outlined/24/000000/plus--v1.png"  width="20px" height="20px"/>
                                    </div>
                                    <div style={{    background: 'black',width: '1px',margin: '10px 0px',}}></div>
                       
                                    <div
                                      variant="contained"
                                      gutterBottom
                                      className={classes.flexbtn}
                                      color="primary" endIcon={<AddIcon />}
                                      onClick={(()=>{
                                        compareVal = compareVal.filter((item,idx) =>{
                                            return(
                                                idx != index
                                            )
                                        })
                                        setCompareVal(compareVal)
                                      })}
                                    >
                                     compares  &nbsp;<img src="https://img.icons8.com/fluency-systems-regular/24/000000/minus.png"  width="20px" height="20px"/>
                                    </div>
                                  
                                   
                              
                     
                                  </Box> 
                                  <Box mb={0} className={classes.warrantyHolder}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.warrantyTextHolder}
                          >
                           
                           Balance of <br/><span className={classes.yellow}>Manufacturer Warranty *</span>
                          
                          </Grid>
                          {/* <Grid
                            item
                            xs={2}
                            sm={2}
                            md={2}
                            className={classes.warrantyYearHolder}
                          >
                           
                          4  <br/>Years
                           
                          </Grid> */}
                        </Box>
                             
                            </Grid>
                        )})}
                             </Container>
    
   
     
            </>
            
   );
   
}

export default Compare;