import React, { useContext, Fragment,useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Mail from '../shared/assets/mailFooter.png'
import Phone from '../shared/assets/phone.jpg'
import Location from '../shared/assets/location.jpg'
import Facebook from '../shared/assets/facebook.jpg'
import { MakeContext } from '../shared/contexts/MakeContext'
import { useMediaQuery as mq } from 'react-responsive';
import { DealerContext } from '../shared/contexts/DealerContext'
import { MASTER_DEALERID } from "../shared/Constants"
import Logo from '../shared/assets/eltGroup.png';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    container: {
        minHeight: '300px',
        padding: '10px 33px',
        background:' #414141',
        color:'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    footerImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: 'white',
        height: '100px',
    },
    iconsWrapper: {
        height: 120,
    },
    icons: {
        display: 'flex',
    },
    icon: {
        width: 48,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        color: '#000000'
    },
    list: {
        margin: 0,
        listStyle: 'none',
        padding: 0,  
    },
    listbottom: {
        margin: 0,
        listStyle: 'none',
        padding: 0,
        display: 'flex',
    },
    background: {
        display: 'flex',
        margin: 0,
        listStyle: 'none',
        padding: '10px 5%',
        maxWidth: 'unset',
    },
    listbottom: {
        display: 'flex',
        margin: 0,
        listStyle: 'none',
        padding: '10px 20px',

    },
    bottomlistItem: {
        paddingRight: theme.spacing(2),
    },
    listItem: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        alignItems: 'center',
        display:'flex',
        marginBottom:'0.5rem',
    },
    language: {
        marginTop: theme.spacing(1),
        width: 150,
    },
    footerIconSection: {
        backgroundColor: ({ secondaryColor }) => `${secondaryColor} `,
    },
    footerIconSectionLink: {
        color: 'white',
        textTransform: 'capitalize'
    },
    footerIconSectionIcon: {
        backgroundColor: ({ secondaryColor }) => `${secondaryColor} `,
    },
    footerLink: {
        color: '#d3d3d3! important',
        textTransform:'capitalize',
        cursor:"pointer",
        fontSize:'0.9rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration:'none',    
        '&:hover': {
            borderColor: '#fff',
            boxShadow: 'none',
            color: '#fff !important'
        },
    },
    mb: {
        marginRight: '10px'
    },
    stickyBarInner: {
        backgroundColor: "#F8F8F8",
        textAlign: "center",
        position: "fixed",
        left: "0",
        bottom: "0",
        height: "60px",
        width: "100%",
    },
    stickyBar: {
        display: 'block',
        padding: '20px',
        height: '60px',
        width: '100%'
    },
    stickyBarImage: {
        width: '100%',
        height: '100%'
    },
    footerLinkLogo: {
        width: '25px',
        borderRadius:'2px',
        margin: '0px 0.5rem',
     
        '@media(min-width: 0px) and (max-width: 768px)': {
            width: '15px',
        },
    },
    calcHolder: {
        position: 'fixed',
        top: '0',
        background: '#9095a0a8',
        zIndex: '20',
        width: "100%",
        height: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        height: '80%',
        paddingRight: '30px'
    },
    buttonInvert: {
        width: '200px',
        height: '42px',
        bottom: '0px',
        right: '0px',
        borderRadius: '35px !important',
        fontWeight:'normal!important',
        textAlign: 'center',
        justifyContent: "center",
        alignContent: 'flex-end',
        color: `#2e3975 !important`,
        backgroundColor:"#fff !important",
        boxShadow: 'none',
        textTransform:'none',
        fontSize: 16,
        padding: '6px 0px',
        border: '3px solid',
        lineHeight: 1.5,  
        borderColor: '#2e3975',
        marginTop: '25px',
        marginBottom: '10px',
        '&:hover': {
            backgroundColor: '#2e3975 !important',
            borderColor: '#fff',
            boxShadow: 'none',
            color: '#fff !important'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#fff',
            borderColor: '#fff',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.1rem rgba(46,57,117,.5)',
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        allignItems: 'center',
    },
    sectionHeader: {
        paddingTop: '25px',
        paddingBottom: '10px'
    },
    enquireButton: {
        bottom: '0px',
        right: '0px',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
}));


export default function FooterComponent() {

    let cancel;

    const { websiteColors, globalDealer } = useContext(DealerContext)
    const classes = useStyles({ ...websiteColors });
    const [open, setOpen] = useState(false)
    const [enableEnquire, setEnableEnquire] = useState(false)
    const history = useHistory()

    let openCalc = () => {
        if (open === false) {
            setOpen(true)

        } else {
            setOpen(false)

        }
    }

    useEffect(() => {
        if(history?.location?.pathname.includes('our-vehicles') || 
        history?.location?.pathname.includes('models') && 
        history.location.pathname !== '/'){
            setEnableEnquire(true)
          }else{
            setEnableEnquire(false)
          }
        return history.listen((location) => {
            if(location?.pathname.includes('our-vehicles')|| location?.pathname.includes('models')){
                setEnableEnquire(true)
              }else{
                setEnableEnquire(false)
            }
        })

    },[history])

    return (
        <Fragment>
            <Typography component="footer" className={classes.container}>
                <Container className={classes.footer}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={enableEnquire ? 3 : 4}  className={classes.footerSection}>
                            <Typography variant="h6" marked="left"  className={classes.sectionHeader}>
                            LEGAL
                            </Typography>
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="/privacy">Privacy Policy</Link>
                                </li>
                                <li className={classes.listItem} onClick={()=>{openCalc()}}>
                                    <Link className={classes.footerLink} href="/terms">{`Terms & Conditions`}</Link>
                                </li>
                                <li className={classes.listItem} onClick={()=>{openCalc()}}>
                                    <Link className={classes.footerLink} href="/cookie">{`Cookie Policy`}</Link>
                                </li>
                                <li className={classes.listItem} onClick={()=>{openCalc()}}>
                                    <Link className={classes.footerLink} href="/requestergws">{`POPIA Policy`}</Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={6} md={enableEnquire ? 3 : 4}  className={classes.footerSection}>
                            <Typography variant="h6" marked="left"  className={classes.sectionHeader}>
                            TOOLS
                            </Typography>
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="/after-sales">After Sales</Link>
                                </li>
                       
                                <li className={classes.listItem}>
                                <a href='https://bausedtruckandtrailer.com/stock'           className={classes.footerLink}>
                                Search Pre-Owned Vehicles
                    </a>
                                </li>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="/roadside-assistance">Roadside Assistance</Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={6} md={enableEnquire ? 3 : 4}  className={classes.footerSection}>
                            <Typography variant="h6" marked="left"  className={classes.sectionHeader}>
                            CONTACT US
                            </Typography>
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="tel:+27116833364">
                                        <img src={Phone} className={classes.footerLinkLogo} alt={'logoPhone'}/>
                                        +27 11 683 3364
                                    </Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="mailto:info@eltgroup.co.za">
                                        <img src={Mail} className={classes.footerLinkLogo} alt={'logoMail'} />
                                        <span style={{ textTransform: 'lowercase' }}>info@eltgroup.co.za</span>
                                    </Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="https://goo.gl/maps/fbmfyz1HMHKrnvx68" target="_blank">
                                        <img src={Location} className={classes.footerLinkLogo} alt={'logoContactUs'} />
                                        101 Turffontein Road | Glenesk
                                    </Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="https://www.facebook.com/eltgroupcommercial/?ref=br_rs">
                                        <img src={Facebook} className={classes.footerLinkLogo} alt={'logoFacebook'} />
                                        Facebook
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        { enableEnquire && 
                        <Grid  item xs={12} sm={6} md={enableEnquire ? 3 : 4}  className={classes.enquireButton}>
                            <Button 
                                    variant="contained"
                                    className={classes.buttonInvert} 
                                    size="large" 
                                    href="/contact-us"
                                >
                                    ENQUIRE NOW
                                </Button>
                        </Grid>}
                    </Grid>
                </Container>
            </Typography>
            <div className={classes.footerImage} >
                <img src={Logo} className={classes.logo} alt="ELT Motor Group" />
            </div>
            
        </Fragment>
    );
}
