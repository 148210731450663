import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import Drive from '../shared/assets/steering-wheel.svg';
import Phone from '../shared/assets/phone-call.svg';
import Placeholder from '../shared/assets/placeholder.svg';
import Mobile from '../shared/assets/noun_Mobile_2969983.svg';
import RoadSideAssistance from '../shared/assets/assistance.png';
import EnquireNow from '../shared/assets/enquire.png';
import PhoneCall from '../shared/assets/phone-call.png';

const useStyles = makeStyles(() => ({
    root: {
        transform: (hidden) => `translate(${hidden}px, -50% )`,
        position: 'fixed',
        top: '50%',
        zIndex: '90',
        paddingRight: '20px',
        background: '#fff',
        opacity: 0.90,
        right: '-40px',
        transition: '0.2s all ease-in',
        borderRadius: '15px 0px 0px 15px',
        boxShadow: 'rgb(0 0 0 / 38%) 0px 0px 15px',
        '@media (min-width:0px) and (max-width:768px)': {
            top: '50%',
            right: '-30px',
        }
    },
    section: {
        padding: '8px',
        display: 'flex',
        borderRadius: '15px 0px 0px 15px',
        alignItems: 'center',
        '& a': {
            display: 'flex',
            alignItems: 'center',
            color: 'black'
        },
        '&:nth-child(1)': {
            '&:hover': {
                background: '#1b224a ',
                borderRadius: '15px 0px 0px 0px',
                '& img': {
                    filter: 'invert(1)'
                },
                '& div': {
                    color: 'white'
                }
            }
        },
        '&:nth-child(2)': {
            '&:hover': {
                background: '#1b224a ',
                borderRadius: '0px 0px 0px 0px',
                '& img': {
                    filter: 'invert(1)'
                },
                '& div': {
                    color: 'white'
                }
            }
        },
        '&:nth-child(3)': {
            '&:hover': {
                background: '#1b224a ',
                borderRadius: '0px 0px 0px 15px',
                '& img': {
                    filter: 'invert(1)'
                },
                '& div': {
                    color: 'white'
                }
            }
        },
        '&:nth-child(4)': {
            '&:hover': {
                background: '#1b224a ',
                borderRadius: '0px 0px 0px 15px',
                '& img': {
                    filter: 'invert(1)'
                },
                '& div': {
                    color: 'white'
                },

            }
        }
    },
    sectionIcon: {
        width: "40px",
        '@media (min-width:0px) and (max-width:768px)': {
            width: '25px',
        }
    },
    sectionText: {
        padding: '0px 10px',
        textTransform: 'uppercase',
        color: '#1b224a',
        textDecoration: 'none' ,
        '@media (min-width:0px) and (max-width:768px)': {
            fontSize: '15px',
        }
    }
}));

const BottomPopUp = () => {

    const [hidden, setHidden] = useState(175);
    const classes = useStyles(hidden);

    return (
        <div className={classes.root} onMouseEnter={() => { setHidden(0) }} onMouseLeave={() => { setHidden(175) }}>
            <div className={classes.section}>
                <a href="tel:+278617362858">
                    <div className={classes.sectionIcon} >
                        <img src={RoadSideAssistance} alt="Test drive" width="100%" />
                    </div>
                    <div className={classes.sectionText} >
                    Roadside Assistance
                    </div>
                </a>
            </div>
            <div className={classes.section}>
                <a href="mailto:info@eltgroup.co.za">
                    <div className={classes.sectionIcon} >
                        <img src={EnquireNow} alt="App" width="100%" />
                    </div>
                    <div className={classes.sectionText} >
                        Enquire Now
                    </div>
                </a>
            </div>
            <div className={classes.section}>
                <a href="tel:+27116833364">
                    <div className={classes.sectionIcon} >
                        <img src={PhoneCall} alt="Contact us" width="100%" />
                    </div>
                    <div className={classes.sectionText} >
                        Contact Us
                    </div>
                </a>
            </div>

        </div>
    );
};

export default BottomPopUp;