import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
    sliderContainer: {
        position: 'relative',
        '@media (max-width: 425px)': {
            height: '122px'
        },
    },
    carouselBack: {
        left: '10px',
        background:'#000000',
       
        height:'40px',
        width:'40px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%);',
        all: 'unset',
        cursor: 'pointer',
      
        
    },
    carouselNext: {
        right: '10px',
        background:'#000000',

        height:'40px',
        width:'40px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%);',
        all: 'unset',
        cursor: 'pointer',    
      
    },
    carouselDots: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
        marginBottom: '15px',
    
        '& > button': {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            border: 'none',
            padding: '0',
            cursor: 'pointer',
            marginRight: '10px',
            backgroundColor: '#EFDF00',
            opacity:'0.7'

        },
        '& > button:disabled': {
            backgroundColor: 'grey',
            opacity:'0.2'

        }
    },
    backgroundImage: {
        backgroundPosition: 'center center',
        backgroundSize:'100% 100%!important',
        padding:'0px',
        
        '@media (max-width: 624px)': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            height: '36%'
        },
    },
    //slide: {
    //    padding: "10%!important",
    // 
    //   '@media(min-width: 0px) and (max-width: 375px)': {
    //       marginBottom: "15px!important",
    //   },
    //},
}));


const CarouselSingle = ({ slides, innerClass, dots, onClick, ...props }) => {
    const classes = useStyles();
    return (
        <CarouselProvider
            {...props}
        >
            <div className={classes.sliderContainer}>
                <Slider>
                    {
                        slides && slides.map((slide, index) =>
                            <Slide innerClassName={innerClass} className={classes.slide} index={index} onClick={() => { onClick(slide) }}>
                           
                                {slide.content}
                                {slide.content ? <Image tag="div" isBgImage src={slide.img ? slide.img : slide} className={classes.backgroundImage} /> :
                                    <Image src={slide.img ? slide.img : slide} />}
                            </Slide>
                        )
                    }
                </Slider>
            
                <ButtonBack className={classes.carouselBack}><ChevronLeftIcon style={{ fontSize: 40 , color: 'white'}} /></ButtonBack>
                <ButtonNext className={classes.carouselNext}><ChevronRightIcon style={{ fontSize: 40 , color: 'white' }} /></ButtonNext>

            </div>
        </CarouselProvider>
    )
}

export default CarouselSingle;
