export const BASE_URI = process.env.REACT_APP_CONEXA_API_URL;
// export const BASE_URI = "http://localhost:5001/api"
export const SELF_URI = "http://localhost:3000/renault"
export const WEPAY4CARS_URI = "https://c2-wepay4cars-marketdealer.azurewebsites.net/api";
export const PAGE_SIZE= 20;
export const HAS_MULTIPLE_DEALERSHIPS = false
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;

export const DEALERID = 281
export const MASTER_DEALERID = 281
export const MASTER_CONFIGURATOR_DEALERID = 286
export const MASTER_DEALER_NAME = "ELT Master"
export const MOTORGROUPID = 47
export const SECONDARYCOLOR = "white"
export const BASECOLOR = "white"

export const VIRTUAL_URI_RANGE = `https://configurator.renaultmotors.co.za/${DEALERID}/range/:leadId`
export const VIRTUAL_URI_DERIVATIVE = `https://configurator.renaultmotors.co.za/${DEALERID}/:modelId/builder/:leadId`
export const VIRTUAL_URI_APPLICATION = `https://configurator.renaultmotors.co.za/${DEALERID}/1/vehicle/finance/0/:leadId`

export const FINAPP_URI_BASE = "https://dz6rhlcy38qhy.cloudfront.net";
export const FINAPP_URI_APPLICATION = `${FINAPP_URI_BASE}/${DEALERID}/1/vehicle/finance/0/:leadId`;