import React, { useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { DealerContext } from '../shared/contexts/DealerContext'
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        'li': {
            display: 'block;'
        },
        'li: before': {
            content: 'counters(item, ".") ". "',
            counterIncrement: 'item'
        },
        'ol': {
            counterReset: 'item'
        },
        'li strong': {
            display: 'inline-block',
            margin: '10px 0'
        }
    },
    link: {
        color: 'blue',
        textDecoration: 'none',
        '&:hover': {
            color: 'blue',
            textDecoration: 'none'
        }
    }
}));

const Terms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <div className={classes.content} >
                <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    PRIVACY POLICY
                </Typography>
                <p>
                <strong>Please read this Privacy Policy carefully. By using Conexa, which is owned by Market Dealer Holdings (Pty) Limited (MDH) you are acknowledging that you understand and agree to the terms of this Privacy Policy and how your Personal Information is used and treated. Please also review the Terms and Conditions which govern your use of Conexa and the services provided by Conexa.</strong>
                <br/>
                <br/>
                <ol>
                    <li>
                        <strong>Introduction</strong>
                        <ol>
                            <li>
                                This privacy policy (“Privacy Policy”) applies solely to Personal Information (as defined in the Protection of Personal Information Act, 4 of 2013 (“POPIA”) collected by Market Dealer Holdings (Pty) Limited (“MDH”) as a
                                result of you using Conexa and completion the Finance Application.
                            </li>
                            <li>Conexa is the Application Programming Interface through which a virtual sales assistant (known as Conexa) assists in taking you through the initial steps required when purchasing a vehicle.</li>
                            <li>The Finance Application is your application for vehicle finance which is submitted to MFC, a Division of Nedbank Limited (“Bank”), via Conexa.</li>
                            <li>All references to “you/your” in this Privacy Policy will be references to the user of Conexa.</li>
                            <li>This Privacy Policy explains what information may be collected through the use of Conexa, how such information may be used and shared with others, and how it is safeguarded.</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Information collected by Conexa</strong>
                        <ol>
                            <li>Certain Personal Information which will identify you, or from which you can be reasonably identified, is collected by Conexa when you complete the Finance Application.</li>
                            <li>
                                The information that does not identify you, such as anonymous or aggregated data, is not covered in this Privacy Policy. This information may be collected, used and disclosed by MDH in order to evaluate and improve the
                                services of MDH and that way in which Conexa is managed and operated. This information may also be shared with MDH’s partners or advertisers for other purposes, without limitation.
                            </li>
                            <li>
                                All Personal Information which you provide when utilising Conexa will be processed and shared with the Bank, to enable the Bank to assess your Finance Application. This Personal Information will also be shared with the motor
                                vehicle dealership (“Dealership”) from whose website you accessed Conexa, to enable the Dealership to contact you regarding your interest in purchasing a vehicle.
                            </li>
                            <li>Other than if required by law, MDH does not collect, store of use the Personal Information in any other manner other than as mentioned above.</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Information that is collected automatically</strong>
                        <ol>
                            <li>
                                Whenever you interact with Conexa, we may use a variety of technologies that automatically or passively collect certain information about how Conexa is accessed and used. This may include information about your operating
                                system and your location.
                            </li>
                            <li>We may also automatically collect your Internet Protocol address or other unique identifier for the computer, mobile device, technology or other device you use to access Conexa.</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Security of Information</strong>
                        <ol>
                            <li>
                                MDH is dedicated to safeguarding your Personal Information whilst it is in MDH’s possession and shall take appropriate, reasonable, technical and organizational measures to safeguard your Personal Information in order to
                                prevent loss of, damage to or unauthorized destruction of your Personal Information or unlawful access to or processing of your Personal Information. However, no electronic data transmission or storage of information can be
                                guaranteed to be completely secure.
                            </li>
                            <li>MDH is not responsible for your Personal Information in any way whatsoever, once it has been furnished to the Bank and/or the Dealership, in accordance with the Terms and Conditions and this Privacy Policy.</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Password and user name</strong>
                        <ol>
                            <li>
                                Should the access of Conexa require a user name and password, then it is your responsibility to choose a user name and password and to keep them safe. You agree and warrant that your username and password will be used for
                                personal use only and not be disclosed by you to any third party.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Third party websites</strong>
                        <ol>
                            <li>
                                Conexa is accessed from a third-party website, which may contain other links or apps that we do not own or operate. We do not control, recommend or endorse and are not responsible for these websites or their content,
                                products, services of privacy policies or practices.
                            </li>
                            <li>Third-party websites may send their own “cookies” to your device, they may independently collect data or solicit Personal Information and may or may not have their own published privacy policies.</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Amendments to this Privacy Policy</strong>
                        <ol>
                            <li>This Privacy Policy was published on 2021/03/30 and replaces with immediate effect any Privacy Policy previously published.</li>
                            <li>We may amend or update this Privacy Policy at any time. Changes will become effective as soon as posted.</li>
                        </ol>
                    </li>
                    <li><strong>Contact Details</strong></li>
                    <ol>
                        <li>If you have any questions about Conexa or this Privacy Policy, please contact us on support@mdhdigital.com.</li>
                    </ol>
                </ol>

                </p>
            </div>
            
            <div>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Additional Links
                </Typography>
                    <ul>
                        <li>
                            <Link component={RouterLink} to="/requestergws" className={classes.link}>Personal information request form</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/privacy" className={classes.link}>Policies</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/cookie" className={classes.link}>Cookie Policy</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/terms" className={classes.link}>Terms & Conditions</Link>
                        </li>
                    </ul>
                </div>
                <br/>
                
        </Container>
    )
}

export default Terms