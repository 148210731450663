import React, { useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { ImageContainer } from '../shared/ImageContainer'
import ContactComponent from '../shared/ContactComponentService'
import { DealerContext } from '../shared/contexts/DealerContext'
import axios from 'axios';
import MultiPointChecks from '../shared/assets/Multi-Point-Check-5231-1.jpg';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6, 0, 6),
    },
    dividerTitle: {
        backgroundColor: '#2e3975',
        width: '33%',
        marginBottom: '0px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    url: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none',
            
        }
    },
    xlbuttonFix: {
        paddingRight:'65px !important',
        fontWeight:'lighter !important',
        textTransform:'capitalize !important'

    },
    link: {
        backgroundColor: '#E20A17',
        marginBottom: '1rem',
        color: '#ffffff',
        fontWeight: '700',
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8
        }
    }
}));

const BreakSpecial = () => { 
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <Box>
                {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={contactUsImage} alt='Contact Us' />*/}
            </Box>
            <div className={classes.content}>

            <img src={MultiPointChecks} style={{ width: '100%',marginTop:'-51px' }}/>
                <br/>
             
                <Grid item xs={12} md={2}></Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <ContactComponent leadTypeId={5} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                        <Typography gutterBottom variant="h3" align="center" color="textPrimary" fontWeight="500" style={{ fontWeight:'bold' }}>
                            Multi-Point-Check
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

export default BreakSpecial;