import React,{useEffect} from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import DashboardContainer from './components/MainContainer'
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import Cookie from './components/shared/CookiePopUp'
import ScrollToTop from './components/shared/ScrollToTop'
import { DealerProvider } from "./components/shared/contexts/DealerContext";
import { MakeProvider } from "./components/shared/contexts/MakeContext";
import { CompareProvider } from './components/shared/contexts/CompareContext';
import { FavouriteProvider } from './components/shared/contexts/FavouriteContext';
import BottomPopUp from './components/SidePopUp/BottomPopUp';

const theme = createMuiTheme({
    typography: {
        fontFamily: [
           
            'NotoSans',
        
        ].join(','),
    },
    overrides: {
        MuiTextField: {
            root: {
                marginBottom: '1rem'
            }
        },
        MuiTypography: {
            h4: {
                fontWeight: 700
            },
            h5: {
                textTransform: 'capitalize',
                fontWeight: 700
            },
            h6: {
                fontWeight: 700
            },
            subtitle2: {
                fontSize: '1rem'
            }
        },
        MuiDialogActions: {
            root: {
                padding: '1.5rem 1rem'
            }
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#2e3975',
                color: '#000',
                borderRadius: '0px',
                '&:hover': {
                    backgroundColor: '#2e3975',
                    opacity: 0.8
                }
            },
            contained: {
                borderRadius: '0px'
            }
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': "NotoSans",
                body: {
                    backgroundColor: '#ffffff',
                    fontSize: '100%'
                }
            }
        }
    }
});

function App() {

    const parsedData = window.location.pathname.split("/");
    var domain = "/"

    if (parsedData.length > 1 && parsedData[1] == "dealer") {
        domain = "/dealer/" + parsedData[2];

        if (parsedData.length > 3 && parsedData[3] == "make") {
            domain += "/make/" + parsedData[4];
        }
    }

      useEffect(() => {
        console.log(window.location.href);
          switch (window.location.href) {
  
              case "https://www.eltgroup.co.za/used-vehicles":
              
                  window.location.replace("https://bausedtruckandtrailer.com/stock");
                  break;
          }
          },[])
    return (
        <Router basename={domain}>
            <Cookie />
            <ScrollToTop />
            <FavouriteProvider>
                <CompareProvider>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}>
                            <CssBaseline />
                            <DealerProvider>
                                <MakeProvider>
                                    <BottomPopUp />
                                    <DashboardContainer />
                                </MakeProvider>
                            </DealerProvider>
                        </SnackbarProvider>
                    </ThemeProvider>
                </CompareProvider>
            </FavouriteProvider>
        </Router>
    );
}

export default App;
