import React, { createContext, useState, useEffect, useContext } from 'react';
import { DealerContext } from './DealerContext'

export const MakeContext = createContext();

export const MakeProvider = ({ children }) => {
    const getItem = (itm) => {
        let data = localStorage.getItem(itm);

        if (data && data != "undefined")
            return JSON.parse(data)
        else
            return {
            }
    }
    const setItem = (name, object) => {
        var stringObject = JSON.stringify(object);
        localStorage.setItem(name, stringObject);
    }

    const [make, setMake] = useState(getItem('activeMake'));
    const { globalDealer, websiteColors } = useContext(DealerContext)
    const [makeColors, setMakeColors] = useState({
        primaryColor: make?.primaryColor ? make?.primaryColor : websiteColors?.primaryColor,
        secondaryColor: make?.secondaryColor ? make?.secondaryColor : websiteColors?.secondaryColor,
        accentColor: make?.accentColor ? make?.accentColor : websiteColors?.accentColor,
        logo: make?.logo ? make?.logo : websiteColors?.logo
    })



    const setActiveGlobalMake = (activeMake) => {
        setItem('activeMake', activeMake);
        setMake(activeMake);
    }

    useEffect(() => {

        const parsedData = window.location.pathname.split("/");
        /*const parsedData = window.location.pathname.split("/");
        let makeName = ""
 
        if (parsedData.length > 1 && parsedData[1] == "make") {
            makeName = parsedData[2];
        }
 
        if (parsedData.length > 3 && parsedData[3] == "make") {
            makeName = parsedData[4];
        }
 
        if (globalDealer && globalDealer.makes) {
 
            var makes = globalDealer.makes.filter(itm => itm.name.toLowerCase() == makeName.toLowerCase());
 
            if (makes.length > 0) {
                setMake(makes[0]);
            }
        }
        }*/

    }, [globalDealer.makes])

    useEffect(() => {
        if (make?.name) {
            const colors = {
                primaryColor: make?.primaryColor ? make?.primaryColor : websiteColors?.primaryColor,
                secondaryColor: make?.secondaryColor ? make?.secondaryColor : websiteColors?.secondaryColor,
                accentColor: make?.accentColor ? make?.accentColor : websiteColors?.accentColor,
                logo: make?.logo ? make?.logo : websiteColors?.logo
            }
            setMakeColors(colors)
        } else {
            setMake(null)
        }

    }, [make])

    return (
        <MakeContext.Provider value={{ make, makeColors, setActiveGlobalMake }}>
            {children}
        </MakeContext.Provider>
    )
};