import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { BASE_URI, PAGE_SIZE, MOTORGROUPID, DEALERID } from '../shared/Constants'
import { DealerContext } from '../shared/contexts/DealerContext'

export default function VehicleController() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [total, setTotal] = useState(false);
    const [pagedVehicles,setPagedVehicle] = useState([]);

    let { globalDealer } = useContext(DealerContext);
    let source;


    const pageVehicles = () => {
        var numPages = total / PAGE_SIZE
        var i = 0;
        var tmpPagesData = []
        for (i = 0; i < numPages; i++) {
            const index = i * PAGE_SIZE;
            tmpPagesData.push(vehicles.slice(index, index + PAGE_SIZE));
        }
        setPagedVehicle(tmpPagesData)
    }

    const getVehicles = () => {
        
        if (typeof source != typeof undefined) {
            source.cancel("Operation canceled due to new request.");
        }

        source = axios.CancelToken.source();

        setLoading(true);
        setError(false);
        setVehicles([]);

        const params = {
            pageNumber: 1,
            pageSize: 1000
        }
        
        if (globalDealer?.id != DEALERID) {
            params.dealerId = globalDealer.id;
        } else {
            params.motorGroupId = MOTORGROUPID;
        }
        
        axios({
            method: 'GET',
            url: `${BASE_URI}/stock`,
            params,
            cancelToken: source.token
        }).then((response) => {
            
            setVehicles(response.data?.list);
            setTotal(response.data?.total);

            setLoading(false);

        }).catch(error => {
            if (axios.isCancel(error)) return
            setLoading(false);
        })

    }

    useEffect(() => {
        getVehicles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getVehicles();

        // return () => {
        //     source.cancel();
        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalDealer.id])
    
    useEffect(() => {
        if (total > 0) {
            pageVehicles();
        }
    }, [total])

    return { loading, error, vehicles, total, pagedVehicles  }
}