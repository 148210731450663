import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, PAGE_SIZE, DEALERID } from '../shared/Constants'
import { DealerContext } from '../shared/contexts/DealerContext'

export default function DealsFilter(pageNumber, sortDirection) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [deals, setDeals] = useState([])
    const [hasMore, setHasMore] = useState(false)
    let { globalDealer } = useContext(DealerContext);
    let currentDate = new Date() 

    useEffect(() => {
        setDeals([])
    }, [sortDirection])

    useEffect(() => {
        let cancel
        setLoading(true)
        setError(false)

        const params = {
            pageNumber,
            pageSize: PAGE_SIZE,
            status: 'active',
            dealerId: globalDealer.id
        }
        axios({
            method: 'GET',
            url: `${BASE_URI}/Offers`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            setHasMore(response.data?.list.length > 0)
            setLoading(false)
            let filterd = response.data?.list.filter(d => d.dateTo >= currentDate.toISOString() && d.dateFrom <= currentDate.toISOString())
            setDeals(filterd)
            console.log("deal response",response.data?.list)
            console.log("filterd",filterd)
        }).catch(error => {
            if (axios.isCancel(error)) return
            setLoading(false)
        })
        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, sortDirection])

    return { loading, error, deals, hasMore }
}