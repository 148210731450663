import React, { useContext, useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Container from "@material-ui/core/Container";
import { MakeContext } from '../components/shared/contexts/MakeContext'
import Grid from "@material-ui/core/Grid";
import { BASE_URI, DEALERID,MASTER_DEALER_NAME } from './shared/Constants';
import axios from 'axios';
import { useMediaQuery as mq } from 'react-responsive'
import { DealerContext } from '../components/shared/contexts/DealerContext'
import Logo from './shared/assets/eltGroup.png';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import { fontSize } from '@material-ui/system';

const drawerWidth = 300;

const navBarStyles = makeStyles((theme) => ({
    searchBar: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '200px',
        height: '25px',
        backgroundColor: 'white',
        justifyContent: 'center',
        borderRadius: '15px',
        border: '1px solid #2e3975',
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: 10,
        height: '30px',
        borderRadius: '15px',
      },
      divider: {
        height: 28,
        margin: 4,
      },
      search: {
        transform: 'rotate(90deg)',
      },
    Container: {
        background: 'transparent',
        color: ({ primaryColor }) => `${primaryColor} !important`,
        
    },
    contactInfo: {
        width: '100vw',
        height: 'auto',
        color: 'white',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor:  ({ primaryColor }) => `${primaryColor} !important`,
    },
    linkHome:{
        padding: '1rem 2rem!important',
        minHeight: '80px',
        display: 'flex',
        alignItems: 'center',
     
        color: ({ primaryColor }) => `${primaryColor}`,
    },
    listItem: {
        color: 'white !important',
        padding: "5px 0px",
      
        alignItems: 'center',
        justifyContent: 'center',
        display:'flex',
     
        marginRight: '50px',
        '@media(min-width: 0px) and (max-width: 768px)': {
            marginRight: '0px',
        },
    },
    footerLinkLogo:{
        color: 'white !important',
        width: '25px',
        borderRadius:'2px',
        marginRight: '0.5rem',
        alignItems: 'center',
        '@media(min-width: 0px) and (max-width: 768px)': {
            width: '40px',
        },
    },
    link: {
        padding: '40px 15px !important',
        cursor: 'pointer',
        borderBottom: '5px solid #9f9f9f',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        fontFamily: 'NotoSans',
        position: 'relative',
        margin: '0.1px',
        textDecoration:'none',
        color:  '#2e3975',
        fontSize: '16px',
        fontWeight:'900',
        textTransform: 'none',
        '&:hover': {
            textDecoration: 'none',
     
            borderBottom: '5px solid #2e3975',
        },
        '&:hover::after': {
            content: '" "',
            position: 'absolute',
            display: 'block',
            textDecoration: 'none',
            width: '100%',
            left: 0,
            bottom: 0
        },
        '@media (max-width: 1440px)': {
            padding: '0.5rem !important',
        },
    },
    linkDrawer: {
        padding: '1rem !important',
        cursor: 'pointer',
        minHeight: '25px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        textAlign: 'left',
        fontFamily: 'NotoSans',
        position: 'relative',
        margin: '0.1px',
        textDecoration:'none',
        color:  '#2e3975',
        fontSize: '20px',
        textTransform: 'none',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '10px !important',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#2e3975',
            color: 'white',
        },
        '&:hover::after': {
            content: '" "',
            position: 'absolute',
            display: 'block',
            textDecoration: 'none',
            width: '100%',
            left: 0,
            bottom: 0
        },
        '@media (max-width: 1440px)': {
            padding: '0.5rem !important',
        },
    },
    linkBorder: {
        borderBottom: '1px solid',
        borderColor: '#707070',
        width: '50%',
        height: '2px',
        '&:hover': {
            borderColor: '#fff',
        },
    },
    linkSearchBar: {
        height: '124px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconLink: {
        padding: '1rem 0rem !important',
        cursor: 'pointer',
        minHeight: '64px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        position: 'relative',
        color: 'white',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'transparent'
        },
        '&:hover::after': {
            content: '" "',
            position: 'absolute',
            display: 'block',
            textDecoration: 'none',
            width: '100%',
            left: 0,
            bottom: 0
        }
    },
    active: {
        textDecoration: 'none',
    },
    background: {

        display:'flex',
        flexDirection: 'row',
        justifyContent:'space-between',
        background: 'linear-gradient(0deg, rgba(157,157,157,1) 0%, rgba(207,207,207,1) 40%, rgba(223,223,223,1) 100%)',
        '@media (max-width: 959px)': {
            display:'flex',
            justifyContent:'space-between',
        }
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        background:  `rgba(255,255,255,0.7) !important `,
        border: '1px solid #707070 !important',
        height: 'fit-content',
        marginRight: '10px',
        top: '100px',
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    gutter: {
        '@media (max-width: 426px)': {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    logoHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 1440px)': {
            padding: '15px',
            display: 'none',
        },
    },
    logoHolderMobile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 1440px)': {
            padding: '15px',
            
        },
    },
    logo: {
        zIndex: 10,
        width:'200px',
        '@media (max-width: 1441px)': {
            display: 'none',
        },
    },
    logoMobile:{
        width:'80%',
        '@media (min-width: 1441px)': {
            display: 'none',
        },
    },
    titleHolder: {
        padding: '0px 0px',
        textTransform: 'uppercase',
        '@media (min-width: 600px)': {
            padding: '0px 0px',
        }
    },
    title: {
        fontWeight: 'normal',
    },
    subTitleHolder: {
        color: '#D9D9D6',
        textTransform: 'lowercase',
    },
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    closeMenuButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    badgeButton: {
        '&:hover': {
            background: 'transparent',
            color: '#2e3975'
        },
        '&:focus': {
            outline: 'none'
        },
        '&:active': {
            color: '#2e3975'
        }
    },
    flexContainer: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'space-between',
        background: 'transparent',
        width: '100%'
    },
    grow: {
        flexGrow: 1,
    },
    root: {
        '& > * + *': {
            marginLeft: theme.spacing(2),
            '@media (max-width: 410px)': {
                marginLeft: '3px'
            }
        },
    },
    iconButtonStyles: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
    },
    flexWrap: {
        '@media (max-width: 410px)': {
            flexWrap: 'wrap'
        }
    },
    Img: {
        margin: '10px',
        '@media (max-width: 1024px)': {
            display: 'none'
        },
    },
    holder: {
        flex: 1,
        display: 'flex',
        justifyContent: 'start',
        '@media (max-width: 426px)': {
            justifyContent: 'end',
        },
    },
    badge: {
        background: '#39679f',
        color: 'white',
        padding: '0px',
        borderRadius: '50%',
        width: "20px",
        height: '20px',
        display: 'block',
        textAlign: 'center',
        position: 'absolute',
        right:'5px',
        top:'5px',
        fontSize: '0.8rem',
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    badgeimg: {
        width: "75px",
        '@media (min-width: 1025px) and (max-width: 1440px)': {
            width: "50px",
        },
        '@media (min-width: 1441px) and (max-width: 1920px)': {
            width: "50px",
        },
    },
    footerLink: {
        color: '#d3d3d3 !important',
        cursor:"pointer",
        fontSize:'17px',
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media(min-width: 0px) and (max-width: 930px)': {
            fontSize:'14px',
        },
    },
    mobileCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    topbarLinkText: {
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap' ,
        fontSize:'14px',
    }
}));

export default function NavbarComponent() {
    const history = useHistory()
    const { make, setActiveGlobalMake } = useContext(MakeContext)
    const { websiteColors } = useContext(DealerContext)
    const classes = navBarStyles({ ...websiteColors });
    const [mobileOpen, setMobileOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [navBarData, setNavBarData] = useState(false);
    const [activeDealer, setActiveDealer] = useState('');
    const anchorRef = useRef(null);
    let { globalDealer } = useContext(DealerContext);

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;

        axios({
            method: 'GET',
            url: `${BASE_URI}/dealerWebsites/${DEALERID}`
        }).then((response) => {

            setNavBarData({ title: response.data.title, subTitle: response.data.subTitle, logo: response.data.image });

            document.title = `${response.data.title}`
        });
    }, [open]);

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }
    
    const cleanName = (dealerName) => {
        return dealerName ? dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-') : ""
    }
  
    let getDealer = localStorage.getItem("globalDealer")
    let dealer = JSON.parse(getDealer)

    useEffect(() => {
        if(getDealer){
            if(dealer.name !== MASTER_DEALER_NAME){
                setActiveDealer(cleanName(dealer.name))
            }else{
                setActiveDealer(cleanName(MASTER_DEALER_NAME))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [make])

    function removeMake(path) {
        localStorage.removeItem("activeMake")
        setMobileOpen(false)
        setActiveGlobalMake('')
   
        if (path !== "/" && activeDealer !== MASTER_DEALER_NAME){
            if(globalDealer.name !== MASTER_DEALER_NAME){
                history.push(`/dealer/${cleanName(globalDealer.name)}/${path}`)
             }else{
                history.push(`/${path}`)
             }
        }
    }

    const menuLinks = (
        <React.Fragment>
            <Hidden smDown implementation="css"  >
                <div className={classes.flexContainer}>
                    <Link variant="button" color="textSecondary"
                        className={classes.link}
                        onClick={() => removeMake(`/`)}
                        component={RouterLink} to='/'
                        activeClassName={classes.active}>
                            Home
                    </Link>
                    <Link 
                        variant="button" 
                        color="textSecondary"
                        className={classes.link}
                        onClick={() => removeMake(`our-vehicles`)}
                        component={RouterLink} 
                        to='/our-vehicles'
                        activeClassName={classes.active}>
                            Our Brands
                    </Link>
                    <Link 
                        variant="button" 
                        color="textSecondary"
                        className={classes.link}
                        component={RouterLink} 
                        to='/special-deals'
                        activeClassName={classes.active}>
                            Special Deals
                    </Link>
                    <Link 
                        variant="button" 
                        color="textSecondary" 
                        component={RouterLink} 
                        to='/after-sales'
                        className={classes.link}
                        onClick={() => setMobileOpen(false)}
                        activeClassName={classes.active}>
                            After Sales
                    </Link>
                     <a 
                        href='https://bausedtruckandtrailer.com/stock'           
                        className={classes.link}>
                            Pre-Owned
                    </a>
                    <Link variant="button" color="textSecondary"
                        activeClassName={classes.active}
                        onClick={() => setMobileOpen(false)}
                        component={RouterLink} to='/sell-vehicle'
                        className={classes.link}>
                            Sell
                    </Link>
                    <Link variant="button" color="textSecondary" component={RouterLink} to='/about-us'
                        className={classes.link}
                        onClick={() => setMobileOpen(false)}>
                            About Us
                    </Link>
                    <Link 
                        variant="button" 
                        color="textSecondary" 
                        component={RouterLink} 
                        to='/roadside-assistance'
                        className={classes.link}
                        onClick={() => setMobileOpen(false)}
                        activeClassName={classes.active}>
                        Roadside Assistance
                    </Link>
                    <Link 
                        variant="button" 
                        color="textSecondary" 
                        component={RouterLink} 
                        to='/contact-us'
                        className={classes.link}
                        onClick={() => setMobileOpen(false)}
                        activeClassName={classes.active}>
                    Contact Us
                    </Link>
               
                    {/* <div className={classes.linkSearchBar}>
                        <div className={classes.searchBar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                <SearchIcon className={classes.search}/>
                            </IconButton>
                        </div>
                    </div> */}
                 </div>
            </Hidden>
        </React.Fragment>
    );

    const isMobile = mq({ query: '(max-width: 600px)' })
    const isTablet = mq({ query: '(max-width: 845px)' })
    
    const showInMapClicked = () => {
        window.open("https://maps.google.com?q=-26.233772140969705,28.042902195681844" )
    }

    return (
        <AppBar position="static" color="#2e3975" elevation={0} >
            <Grid container className={classes.contactInfo}>
            <Grid item xs={4} sm={3} md={3} className={isMobile && classes.mobileCenter}>
                <li className={classes.listItem}>
                    <a href="tel:+27116833364" className={classes.footerLink}>
                        <PhoneIcon className={classes.footerLinkLogo}/>
                        {!isMobile &&  <span style={{width: isTablet ? '100px' : '100%'}} className={classes.topbarLinkText}>+27 11 683 3364</span>}
                    </a>
                </li>
                </Grid>
                <Grid item xs={4} sm={3} md={3} className={isMobile && classes.mobileCenter}>
                    <li className={classes.listItem}>
                    <Link className={classes.footerLink} onClick={() => showInMapClicked()}>
                        <LocationOnIcon className={classes.footerLinkLogo}/>
                        {!isMobile && <span style={{width: isTablet ? '100px' : '100%'}} className={classes.topbarLinkText}>101 Turffontein Road | Glenesk</span>}
                    </Link>
                </li>
                </Grid>
                <Grid item xs={4} sm={3} md={3} className={isMobile && classes.mobileCenter}>
                <li className={classes.listItem}>
                    <a className={classes.footerLink} href="mailto:info@eltgroup.co.za">
                        <MailIcon className={classes.footerLinkLogo}/>
                        {!isMobile &&  <span style={{width: isTablet ? '100px' : '100%'}} className={classes.topbarLinkText}>info@eltgroup.co.za</span>}
                    </a>
                </li>  
                </Grid>
            </Grid>
            <Container maxWidth="flase" className={classes.background} >
            {!isTablet && <div className={classes.logoHolder}>
                        <Link component={RouterLink} to='/' >
                            <img src={Logo} className={classes.logo} alt="ELT Motor Group" />
                        </Link>
                </div>}
                <div className={classes.logoHolderMobile}>
                    <Link component={RouterLink} to='/' >
                        <img src={Logo} className={classes.logoMobile} alt="ELT Motor Group" />
                    </Link>
                </div>
                <Toolbar className={`${classes.flexWrap} ${classes.Container}`} disableGutters>
                    <div style={{ flex: 1, display: 'flex',justifyContent:'center' }}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        {
                            menuLinks
                        }
                    </div>
                    <React.Fragment>
                        <Hidden smUp implementation="css" style={{display:'flex'}}>
                            <Drawer
                                variant="temporary"
                                anchor={'right'}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true
                                }}
                            >
                                <div className="d-flex flex-column flex-sm-row">
                                    <Link variant="button" color="textSecondary" component={RouterLink} to='/'
                                        className={`${classes.linkDrawer}`}
                                        onClick={() => setMobileOpen(false)}
                                     > 
                                        Home
                                        <div className={classes.linkBorder}/>
                                    </Link>
                                    <Link 
                                        variant="button" 
                                        color="textSecondary"
                                        className={classes.linkDrawer}
                                        to='/our-vehicles'
                                        onClick={() => removeMake(`our-vehicles`)}>
                                        Our Brands
                                        <div className={classes.linkBorder}/>
                                    </Link>
                          
                    <Link 
                                        variant="button"
                                        color="textSecondary" 
                                        component={RouterLink} 
                                        to='/special-deals'
                                        onClick={() => setMobileOpen(false)}
                                        className={classes.linkDrawer} >
                                     Special Deals
                                        <div className={classes.linkBorder}/>
                                    </Link>
                                    <Link 
                                        variant="button"
                                        color="textSecondary" 
                                        component={RouterLink} 
                                        to='/after-sales'
                                        onClick={() => setMobileOpen(false)}
                                        className={classes.linkDrawer} >
                                        After Sales
                                        <div className={classes.linkBorder}/>
                                    </Link>
                                    <a 
                                        href='https://bausedtruckandtrailer.com/stock' 
                                        className={classes.linkDrawer} 
                                        >
                                        Pre-Owned
                                        <div className={classes.linkBorder}/>
                                    </a>
                                    <Link variant="button" 
                                        color="textSecondary"
                                        onClick={() => setMobileOpen(false)}
                                        component={RouterLink} 
                                        to='/sell-vehicle'
                                        className={classes.linkDrawer}>
                                        Sell
                                        <div className={classes.linkBorder}/>
                                    </Link>
                                    <Link 
                                        variant="button"
                                        color="textSecondary" 
                                        component={RouterLink} 
                                        to='/about-us'
                                        onClick={() => setMobileOpen(false)}
                                        className={classes.linkDrawer} >
                                        About us
                                        <div className={classes.linkBorder}/>
                                    </Link>
                                    <Link 
                                        variant="button"
                                        color="textSecondary" 
                                        component={RouterLink} 
                                        to='/roadside-assistance'
                                        onClick={() => setMobileOpen(false)}
                                        className={classes.linkDrawer} >
                                        Roadside Assistance
                                        <div className={classes.linkBorder}/>
                                    </Link>
                                    <Link 
                                        variant="button"
                                        color="textSecondary" 
                                        component={RouterLink} 
                                        to='/contact-us'
                                        onClick={() => setMobileOpen(false)}
                                        className={classes.linkDrawer} >
                                        Contact Us
                                        <div className={classes.linkBorder}/>
                                    </Link>
                                </div>
                            </Drawer>
                        </Hidden>

                    </React.Fragment>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
