import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import ContactComponent from '../shared/ContactComponent';
import { DealerContext } from '../shared/contexts/DealerContext';
import { BASE_URI } from '../shared/Constants';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6, 0, 6),
    },
    dividerTitle: {
        backgroundColor: "#2e3975",
        width: '33%',
        marginBottom: '0px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    url: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none'
        }
    },
    link: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} `,
        marginBottom: '1rem',
        color: '#ffffff',
        fontWeight: '700',
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8
        }
    }
}));

const ContactUs = () => {

    let cancel;

    const { websiteColors, globalDealer } = useContext(DealerContext);
    const classes = useStyles({ ...websiteColors });
    const [dealerDetails, setDealerDetails] = useState();

    useEffect(async () => {
        
        try {

            const response = await axios({
                method: 'GET',
                url: `${BASE_URI}/Dealers/DealerContactDetails/${globalDealer.id}`,
                cancelToken: new axios.CancelToken(c => cancel = c)
            });
            
            setDealerDetails(response.data);

        } catch (error) {
            if (axios.isCancel(error)) return;
        }

    }, [])

    return (
        <Container maxWidth="lg">
            <Box>
                {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={} alt='Contact Us' />*/}
            </Box>
            <div className={classes.content}>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                    Our Customer Relations Team Are Here To Help You
                </Typography>
                <Divider className={classes.dividerTitle} />
                <Box mb={3} mt={3}>
                    <Box textAlign="center" fontSize={'1.5rem'}>
                        Send us your details and we will call you
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Box fontWeight="fontWeightBold">General Enquiries</Box>
                        <Box mb={3}>
                            <Link href="tel:+27116833364">
                                011 683 3364
                            </Link>
                        </Box>
                        <Box fontWeight="fontWeightBold">Address</Box>
                        <Box mb={3}>
                            <Link href="https://goo.gl/maps/fbmfyz1HMHKrnvx68" target="_blank">
                                {dealerDetails?.address?.streetAddress || dealerDetails?.address?.postalAddress || "101 Turffontein Road | Glenesk"}
                            </Link>
                        </Box>


                        {/* <Box fontWeight="fontWeightBold">Email</Box>
                        <Box mb={3}>
                            <a className={classes.url} href="mailto:customercare@renaultretail.co.za?Subject=Renault%20Virtual%20Contact" title="Drop us message on email">customercare@renaultretail.co.za</a>
                        </Box> */}
                        <Box fontWeight="fontWeightBold">Opening Hours</Box>
                        <Box >Monday - Friday: 7:30 - 17:00</Box>
                                    <Box >Saturday: 8:00 - 13:00</Box>
                                    <Box  mb={3}>Sunday: Closed</Box>



                    </Grid>

                    <Grid item xs={12} md={6}>
                        <ContactComponent leadTypeId={3} />
                    </Grid>

                </Grid>
            </div>
        </Container>
    );
}

export default ContactUs;