import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import NavbarComponent from './NavbarComponent'
import HomeComponent from './homePage/HomeComponent'
import FooterComponent from './homePage/FooterComponent'
import ApplyFinance from './applyFinancePage/ApplyFinance'
import PreApplication from './applyFinancePage/PreApplication'
import PostMfc from './applyFinancePage/PostMfc'
import PQSuccess from './applyFinancePage/PQSuccess'
import PQDecline from './applyFinancePage/PQDecline'
import AfterSalesComponent from './afterSalesPages/AfterSalesComponent'
import SpecialDealsComponent from './specialDeals/SpecialDealsComponent'
import SpecialDealsComponentDetailed from './specialDeals/SpecialDealsComponentDetailed'
import CarProfileContainer from './carProfile/CarProfileContainer'
import OurVehicles from './ourVehiclesPage/OurVehicles'
import ContactUs from './contactUsPage/ContactUs';
import Service from './servicePage/Service';
import Parts from './partsPage/Parts';
import Terms from './legalPage/Terms';
import Privacy from './legalPage/Privacy';
import Cookies from './legalPage/Cookies';
import Popi from './legalPage/Popia';
import ScrollUpButton from "react-scroll-up-button";
import Complaints from "./legalPage/Complaints";
import WarrantyAndServicePlan from "./legalPage/Warranty-Service.js";
import ConflictsOfInterest from "./legalPage/Conflicts.js";
import OurDealers from './ourDealersPage/OurDealers';
import ServicePlans from '../components/applyFinancePage/ServicePlans';
import TradeIn from '../components/shared/TradeInContainer';
import OilChangeService from '../components/specialDeals/OilChangeService';
import BreakSpecial from '../components/specialDeals/BreakSpecial';
import MultiPointChecks from '../components/specialDeals/MultiPointCheck';
import UsedCarUsedVehiclesPage from "./usedCarUsedVehiclesPage/usedCarUsedVehiclesPage";
import Compare from "./comparePage/Compare";
import Favourite from "./favouritePage/favouritePage";
import Career from "./CareerPage/CareerPage";

export default function DashboardContainer() {
    return (
        <React.Fragment>
            <main>
                <NavbarComponent />
                <Route exact path="/" render={(props) => <HomeComponent />} />
                <Route exact path='/apply-for-finance' component={ApplyFinance} />
                <Route exact path='/apply-for-finance/done/:leadId' component={PreApplication} />
                <Route exact path='/pqComplete/leadId/:leadId/a/:a/i/:i' component={PostMfc} />
                <Route exact path='/pqSuccess/:leadId' component={PQSuccess} />
                <Route exact path='/:dealerId/0/buy/pqDecline/:leadId' component={PQDecline} />
                <Route path={['/maintenance', '/warranty', '/tomtom-live', '/service-plans', '/bodyshops', '/roadside-assistance', '/about-us', '/after-sales']} render={props => <AfterSalesComponent {...props} />} />
                <Route exact path="/special-deals" render={(props) => <SpecialDealsComponent />} />
                <Route exact path="/special-deals/:carId/:modelId/:dealId?" render={(props) => <SpecialDealsComponentDetailed />} />
                <Route exact path="/models/:carId/:modelId/:dealId?" component={CarProfileContainer} />
                <Route exact path="/stock/:stockId?" component={UsedCarUsedVehiclesPage} />
                <Route path='/our-vehicles' component={OurVehicles} />
                <Route path='/career' component={Career} />
                <Route path='/used-vehicles' exact component={UsedCarUsedVehiclesPage} />
                <Route path='/sell-vehicle' component={TradeIn} />
                <Route path='/contact-us' component={ContactUs} />
                <Route path='/compare' component={Compare} />
                <Route path='/favourite' component={Favourite} />
                <Route path='/service' component={Service} />
                <Route path='/parts' component={Parts} />
                <Route path='/our-dealers' component={OurDealers} />
                <Route path='/terms' component={Terms} />
                <Route path='/privacy' component={Privacy} />
                <Route path='/cookie' component={Cookies} />
                <Route path='/requestergws' component={Popi} />
                <Route path='/complaints' component={Complaints} />
                <Route path='/warranty-and-service-plan' component={WarrantyAndServicePlan} />
                <Route path='/conflicts-of-interest' component={ConflictsOfInterest} />
                <Route path='/services-plans' component={ServicePlans} />
                <Route path='/oil-change-service' component={OilChangeService} />
                <Route path='/break-special' component={BreakSpecial} />
                <Route path='/multi-point-check' component={MultiPointChecks} />
                <FooterComponent />
                <ScrollUpButton />
            </main>
        </React.Fragment>
    );  
}