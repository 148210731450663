import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { VIRTUAL_URI_APPLICATION } from "../shared/Constants";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ApplySteps from '../shared/ApplySteps';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    divider: {
        backgroundColor: '#ffce33',
        width: '33%',
        marginBottom: '30px',
        height: '3px',
        margin: '20px auto'
    },
    success: {
        color: 'green',
        justifyContent: 'center'
    },
    blue: {
        color: '#f5d600'
    },
    topHeader: {
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '2.5rem',
        marginBottom: 0
    },
    arrow: {
        width: 0,
        height: 0,
        borderLeft: '30px solid transparent',
        borderRight: '30px solid transparent',
        borderTop: '30px solid #f5d600',
        margin: '0 auto'
    },
    stepsIcon: {
        '& svg': {
            fontSize: '5rem',
            border: '2px solid #f5d600',
            borderRadius: '1rem',
            padding: '0.4rem'
        }
    },
    successText: {
        fontSize: '1.2rem'
    },
    ctaButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        '& > button:first-child': {
            '@media (max-width:780px)': {
                marginBottom: '1rem'
            }
        },
        '@media (max-width:780px)': {
            flexDirection: 'column'
        }
    },
    blackButton: {
        backgroundColor: '#000000',
        color: '#2e3975'
    }
}));

export default function PQDecline(props) {
    const classes = useStyles();

    const { leadId } = useParams();
    const history = useHistory();
    let { globalLead, globalVehicle } = useContext(FinanceContext);
    

    const stepsState = {
        one: {
            active: false,
            completed: true
        },
        two: {
            active: false,
            completed: true
        },
        three: {
            active: true,
            completed: false
        }
    }

    return (

        <Container maxWidth="lg">
            <div className={classes.content}>
               
                <Grid container alignItems="center" justify="center">
                    <Grid item sm={12} md={6}>
                        <Box mb={6} mt={4}>
                            <Typography align="center" className={classes.successText}>
                                Unfortunately you do not qualify to purchase this vehicle
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
