
const Dealers = [
    {
        dealer: 'BA Used Truck and Trailer',
        address: 'Cnr Atlas & North Rand Road, Boksburg, Gauteng',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-26.23355137802141,28.043010223726682&hl=es;z=14&amp;output=embed" height="244" width="100%"></iframe>
        )
    },
    {
        dealer: 'ELT IVECO',
        address: 'Cnr Atlas & North Rand Road, Boksburg, Gauteng',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-26.232608807409473,28.04276269571288&hl=es;z=14&amp;output=embed" height="244" width="100%"></iframe>
        )
    },
    {
        dealer: 'ELT FAW',
        address: 'Cnr. Wedge Park Road and William Nicol Drive, Bryanston, Gauteng',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-26.233620544255757,28.043013324990238&via=0&z=16&amp;output=embed" height="244" width="100%" frameBorder="none"></iframe>
        )
    },
    {
        dealer: 'ELT TATA',
        address: 'Cnr. Noon and Sunrise Boulevard, Fourways Crossing, Fourways, Gauteng',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-26.23256433883911,28.042059998797537&via=0&z=16&amp;output=embed" height="244" width="100%" frameBorder="none"></iframe>
        )
    },
    {
        dealer: 'ELT DAEWOO',
        address: 'Cnr. Herman & Kuschke Road, Meadowdale, Gauteng, South Africa',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-26.23362696989339,28.043014885061186&via=0&z=16&amp;output=embed" height="244" width="100%" frameBorder="none"></iframe>
        )
    }
]


export default Dealers;