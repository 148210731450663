import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image'

const useIntersectionObserver = ({
                                   target,
                                   onIntersect,
                                   threshold = 0.1,
                                   rootMargin = "0px"
                                 }) => {
  React.useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      rootMargin,
      threshold
    });
    const current = target.current;
    observer.observe(current);
    return () => {
      observer.unobserve(current);
    };
  });
};

const useStyles = makeStyles({
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: props => props.height ? props.height: 'initial',
    width: '100%',
    paddingTop:'0px'
  },
  image: {
    width: '100%',
    paddingTop:'0px',
    objectFit: 'contain',
  }
});

export const ImageContainer = props => {
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);
  const classes = useStyles(props);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    }
  });

  return (
    <div
      ref={ref}
      className={classes.imageContainer}>
      {isVisible && (
        <img className={classes.image} src={props.src} alt={props.alt}
          style={{...props.style}}
          imageStyle={{
            height: 'initial',
            bottom: 0,
            margin: 'auto',
            position:'relative',
          }}
          aspectRatio={props.aspectRatio ? props.aspectRatio : (16/9)} color='lightgrey'/>
      )}
    </div>
  );
};
