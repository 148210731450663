import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI, DEALERID,MASTER_DEALER_NAME,MASTER_DEALERID } from "./Constants"
import axios from "axios";
import Button from "@material-ui/core/Button";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Switch } from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from '@material-ui/core/Box';
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { DealerContext } from './contexts/DealerContext'

import { FinanceContext } from './contexts/FinanceContext'
import Dealers from '../ourDealersPage/Dealers';
import MenuItem from "@material-ui/core/MenuItem";
import { display } from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
    // textFields: {
    //     backgroundColor: 'white !important',
    //     // border: '1px solid #707070 !important',
    //     // borderRadius: '5px'
    // },
    content: {
        backgroundColor: 'transparent !important',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    textFieldRoot: {
        border: `1px solid #878787 !important`,
        borderWidth: "1px !important",
        borderRadius: '10px !important',
        backgroundColor: 'white !important',
        "& $notchedOutline": {
            borderColor: `#878787 !important`,
            borderRadius: '10px !important',
        },
        "&:hover fieldset": {
            borderColor: `#000 !important`,
            borderRadius: '10px !important',
            // backgroundColor: `white !important`,
        },
        "&$focused $notchedOutline": {
            borderColor: `#878787 !important`,
            borderRadius: '10px !important',
        },
    },
    focused: {},
    notchedOutline: {},
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingBelow: {
        marginRight: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingButton: {
        marginTop: "0.5rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    flex: {
        display: "flex",
        flexDirection: 'column',
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        minWidth: "100%",
        height: 550,
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    activebtn:{
        background:"#39679f",
        color:'white',
        fontWeight:'bold',
        width:"20%",
        height: '40px',
        borderRadius: '20px !important',
        '&:hover': {
            background:'#39679fe6',
        },
    },
    inputLabel: {
       color: `white !important`,
   },
   required: {
        color: '#f80609'
   }
}));

export default function ContactHomeContainer(props) {
    let bottomHomeScreen = props.bottomHomeScreen;
    const classes = useStyles(bottomHomeScreen);
    const { enqueueSnackbar } = useSnackbar();
    const { globalModel } = useContext(FinanceContext);
    const { globalDealer ,dealerList} = useContext(DealerContext);

    const [contactMe, setContactMe] = useState(false)
    const [loader, setLoader] = useState(false)
    const [popi, setPopi] = useState(false)
    const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;
    
    const initialFormValues = {
        name: '',
        phone: '',
        email: '',
        dealerId: globalDealer?.id !== DEALERID ? globalDealer?.id : '',
        dealer: globalDealer?.id !== DEALERID ? globalDealer?.name : ''
    }

    let isCashBuyer = props.isCashBuyer;
    let leadTypeId = props.leadTypeId;
    

    const validationSchema = Yup.object({
        name: Yup.string("Enter a name").required(
            "Name is required"
        ),
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        phone: Yup.string("Enter your phone number")
            .required("Phone number is required")
            .matches(phoneRegExp, "Phone number is not valid")
    });

    const checkValid = (validForm) => {
        return (validForm && contactMe && popi)
    }
    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
    function notEmpty(obj) {
        return Object.keys(obj).length > 0;
    }



    function createLead(values) {

        setLoader(true);

        let params = {}

        if (isEmpty(globalModel)  ) {
       
            leadTypeId = 3
        }
     
        if (globalModel.stockId) {
            params.stockId = globalModel.stockId;
            leadTypeId = 2
        }

        if (notEmpty(globalModel) && globalModel.stockId === undefined ) {
            params.ownedVariantId = globalModel.id;
            leadTypeId = 1
        }

        params.leadStatusId = 1
        params.dealerId = values.dealerId || globalDealer.id;
        params.name = values.name
        params.firstName = values.name;
        params.lastName = '';
        params.phoneNumber = values.phone
        params.emailAddress = values.email
        params.leadSourceId = 1
        params.leadSourceId = 1
        params.TypeCode = "NEW"
        params.notes = props.isCashBuyer ? "Cash buyer" : ""
        params.leadTypeId = leadTypeId

        let m = new Date()
        m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
        params.dateUpdated = new Date().toISOString();

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${BASE_URI}/leads`,
            data: params,
            cancelToken: source.token
        }).then((response) => {

            fbq('track', 'Lead');  // eslint-disable-line

         
         
                setLoader(false);
                enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
        

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to complete the request", { variant: 'error' });
            setLoader(false);
        })
    }

    const cleanName = (dealerName) => {
        return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }

    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, value.props.id)
    }
    let dealer = localStorage.getItem('globalDealer')
    let dealerParsed = JSON.parse(dealer)
    return (
        <React.Fragment>
            <MuiThemeProvider>
                <div style={{width: '100%'}}>
                <Grid container direction="row">
                    <Grid item md={6} lg={6}/>
                    <Grid item md={6} lg={6}>
                        <Card className={classes.content} elevation={0}>
                            <CardContent>
                                <Formik
                                    isInitialValid={false}
                                    initialValues={initialFormValues}
                                    enableReinitialize={true}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values, actions) => {
                                        actions.resetForm()
                                        createLead(values)
                                    }}
                                >
                                    {(props) => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            isValid,
                                            handleBlur,
                                            handleChange,
                                            setFieldTouched,
                                            setFieldValue
                                        } = props;
                                        return (
                                            <Form>
                                                <Grid container className={classes.root} direction="row" spacing={2}>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={6} spacing={2}
                                                    >
                                                        <text className={classes.inputLabel} >Name <span className={classes.required}>*</span></text>
                                                        <TextField
                                                            id="name"
                                                            name="name"
                                                            label=""
                                                            value={values.name}
                                                            fullWidth
                                                            required
                                                            variant="outlined"
                                                            helperText={touched.name ? errors.name : ""}
                                                            error={touched.name && Boolean(errors.name)}
                                                            onChange={handleChange}
                                                            className={classes.textFields}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    focused: classes.focused,
                                                                    notchedOutline: classes.notchedOutline,
                                                                }
                                                              }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={6}
                                                        lg={6}>
                                                        <text className={classes.inputLabel} >Email <span className={classes.required}>*</span></text>
                                                        <TextField
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            label=""
                                                            required
                                                            fullWidth
                                                            value={values.email}
                                                            variant="outlined"
                                                            helperText={touched.email ? errors.email : ""}
                                                            error={touched.email && Boolean(errors.email)}
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    focused: classes.focused,
                                                                    notchedOutline: classes.notchedOutline,
                                                                }
                                                              }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={6}>
                                                    <text className={classes.inputLabel} >Cellphone <span className={classes.required}>*</span></text>
                                                        <TextField
                                                            id="phone"
                                                            name="phone"
                                                            type="tel"
                                                            label=""
                                                            required
                                                            fullWidth
                                                            value={values.phone}
                                                            variant="outlined"
                                                            helperText={touched.phone ? errors.phone : ""}
                                                            error={touched.phone && Boolean(errors.phone)}
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    focused: classes.focused,
                                                                    notchedOutline: classes.notchedOutline,
                                                                }
                                                              }}
                                                        />
                                                    </Grid>
                                                 
                                                    <Grid item className={classes.stepPaddingButton}xs={12} md={6} lg={6}>
                                                    <text className={classes.inputLabel} >Dealership <span className={classes.required}>*</span></text>
                                                            <TextField
                                                             variant="outlined"
                                                             fullWidth
                                                             id="dealer"
                                                             select={globalDealer.id == MASTER_DEALERID}
                                                            readOnly={globalDealer.id != MASTER_DEALERID}
                                                            required
                                                             label=""
                                                             value={values.dealer}
                                                             helperText={errors.dealer && touched.dealer ? errors.dealer : ''}
                                                             error={errors.dealer && touched.dealer}
                                                             onBlur={handleBlur("dealer")}
                                                             onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'dealer', 'dealerId')}
                                                             InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    focused: classes.focused,
                                                                    notchedOutline: classes.notchedOutline,
                                                                }
                                                              }}
                                                         >
                                                                 {dealerList?.map((option) => (
                                                                 <MenuItem key={option.dealerId} id={option.dealerId} value={option.dealerId}>
                                                                     {option.name}
                                                                 </MenuItem>
                                                             ))}
                                                         </TextField>
                                                        </Grid>
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                                          <Box mb={2}>
                                                           <text className={classes.inputLabel} >Your Message</text>
                                                          <TextField
                                                              id="notes"
                                                              name="notes"
                                                              placeholder="leave a message"
                                                              type="text"
                                                              fullWidth
                                                              multiline
                                                              rows={4}
                                                              variant="outlined"
                                                              helperText={touched.notes ? errors.notes : ""}
                                                              error={touched.notes && Boolean(errors.notes)}
                                                              onChange={(event) => {
                                                                setFieldValue("notes", event.target.value)
                                                                setFieldTouched("notes", true, false);
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    focused: classes.focused,
                                                                    notchedOutline: classes.notchedOutline,
                                                                }
                                                              }}
                                                          />
                                                          </Box>
                                                      </Grid>
                                                      
                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                             <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="contactMe"
                                                                    onClick={() => {
                                                                        setContactMe(contactMe ? false : true)
                                                                    }}
                                                                />
                                                            }
                                                         
                                                        />
                                                          <span style={{textAlign: 'left', alignItems: 'center', color: 'white'}}>
                                                    I allow ELT to contact me
                                                        </span>
                                                        </div>
                                                    {/* </Grid>
                                                
                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}> */}
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="popi"
                                                                 
                                                                    onClick={() => {
                                                                        setPopi(popi ? false : true)
                                                                    }}
                                                                />
                                                            }
                                                 
                                                        />
                                                         <span style={{textAlign: 'left', alignItems: 'center', color: 'white'}}>
                                                       By submitting this form I agree to the <Link component={RouterLink} to="/terms" className={classes.required}>terms and conditions</Link> and <Link component={RouterLink} to="/privacy" className={classes.required}>privacy policies.</Link>   
                                                        </span>
                                                        </div>
                                                    </Grid>
                                                    <Grid container direction="row">
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12} style={{display: 'flex', justifyContent: 'flex-end'}}>

                                                            {!loader &&
                                                                <Button 
                                                                    variant={"contained"}
                                                                    color="primary"
                                                                    className={classes.activebtn}
                                                                    disabled={!checkValid(isValid)}
                                                                    type="submit"
                                                                >
                                                                    {isCashBuyer ? 'Order Now' : 'SUBMIT'}
                                                        </Button>
                                                            }
                                                            {loader == true && (
                                                                <Button 
                                                                    className={classes.button} 
                                                                    variant={"contained"}
                                                                    color="primary"
                                                                    disabled={true}
                                                                >
                                                                    <CircularProgress />
                                                                </Button>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                </div>
            </MuiThemeProvider>
        </React.Fragment>
    );
}