import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PaymentDetails from '../shared/PaymentDetails'
import { ImageContainer } from '../shared/ImageContainer'
import Carousel from '../homePage/CarouselSingle'
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BASE_URI } from '../shared/Constants';
import Container from '@material-ui/core/Container';
import { CurrencyValue } from '../shared/TextMask';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import { Formik, Form } from "formik";
import VehicleController from "../usedVehicles/VehicleController";
import ComingSoon from "../shared/assets/comingsoon.jpg";
import { DealerContext } from '../shared/contexts/DealerContext'
import PlaceIcon from '@material-ui/icons/PhoneOutlined';
import {CompareContext} from '../shared/contexts/CompareContext';
import {FavouriteContext} from '../shared/contexts/FavouriteContext';
import Finance from '../shared/FinanceCalulator';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(0, 0, 6),
    },
    largeButton: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        '& h6': {
            fontSize: '28.8px'
        }
    },
    buttonIconLg: {
        fontSize: '5rem',
        color: '#EFDF00'
    },
    sectionPadding: {
        margin: '3rem 0'
    },
    paragraphPadding: {
        padding: '1.5rem 3rem !important'
    },
    heading: {
        borderBottom: '5px solid #EFDF00',
        textTransform: 'capitalize',
        paddingBottom: '.5rem',
        fontWeight: '700',
        display: 'inline-block'
    },
    blueBorder: {
        borderBottom: '5px solid #2e3975',
    },
    secondaryHeading: {
        textTransform: 'uppercase',
        paddingBottom: '1rem',
        fontWeight: '400'
    },
    slideMargin: {
        width: 'calc(100% - 20px)',
        height: 'calc(100%)',
        left: '10px',
        top: '10px',
    },
    topHeader: {
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '2.5rem'
    },
    blue: {
        color: '#2e3975'
    },
    specs: {
        '& .specs': {
            borderBottom: '0 !important',
            marginTop: '-25px'
        },
    },
    formControl: {
        minWidth: 400,
        '@media (max-width: 450px)': {
            minWidth: '100%'
        }
    },
    headerLine: {
        height: '2px',
        width: '100%',
        backgroundColor: '#EFDF00',
        padding: '0 !important'
    },
    smallHeading: {
        fontSize: '1.3rem',
    },
    lighter: {
        fontWeight: 'lighter',
        fontSize: '1.0rem',
        padding: '0 !important'
    },
    stepPaddingColours: {
        marginLeft: "1em",
        marginRight: "1em",
        marginTop: "0.5em",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    colorsContainer: {
        display: 'flex',
        '& > div:first-child': {
            marginLeft: 0
        }
    },
    colourName: {
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        marginTop: '0.6rem'
    },
    colourPrice: {
        fontWeight: 700
    },
    stepOneButtons: {
        display: 'flex',
        '@media (max-width: 500px)': {
            flexDirection: 'column',
            alignItems: 'center',
            '& > a': {
                marginLeft: '0 !important',
                marginRight: '0 !important'
            }
        }
    },
    topDetailSection: {
        '@media screen and (max-width: 959px)': {
            display: 'flex',
            flexDirection: 'column',
            '& > div:first-child': {
                order: 2,
                marginTop: '2rem'
            },
            '& > div:nth-child(2)': {
                order: 3
            },
            '& > div:nth-child(3)': {
                order: 4
            },
            '& > div:nth-child(4)': {
                marginTop: '-1.5rem'
            }
        }
    },
    darkgreyleft:{
        fontWeight:'bold',
        borderBottom: '#cccccc  1px solid',
    },
    darkgreyright:{
        textAlign:'right',
        borderBottom: '#cccccc  1px solid',
    },
    flex: {
        display: 'flex',
        flexDirection:'row!important',
        justifyContent:'space-between',
    },
    btn: {
   
        alignItems: 'start',
        marginTop:'2%',
        width:'45%',
        whiteSpace:'nowrap!important',
    },
    btn1: {
     
        alignItems: 'start',
        marginTop:'2%',
        width:'45%',
        background:'#2e3975',
        border:'1px solid black',
        whiteSpace:'nowrap!important',
        color:'white',
        '&:hover': {
            background:'#2e3975!important',
            color:'white',
        },
    },
    btn2: {
        whiteSpace:'nowrap!important',
        alignItems: 'start',
        marginTop:'2%',
        width:'100%',
    },
    btnBlack: {
        color:'white',
        background:'black',
        alignItems: 'start',
        marginTop:'2%',
        width:'80%',
        margin:'0 auto',
        '&:hover': {
            background:'#2e3975!important',
            color:'black',
        },
    },
    center:{
        textAlign:'center'
    },
    flexHolder:{
        display:'flex',
        background:'#f2f2f2',
        color:'black'
      },
      calcHolder:{
        position:'fixed',
        top: '0',
        left:'0',
        background: '#9095a0a8',
        zIndex:'20',
        width:"100%",
        height:"100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    financeHolder:{
        display:'flex',
        alignItems:'center',
        margin:"1rem 0px"
    },
    financeHolderText:{
        marginLeft:"5px",
        fontWeight:'bolder'
    }
}));

export default function CarProfileContainer({searchLoading,activeVehicle,onFilterData}) {
    const classes = useStyles();
    let { stockId } = useParams();
    const history = useHistory();

    let vehicleProfile;
    const {setCompareVal} = useContext(CompareContext)
    const {compareVal} = useContext(CompareContext)
  
    const {setFavouriteVal} = useContext(FavouriteContext)
    const {favouriteVal} = useContext(FavouriteContext)
    const [carProfile, setCarProfile] = useState(vehicleProfile);
    const [compLoading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [financeOptions, setFinanceOptions] = useState([])
    const [financeDetails, setFinanceDetails] = useState({})
    const [modelData, setModelData] = React.useState([]);
    const [open, setOpenCalc] = useState(false);
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState();

    let { setGlobalFinance, setGlobalVehicle, setGlobalColor, setGlobalVariantId, setGlobalModel, globalColor } = useContext(FinanceContext);
    const { loading, error, vehicles, total } = VehicleController();
    const [makes, setMake] = useState([]);
    const [dealer, setDealer] = useState();
    const [dealerAddress, setDealerAddress] = useState("");
    const [dealerContact, setDealerContact] = useState("");
    const [priceRages, setPriceRanges] = useState([]);
    const [activeMake, setActiveMake] = useState(null);
    const [activeModel, setActiveModel] = useState(null);
    const [activeProvince, setActiveProvince] = useState(null);
    const [activePriceRange, setActivePriceRange] = useState(null);
    const [activeYear, setActiveYear] = useState(null);
    const [activeTransmission, setActiveTransmission] = useState(null);
    const [activeNewUsed, setActiveNewUsed] = useState(null);
    const [activeActiveBodyType, setActiveBodyType] = useState(null);
  const [showFilteredData, setShowFilteredData] = useState(false);
    const values =
    {
        model: "",
        modelId: "",
    };

    useEffect(() => {
        if (vehicles.length > 0) {
          setMake(Array.from(new Set(vehicles.map((m) => m.brand))));
          const prices = [...new Set(vehicles.map((m) => m.price))];
          CalcPriceRanges(prices, true);
        }
      }, [vehicles]);

      const CalcPriceRanges = (list, update) => {
        const min = Math.min.apply(Math, list);
        const max = Math.max.apply(Math, list);
        let step = 0;
        var ranges = [];
    
        while (true) {
          if (min > step) {
            if (step < 100000) {
              step += 100000;
            } else {
              step += 100000;
            }
          } else {
            if (step >= 100000) {
              ranges.push({ min: step - 100000, max: step });
            } else {
              if (step === 0) {
                step += 100000;
                ranges.push({ min: 0, max: step });
              } else {
                ranges.push({ min: step - 100000, max: step });
              }
            }
            break;
          }
        }
        while (true) {
          if (step < max) {
            step += 100000;
            ranges.push({ min: step - 100000, max: step });
          } else {
            break;
          }
        }
    
        return ranges;
      };
      const GetList = (
        make,
        model,
        priceRange,
        province,
        year,
        transmission,
        newUsed,
        body
      ) => {
        return vehicles
          .filter((v) => {
            return make ? v.brand.toUpperCase() === make.toUpperCase() : v;
          })
          .filter((v) => {
            return model ? v.model.toUpperCase() === model.toUpperCase() : v;
          })
          .filter((v) => {
            return priceRange
              ? v.price >= priceRange.min && v.price < priceRange.max
              : v;
          })
          .filter((v) => {
            return province
              ? v.province.toUpperCase() === province.toUpperCase()
              : v;
          })
          .filter((v) => {
            return year ? v.year === year : v;
          })
          .filter((v) => {
            return transmission
              ? v.transmission.toUpperCase() === transmission.toUpperCase()
              : v;
          })
          .filter((v) => {
            return newUsed ? v.newUsed.toUpperCase() === newUsed.toUpperCase() : v;
          })
          .filter((v) => {
            return body ? v.bodyType.toUpperCase() === body.toUpperCase() : v;
          });
      };
      useEffect(() => {
        const filteredData = vehicles
          .filter((v) => {
            return activeMake
              ? v.brand.toUpperCase() === activeMake.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activeModel
              ? v.model.toUpperCase() === activeModel.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activePriceRange
              ? v.price >= activePriceRange.min && v.price <= activePriceRange.max
              : v;
          })
          .filter((v) => {
            return activeProvince
              ? v.province.toUpperCase() === activeProvince.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activeYear ? v.year === activeYear : v;
          })
          .filter((v) => {
            return activeTransmission
              ? v.transmission.toUpperCase() === activeTransmission.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activeNewUsed
              ? v.newUsed.toUpperCase() === activeNewUsed.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activeActiveBodyType
              ? v.bodyType.toUpperCase() === activeActiveBodyType.toUpperCase()
              : v;
          });
        if (filteredData?.length > 0) {
          const prices = [...new Set(filteredData.map((m) => m.price))];
          setPriceRanges(CalcPriceRanges(prices));
        }
      }, [
        activeMake,
        activeModel,
        activeProvince,
        activePriceRange,
        activeYear,
        activeTransmission,
        activeNewUsed,
        activeActiveBodyType,
        vehicles,
      ]);
 
    const calculateMonthly = (data) => {
        var deposit = data.purchasePrice * (data.deposit / 100);
        var totalPrice = data.purchasePrice;
        var initFee = 0;
        var principal = totalPrice - deposit + initFee;

        var balloonPerc = data.balloonPayment;

        var balloonAmt = totalPrice * balloonPerc / 100;

        var interestRate = data.linkedInterestRate;
        var interestPM = interestRate / 100 / 12;

        var repaymentPeriod = data.term;
        var days = 1;

        var v = 1 / (1 + interestPM);
        var d = 1 - v;
        var y = Math.pow(v, repaymentPeriod - 1);
        var comp = (1 - y) / d;
        var fp = principal * (Math.pow(1 + interestPM, (days / (365 / 12).toFixed())));
        var monthly = 0;

        if (parseInt(balloonAmt) > 0) {
            var comp = (1 - (Math.pow(v, repaymentPeriod - 1))) / d;
            monthly = (fp - (balloonAmt * Math.pow(v, repaymentPeriod - 1))) / comp;
        }
        else {
            var comp = (1 - (Math.pow(v, repaymentPeriod))) / d;
            monthly = fp / comp;
        }

        return round(monthly) + initFee;
    }

    function round(x) {
        return Math.round(x * 100) / 100;
    }

    useEffect(() => {

        const CancelToken = axios.CancelToken;
        setLoading(true)
        const source = CancelToken.source();

        axios.get(`${BASE_URI}/stock/${activeVehicle?.stockId}`, {
            cancelToken: source.token
        }).then(result => {

            let data = result.data
           
            setModelData(data);
            setGlobalModel(data);

            var tmpFinanceDetails = {
                monthlyInstallment: 0,
                term: 72,
                linkedInterestRate: 7,
                deposit: 0,
                balloonPayment: 0,
                originalPrice: data.price,
                purchasePrice: data.price
            };

            let monthly = calculateMonthly(tmpFinanceDetails);

            tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

            setFinanceDetails(tmpFinanceDetails);
            setGlobalFinance(tmpFinanceDetails);


            setSelectedImage(data.mainUrls.length > 0 && data.mainUrls[0] ? data.mainUrls[0] : ComingSoon);
            setImages(data.mainUrls.map(itm => { return { img: itm }; }));

            setLoading(false)
        });

    }, [activeVehicle]);

    const padZero = (str, len) => {
        len = len || 2;
        let zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    const invertColor = (hex) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + padZero(r) + padZero(g) + padZero(b);
    }

    const stepsState = {
        one: {
            active: true,
            completed: false
        },
        two: {
            active: true,
            completed: false
        },
        three: {
            active: true,
            completed: false
        }
    }

    const cleanName = (dealerName) => {
        return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }

    const dealerLookUp = (dealerID)=>{
        axios.get(`${BASE_URI}/Dealers/DealerName/${dealerID}`, {
        }).then(result => {
            setDealer(result.data)
        
        }
        )
        
    }
 
    const dealerInfo = (dealerID)=>{
        axios.get(`${BASE_URI}/Dealers/DealerContactDetails/${dealerID}`,{
        }).then(result =>{
       
            setDealerAddress(result.data.address.streetAddress)
            setDealerContact(result.data.usedCarsPhoneNumber)
        })
        
    }
    const Next = () =>{
    
            window.location.href = `/dealer/${cleanName(dealer)}/used-vehicles` 
      
        }

        const { globalDealer } = useContext(DealerContext)

        let openCal = () =>{
            if (open === false) {
                setOpenCalc(true)
                console.log(open)
            } else {
                setOpenCalc(false)
                console.log(open)
            }
        }

   useEffect(() => {


    dealerInfo()
    dealerLookUp()
  }, [cleanName])

    return (
        <React.Fragment>
            <main>
                
            {open &&  
        <div className={classes.calcHolder}>
           <Finance openCalc={setOpenCalc}/>
        </div>}
                <Container maxWidth="lg">
                    {
                        compLoading &&
                        <Grid item container justifyContent='center' justify='center' alignContent='center' xs={12}>
                            <CircularProgress size={80} />
                        </Grid>
                    }
                    {
                        !compLoading &&
                        <div className={classes.background}>
                         
                            <Grid container md={12} className={classes.flex}>
                            <Grid md={2} xs={12}>
                                
                {(loading) && (
                <div className={classes.filterLoadingContainer} />
              )}
             </Grid>
                            <Grid container spacing={2} md={12} style={{ marginBottom: '1rem' }}>
                                <Grid item xs={12} md={8}>
                                        <Box >
                                            <ImageContainer aspectRatio={(16 / 6)} src={selectedImage} alt='MainImage' />
                                        </Box>
                                        {images.length > 1 &&
                                            <Box style={{ marginBottom: '1rem' }}>
                                                {

                                                    <Carousel naturalSlideWidth={100}
                                                        naturalSlideHeight={60}
                                                        totalSlides={images.length}
                                                        visibleSlides={4}
                                                        isPlaying={true}
                                                        infinite
                                                        innerClass={classes.slideMargin}
                                                        slides={images}
                                                        onClick={(slide) => {
                                                            setSelectedImage(slide.img);
                                                        }}
                                                    />
                                                }
                                            </Box>
                                        }
                                    <Box className={classes.specs} mt={5}>
                                        <Typography variant="h4" align="left" > Factory Features on this Vehicle</Typography>
                                        {modelData.features}
                                    </Box>
                                  
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Formik
                                        initialValues={values}
                                        isInitialValid={false}
                                        onSubmit={(values, actions) => {
                                            setTimeout(() => {

                                                actions.setSubmitting(true);
                                            }, 100);
                                        }}
                                    >
                                        {(props) => {
                                            const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

                                            return (
                                                <Form>
                                                   <Box ml={1}>
                                                   <Box mb={1} mt={1}>
                                                   <Typography className={classes.smallHeading}>
                                                    {modelData.brand}  {modelData.model} 
                                                    </Typography>
                                                    </Box>
                                                    <div className={classes.headerLine}></div>
                                                    <Box mb={1} mt={1}>
                                                    <Typography variant="h4" color="textPrimary">
                                                        <strong>
                                                            <CurrencyValue value={financeDetails.purchasePrice} />
                                                        </strong>
                                                    </Typography>
                                                
                                                    <Typography variant="p" color="textPrimary">
                                                            
                                                        <span className={classes.lighter}>
                                                        From <CurrencyValue value={financeDetails.monthlyInstallment}/> pm
                                                        </span>
                                                      
                                                    </Typography>
                                                    <div variant="p" color="textPrimary" onClick={(e)=>{openCal()}} className={classes.financeHolder}>
                                                    <svg width="5%" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calculator" class="svg-inline--fa fa-calculator fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zM128 435.2c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8V268.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v166.4zm0-256c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8V76.8C64 70.4 70.4 64 76.8 64h294.4c6.4 0 12.8 6.4 12.8 12.8v102.4z"></path></svg>
                                                            <span className={classes.financeHolderText}>Finance Calculator</span>
                                                    </div>
                                                    </Box>
                                                    <Box mb={1} mt={1}>
                                                    <Box style={{ marginTop: 24 }}>
                                                        <table border="0" width="100%" cellspacing="0px" cellpadding="15px">
                                                                <tbody>
                                                             
                                                                    <tr >
                                                                        <td className={classes.darkgreyleft}>
                                                                         Year
                                                                        </td>
                                                                        <td className={classes.darkgreyright}>
                                                                        {modelData.year}
                                                                        </td>
                                                                     </tr>
                                                                     
                                                                     <tr >
                                                                         <td className={classes.darkgreyleft}>
                                                                             Mileage
                                                                         </td>
                                                                         <td className={classes.darkgreyright}>
                                                                        {modelData.mileage} km
                                                                         </td>
                                                                      </tr>
                                                                   
                                                                      <tr>
                                                                         <td className={classes.darkgreyleft}>
                                                                             Fuel type
                                                                         </td>
                                                                         <td className={classes.darkgreyright}> 
                                                                            {
                                                                                modelData?.fuelType &&
                                                                                modelData.fuelType === "P" ? <span>Petrol</span> :
                                                                                modelData.fuelType === "D" ? <span>Diesel</span> :
                                                                                <span>{modelData.fuelType}</span>
                                                                            }
                                                                         </td>
                                                                      </tr>
                                                                      <tr>
                                                                         <td className={classes.darkgreyleft}>
                                                                         Transmission
                                                                         </td>
                                                                         <td className={classes.darkgreyright}>
                                                                            {
                                                                                modelData?.transmission &&
                                                                                modelData.transmission === "M" ? <span>Manual</span> :
                                                                                modelData.transmission === "A" ? <span>Automatic</span> :
                                                                                <span>{modelData.transmission}</span>
                                                                            }
                                                                         </td>
                                                                      </tr>
                                                                      <tr>
                                                                         <td className={classes.darkgreyleft}>
                                                                             Colour
                                                                         </td>
                                                                         <td className={classes.darkgreyright}> 
                                                                        {modelData.colour}
                                                                         </td>
                                                                      </tr>
                                                                      <tr>
                                                                         <td className={classes.darkgreyleft}>
                                                                             Stock No #
                                                                         </td>
                                                                         <td className={classes.darkgreyright}> 
                                                                       {modelData.stockNo}
                                                                         </td>
                                                                      </tr>
                                                                </tbody>
                                                            </table>
                                                        </Box>
                                                   
                                                        <Box mb={3} mt={3}  className={`${classes.stepOneButtons} ${classes.flex}`} >
                                                        <Button onLoad={[dealerLookUp(modelData.dealerId), dealerInfo(modelData.dealerId)]}  className={classes.btnBlack} onClick={()=>{Next()}}>
                                                                {dealer}
                                                        </Button>
                                                        </Box>
                                                        <Box mb={3} mt={3}  className={` ${classes.center}`} >
                                                
                                                                {dealerAddress}<br/>

                                                                <span style={{display:'flex',justifyContent: 'center',alignItems: 'center',}}><PlaceIcon className={classes.footerIconSectionIcon} style={{ fontSize: 25 }} />{dealerContact}</span>
                                                           
                                                        </Box>
                                                    </Box>
                                                
                                                 
                                                   </Box>
                                                   <Box mb={0} mt={3}  className={`${classes.stepOneButtons} ${classes.flex}`} style={{ width: 'initial', display: 'flex' }}>
                                                    
                                                    <Button variant="contained" gutterBottom onClick={() => { history.push(`/contact-us`) }} color="primary"  className={classes.btn}>
                                                        Request Call back
                                                    </Button>

                                                    <Button variant="contained" gutterBottom onClick={() => { history.push(`/apply-for-finance`) }}  className={classes.btn1}>
                                                        Pre-Qualify Now
                                                    </Button>
                                             
                                              
                                                  
                                              
                                            </Box>
                                                    <PaymentDetails displayPrice={false} details={financeDetails ? financeDetails : carProfile.dealDetails} />
                                                
                                                 
                                                    <Box mb={3} mt={1}  className={`${classes.stepOneButtons} ${classes.flex}`} style={{ width: 'initial', display: 'flex' }}>
                      
                        <Button
                                variant="contained"
                                gutterBottom
                                className={classes.btn}
                                color="primary"
                                    
                                        onClick={(()=>{
                                        setFavouriteVal([...favouriteVal,modelData])
                                        })}
                            >
                                favourites &nbsp;
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAABgUlEQVQ4ja2UPU9UURCGn7mhFwqjMYAJQQMkQENFlo6EzpBIQ4j+Gz5+jCTQW0pHuSxEbQBBoBIqKx8LZ8nNcnbXDUwzufO+M2feM3MPPLFFL1CtgBlgPENnQCsi/gx0ijqi7qg/fWiX6rY68l8dqkvALvAcaAH7wNeE3wKrwDRwA7yPiC+9OltSf6u/1HW1dGCoG+ptchu9ZN5ksdleV5L8uSx6pQ6XCDt5R+sFbEVdKcQ/ZM5WJ1DlAI66yGyqzUI81GP1op1XJTYDvAT2IsJ+ctuW3D3gVda4Lzia/nvt9GX1UD0EJoCJ9re6XKv7Lf1YveCT2VD6H+kn20BEfAYWsttmxhYKNd6kP7+P5FAu1daAQ6k6h1IHt3MFNgqJ3dbmY+ZsPug7F/s6l3WuIK2TP6/e5bo960Zq5O90m0tbkl9lZ3fJXazjpYQG8Al4AZzw73E4SXgaeAdMAVfAWkQc9FODOqxu5WV32oW62U1mvwc2sqvXGToFjgf5mx5tfwFwgYcVCmvoMAAAAABJRU5ErkJggg=="/>
                            </Button>
                          
                    {compareVal.length <= 2 &&
                          <Button
                            variant="contained"
                            gutterBottom
                            className={classes.btn}
                            color="primary"
                            onClick={(()=>{
                              setCompareVal([...compareVal,modelData])
                            })}
                          >
                           compares  &nbsp; <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAABgUlEQVQ4ja2UPU9UURCGn7mhFwqjMYAJQQMkQENFlo6EzpBIQ4j+Gz5+jCTQW0pHuSxEbQBBoBIqKx8LZ8nNcnbXDUwzufO+M2feM3MPPLFFL1CtgBlgPENnQCsi/gx0ijqi7qg/fWiX6rY68l8dqkvALvAcaAH7wNeE3wKrwDRwA7yPiC+9OltSf6u/1HW1dGCoG+ptchu9ZN5ksdleV5L8uSx6pQ6XCDt5R+sFbEVdKcQ/ZM5WJ1DlAI66yGyqzUI81GP1op1XJTYDvAT2IsJ+ctuW3D3gVda4Lzia/nvt9GX1UD0EJoCJ9re6XKv7Lf1YveCT2VD6H+kn20BEfAYWsttmxhYKNd6kP7+P5FAu1daAQ6k6h1IHt3MFNgqJ3dbmY+ZsPug7F/s6l3WuIK2TP6/e5bo960Zq5O90m0tbkl9lZ3fJXazjpYQG8Al4AZzw73E4SXgaeAdMAVfAWkQc9FODOqxu5WV32oW62U1mvwc2sqvXGToFjgf5mx5tfwFwgYcVCmvoMAAAAABJRU5ErkJggg=="/>
                          </Button>
                            }
                         
                          {compareVal.length > 2 &&
                          <Button
                            variant="contained"
                            gutterBottom
                            className={classes.btn}
                            color="primary"
                                   
                          >
                             compares
                          </Button>
              }
           
                        </Box>
                                                    
                                                       
                                                    <Box>
                                                        <Typography style={{ marginTop: '.5rem' }} variant="caption" color="textSecondary">
                                                            {financeDetails.termsAndConditions}
                                                        </Typography>
                                                    </Box>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </Grid>
                            </Grid>
                            </Grid>
                        </div>
                    }
                </Container>
            </main>
        </React.Fragment>
    );
}