import React, { useState, useEffect, useContext } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ImageContainer } from '../shared/ImageContainer'
import { CurrencyValue } from '../shared/TextMask'
import Button from '@material-ui/core/Button';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import VehicleController from './VehicleController';
import { DealerContext } from '../shared/contexts/DealerContext'
import { MakeContext } from '../shared/contexts/MakeContext'
import BrandsHero from '../shared/assets/BrandsHero.png'
import DaewooLogo from '../shared/assets/Daewoo-back.png'
import DeawooTruck from '../shared/assets/daewoo-front.png'
import FAWLogo from '../shared/assets/faw-back.png'
import FAWTruck from '../shared/assets/FAW-front.png'
import IvecoTruck from '../shared/assets/IVECO-front.png'
import IvecoLogo from '../shared/assets/Iveco-backgroud.png'
import TataLogo from '../shared/assets/tata-background.png'
import TataTruck from '../shared/assets/Tata-front.png'
import { useMediaQuery as mq } from 'react-responsive'

const brands = [
        {
            logo: DaewooLogo,
            truck: DeawooTruck
        },
        {
            logo: FAWLogo,
            truck: FAWTruck
        },
        {
            logo: IvecoLogo,
            truck: IvecoTruck
        },
        {
            logo: TataLogo,
            truck: TataTruck
        },
    ]

const useStyles = makeStyles((theme) => ({
    backgroundImage: {
        // backgroundImage:
        // `linear-gradient(rgba(121, 121, 121, 0), rgba(121, 121, 121, 0)), 
        // url(${BrandsHero})`,
        // width: '100vw',
        // height: '80vh',
        // display: 'flex',
        // backgroundSize: 'cover',
        // position: 'relative'
    },
    content: {
        padding:"20px !important", 
        margin: "0px !important",
        paddingBottom: '80px !important',
        width: '100vw !important', 
        background: 'linear-gradient(0deg, rgba(174,174,174,1) 0%, rgba(207,207,207,1) 40%, rgba(223,223,223,1) 100%) !important',
    },
    testParam: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    },
    dividerTitle: {
        backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
        width: '33%',
        marginBottom: '30px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    title: {
        fontSize: '1.5rem',
        '&>span': {
            fontWeight: 400
        }
    },
    vehicleContainer: {
        '& h5, & p': {
            color: '#2e3975',
        },
        '&:hover img, &:hover p, &:hover h5': {
            color: '#1f2532',
            cursor: 'pointer',
            textDecoration: 'none'
        }
    },
    btn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:"20px"
    },
    image: {
        margin: '0 auto',
        display: 'flex',
        flex: 1,
        '& > div > div': {
            backgroundColor: '#ffffff !important'
        }
    },
    truckImage: {
        alignSelf: 'center',
        marginTop: '25px',
        width: '300px',
        height: 'auto',
        '& > div > div': {
            backgroundColor: '#ffffff !important'
        }
    },
    Carimage:{
        backgroundSize:'contain', 
        backgroundRepeat: "no-repeat", 
        backgroundPositionY: 'center',
        backgroundPositionX: 'center',
        backgroundOrigin: 'border-box',
        maxWidth:"600px",
        display:'block',
        margin:'0 auto',
        cursor:'pointer',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid #707070',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        '&:hover': {
            backgroundColor: '#2e397580 !important',
            borderColor: '#2e3975',
            boxShadow: 'none',
        },
    },
    cardText: {
        backgroundColor: '#2e3975', 
        color: 'white', 
        padding: '0px', 
        height: '80px',  
        borderBottomLeftRadius: '10px', 
        borderBottomRightRadius: '10px',
        justifyContent: 'center', 
        alignItems: 'center', 
        display: 'flex', 
        fontSize: '25px',
    },
    card: {
        textAlign: "center",
        fontWeight: 'bold',
        cursor:'pointer',
     
    },
    card2: {
        // maxWidth:'calc(100% / 3 - 80px)',
        // width: 'calc(100% / 3 - 80px)',
        margin:'0px 40px',
        // '@media (min-width: 0px) and (max-width: 768px)': {
        //     maxWidth:'calc(100% / 1 - 80px)',
        //     width: 'calc(100% / 1 - 80px)',
        // },
        // '@media (min-width: 769px) and (max-width: 1440px)': {
        //     maxWidth:'calc(100% / 2 - 80px)',
        //     width: 'calc(100% / 2 - 80px)',
        // },
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    },
    card2Holder:{
        flex: 1,
        display:'flex',
        flexWrap:'wrap',
        justifyContent: 'center',
    }
}));

const OurVehicles = () => {
    const { make, setActiveGlobalMake, makeColors } = useContext(MakeContext)
    let classes = useStyles({ ...makeColors });
  
    const { vehicles } = VehicleController()
    const [groupedMakes, setGroupedMakes] = useState()
    const [makeLogo, setMakeLogo] = useState()
    const [models, setModels] = useState([])
    const { globalDealer } = useContext(DealerContext)
    const isMobile = mq({ query: '(max-width: 425px)' })

    useEffect(() => {
        let filteredVehicles = vehicles.filter(itm => findMake(itm.make));
        let makes = groupBy(filteredVehicles, 'make');
        setGroupedMakes(makes);
    }, [vehicles, globalDealer.id])

    const handleMakeClick = (make, makeName) => {
        setActiveGlobalMake(make);
    }


    const findMake = (make) => {

        if (!globalDealer.makes) {
            return;
        }

        let foundMakes = globalDealer.makes.filter(itm => itm.name.toLowerCase() == make.toLowerCase());

        return foundMakes.length > 0;
    }

    const cleanModel = (model) => {

        return model.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    return (
        <>
        <div className={classes.backgroundImage}/>
        <Container maxWidth="false" className={classes.content}>
        
            <div >
           
                <Box mb={4}>
                    <Typography gutterBottom component="h1" variant={isMobile ? "h2" : "h1"} align="center" color="#2e3975" style={{ color: '#2e3975', fontWeight: 'bold', padding: '0rem 0rem',paddingTop: '4rem' }}>
                        OUR BRANDS
                    </Typography>
                </Box>
                <Grid container spacing={2} style={{display:'flex',justifyContent:'center'}}>
                    {!make && <>

                        {groupedMakes && Object.keys(groupedMakes).sort().map((makeName, index) => {

                            let models = groupedMakes[makeName];
                            let tmpMake = globalDealer?.makes?.find((m) => {
                                return m?.name?.toLowerCase() === makeName?.toLowerCase()
                            })

                            return (
                                <>
                                    <Grid item xs={12} sm={6} md={6} lg={3} key={index} className={classes.card} onClick={() => { handleMakeClick(tmpMake, makeName) }}>
                                       {tmpMake.heroImage ? 
                                       <Box style={{ backgroundImage:`linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${brands[index].logo})`}} className={classes.Carimage}> 
                                            <img alt={`${makeName.toUpperCase()} MODELS`} className={classes.truckImage} src={brands[index].truck} width="100%"/> 
                                            <text className={classes.cardText}>
                                            {models.length} {makeName.toUpperCase()} MODELS
                                        </text>
                                       </Box>
                                       : 
                                       <Box style={{backgroundImage:`url(${brands[index].truck})`,backgroundSize:'100% ',backgroundRepeat:'no-repeat',backgroundPosition: 'center',}} className={classes.Carimage}/>
                                       }

                                    </Grid>
                                </>)
                        })}
                    </>
                    }

                    {make && <>
                        <Grid item xs={12} sm={6} md={12}>
                       
                            <Typography align="center" classes={{ root: classes.bold }}>
                                {"DISCOVER " + make.name}
                            </Typography>

                        </Grid>

                        <div className={classes.card2Holder}>
                        {groupedMakes && groupedMakes[make.name] && groupedMakes[make.name]
                        // order by tonnage. 3 Ton, 8 Ton, etc. fall back to alphabetical sorting if no ton string.
                        .sort((a, b) => {
                            // look for i.e 4 Ton or 10 Ton
                            const regexp = /([0-9.]{1,4})\s([Tt]on)/g;
                            const aTonnage = [...a?.title.matchAll(regexp)];
                            const bTonnage = [...b?.title.matchAll(regexp)];
                            // prefer an item with tonnage first.
                            if (aTonnage.length === 0) return 1;
                            if (bTonnage.length === 0) return -1;
                            // sort when both items have tonnage.
                            return parseFloat(aTonnage[0][1]) - parseFloat(bTonnage[0][1]);
                        })
                        .map((vehicle, index) => {
                            return (
                                <div  key={index} className={classes.card2}>
                                    <Link underline="none" component={RouterLink} to={`models/${cleanModel(vehicle.model)}/${vehicle.id}?title=${vehicle.title}`}>
                                        <Box className={classes.vehicleContainer}>
                                            <Box className={classes.image}>
                                                <ImageContainer aspectRatio={(16 / 8)} src={vehicle.image} alt={vehicle.title} height="100%" style={{ minWidth: 300, height: 300 }} />
                                            </Box>
                                            <Box></Box>
                                            <Box mt={2} mb={0} textAlign="center" component="h5" className={`${classes.title} ${classes.bold}`}>{vehicle.title}</Box>
                                            <Box mb={3} className={classes.btn}>
                                                <Button className={classes.testParam} variant="contained" gutterBottom color="primary" startIcon={<AddIcon />}>
                                                    Discover
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Link>
                                </div>
                            )
                        })}
                   </div>
                    </>
                    }
                </Grid>
            </div>
        </Container>
        </>
    )
}

export default OurVehicles;
