import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HeroImage from '../shared/assets/ELTBuilding.png'
import backGround from '../shared/assets/backgroundCarousel.png'

import Image1 from '../shared/assets/img1.JPG'
import Image2 from '../shared/assets/img (2).JPG'
import Image3 from '../shared/assets/img (3).JPG'
import Image4 from '../shared/assets/img (4).JPG'
import Image5 from '../shared/assets/img (5).JPG'
import Image6 from '../shared/assets/img (6).JPG'
import Image7 from '../shared/assets/img (7).JPG'
import Image8 from '../shared/assets/img (8).jpg'
import Image9 from '../shared/assets/img (9).JPG'
import Image10 from '../shared/assets/img (10).JPG'
import Image11 from '../shared/assets/img (11).JPG'
import Image12 from '../shared/assets/img (12).JPG'
import Image13 from '../shared/assets/img (13).JPG'
import Image14 from '../shared/assets/img (14).JPG'
import Image15 from '../shared/assets/img (15).JPG'
import Image16 from '../shared/assets/img (16).JPG'
import Image17 from '../shared/assets/img (17).JPG'
import Image18 from '../shared/assets/img (18).JPG'
import Image19 from '../shared/assets/img (19).JPG'
import Image20 from '../shared/assets/img (20).JPG'
import Image21 from '../shared/assets/img (21).JPG'
import Image22 from '../shared/assets/img (22).JPG'
import Image23 from '../shared/assets/img (23).JPG'
import Image24 from '../shared/assets/img (24).JPG'
import Image25 from '../shared/assets/img (25).JPG'
import Image26 from '../shared/assets/img (26).JPG'
import Image27 from '../shared/assets/img (27).JPG'
import Image28 from '../shared/assets/img (28).JPG'
import Image29 from '../shared/assets/img (29).JPG'
import Image30 from '../shared/assets/img (30).JPG'
import Image31 from '../shared/assets/img (31).JPG'
import Image32 from '../shared/assets/img (32).jpg'
import Image33 from '../shared/assets/img (33).jpg'
import Image34 from '../shared/assets/img (34).jpg'
import Image35 from '../shared/assets/img (35).JPG'


import image37 from '../shared/assets/Tron Logistics-16.jpg';
import image38 from '../shared/assets/ELT Group.jpg';
import image39 from '../shared/assets/building.jpg';
import image40 from '../shared/assets/2_FRONT3Qwww_264.jpg';
import image41 from '../shared/assets/IMG_0932.jpg';
import image42 from '../shared/assets/IMG_1018.jpg';
import image43 from '../shared/assets/IMG_1025.jpg';
import image44 from '../shared/assets/IMG_1026.jpg';
import image45 from '../shared/assets/IMG_2044.jpg';
import image46 from '../shared/assets/TATA TANKERS.jpg';

import image47 from '../shared/assets/Gallery1.JPG';
import image48 from '../shared/assets/Gallery2.jpg';
import image49 from '../shared/assets/Gallery3.jpg';
import image50 from '../shared/assets/Gallery4.JPG';
import image51 from '../shared/assets/Gallery5.JPG';
import image52 from '../shared/assets/Gallery6.JPG';

import { useMediaQuery } from 'react-responsive'

const VideoArray = [
    `/static/media/VID-20230420-WA0000.mp4`,
    `/static/media/TRON+VIDEO-2023-08-22.mp4`,
    `/static/media/TATA+Skiploader.mp4`,
    `/static/media/RUBTRANS+VID.mp4`,
]

const ImageArray = [
    image47,
    image48,
    image49,
    image50,
    image51,
    image52,
    image37,
    image38,
    image39,
    image40,
    image41,
    image42,
    image43,
    image44,
    image45,
    image46,
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
    Image13,
    Image14,
    Image15,
    Image16,
    Image17,
    Image18,
    Image19,
    Image20,
    Image21,
    Image22,
    Image23,
    Image24,
    Image25,
    Image26,
    Image27,
    Image28,
    Image29,
    Image30,
    Image31,
    Image32,
    Image33,
    Image34,
    Image35,
]

const useStyles = makeStyles(() => ({
    backgroundImage: {
        backgroundImage: `linear-gradient(rgba(121, 121, 121, 0.6), rgba(121, 121, 121, 0.6)), 
        url(${HeroImage})`,
       
        width: '100vw',
        height: '80vh',
        display: 'flex',
        backgroundSize: 'cover',
        position: 'relative'
    },
    info: {
        paddingBottom: '1rem'
    },
    gallery: {
        position: 'relative',
        height: '900px',
        width: '100vw',
        backgroundImage: `url(${backGround})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        padding: '40px',
        zIndex: 0,
        '@media (max-width: 650px)': {
            padding: '20px',
            height: 'fit-content'
        }
    },
    videos: {
        height: '370px',
        width: 'calc(100vw - 40px)',
        overflowX: 'scroll',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 650px)': {
            height:  'fit-content',
        },

        '&::-webkit-scrollbar': {
            height: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 50px #fff',
            borderRadius: '4px',
            borderTop: '3px solid transparent',
            borderBottom: '3px solid transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#7fbdf3',
            borderRadius: '4px',

            
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#dfdfdf',
        }
    },
    pictures: {
        height: '200px',
        width: 'calc(100vw - 40px)',
        overflowX: 'scroll',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 650px)': {
            height:  'fit-content',
        },

        '&::-webkit-scrollbar': {
            height: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 50px #fff',
            borderRadius: '4px',
            borderTop: '3px solid transparent',
            borderBottom: '3px solid transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#7fbdf3',
            borderRadius: '4px',

            
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#dfdfdf',
        }
    },
    header: { 
        color: '#2e3975', 
        fontWeight: 'bold',
        padding: '5px'
    },
    imageText: {
        backgroundColor: 'white',
        height: '40px', 
        opacity: 0.6, 
        marginTop: '-30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: '900',
        color: '#2e3975',
    },
    paragaph: {
        fontSize: '1.4rem', 
        width: '80%', 
        justifyItems: 'center' , 
        marginBottom: '20px', 
        color: "black",
        '@media (max-width: 425px)': {
            fontSize: '0.9rem', 
        }
    },
    videoContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        paddingRight: '40px',
        '@media (max-width: 650px)': {
            padding: '10px 20px 20px 0px',
        }
    },
    videoImage: {
        height: '300px',
        bottom: 0,
        position:'relative',
        objectFit:'contain',
        objectPosition: 'center',
        '@media (max-width: 650px)': {
            width: '89vw',
            height: 'auto'
        }
    },
    pictureImage: {
        height: '150px',
        bottom: 0,
        position:'relative',
        objectFit:'contain',
        objectPosition: 'center',
        '@media (max-width: 650px)': {
            width: '89vw',
            height: 'auto',
            aspectRatio:'16/12'
        }
    },
    pictureContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        paddingRight: '20px',
        '@media (max-width: 650px)': {
            padding: '10px 20px 20px 0px',
        }
    }
}));

const AboutUsPage = () => {

    const classes = useStyles();
    const isMobile = useMediaQuery({ query: '(max-width: 624px)' })

    return (
        <>
        <div className={classes.backgroundImage}/>
        <React.Fragment>
            <div style={{background: 'linear-gradient(0deg, rgba(174,174,174,1) 0%, rgba(207,207,207,1) 40%, rgba(223,223,223,1) 100%)', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography gutterBottom component="h1" variant={isMobile? "h3":"h2"} color="black" fontWeight="900" align='center' className={classes.header}>
                ABOUT THE ELT GROUP
            </Typography>
            <Typography align="center"  paragraph gutterBottom className={classes.paragaph}>
               ELT Group is a renowned business house situated in Turffontein, South of Johannesburg. Established in 2007
            </Typography>

            <Typography align="center" paragraph gutterBottom className={classes.paragaph}>
               The group specialises in Commercial vehicles and are authorised dealers (Sales, Service and Parts) for renowned brands such as Iveco, Tata, FAW and International trucks. 
               The group is also an authorised parts and service dealer for Cummins and CAT products in addition to our used trucks and used parts business.
            </Typography>
            
            <Typography align="center" paragraph gutterBottom className={classes.paragaph}>
               Our business model caters to all the market segments and hence we strive to provide a "One-stop shop" solution for all our customers' needs.
               </Typography>
            
            <Typography align="center" paragraph gutterBottom className={classes.paragaph}>
               We at ELT Group believe that our customers play an integral role towards our road to success and our endeavour is to achieve the highest customer
               satisfaction exceeding the industry benchmarks. Our adherence to core values and continuous upgrade of skills through training and constructive interactions 
               help us create the best expierence for our customers.
               </Typography>
            
            <Typography align="center" paragraph gutterBottom className={classes.paragaph}>
              ELT group employs in excess of 100 employees and is within its Employment Equity targets.
            </Typography>
            
            </div>
            <div className={classes.gallery}>
            <Typography component="h1" variant={isMobile? "h3":"h2"} align="left"  gutterBottom style={{ color: '#fff', fontWeight: 'bold' }}>
                {'Gallery'.toUpperCase()}
                </Typography>
                <Typography component="h4" variant="h4" align="left"  gutterBottom style={{ color: '#fff', fontWeight: 'bold'}}>
                {'Video Gallery'}
                </Typography>
                <div className={classes.videos}>
                    {VideoArray.map((_video, index) => {
                        return (
                            <div className={classes.videoContainer}>
                                <video width="320" height="240" controls  className={`${classes.image} ${classes.videoImage}`} >
                                <source  src={VideoArray[index]}  type="video/mp4"/>
                           
                                Your browser does not support the video tag.
                                </video>
                            </div>
                        )
                    })}
                </div>
                <Typography component="h4" variant="h4" align="left"  gutterBottom style={{ color: '#fff', fontWeight: 'bold', paddingTop: '1rem' }}>
                {'Photo Gallery'}
                </Typography>
                <div className={classes.pictures}>
                {ImageArray.map((_image, index) => {
                        return (
                            <div className={classes.pictureContainer}>
                                <img
                                    className={`${classes.image} ${classes.pictureImage}`} 
                                    src={ImageArray[index]} 
                                    alt={`elt-${index}`}
                                    color='lightgrey'/>  
                                    {/* <div className={classes.imageText}>Image Description</div> */}
                            </div>
                        )
                    // }
                    })}
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default AboutUsPage;