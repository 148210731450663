import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import BuildIcon from '@material-ui/icons/Build';
import { NavLink as RouterLink, useParams, useHistory } from 'react-router-dom';
import { DealerContext } from '../shared/contexts/DealerContext'
import { MOTORGROUPID, BASE_URI, MASTER_DEALERID } from '../shared/Constants'
import axios from 'axios';
import Dealers from './Dealers';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        overflow: 'hidden'
    },
    black: {
        backgroundColor: '#d6ebf9',
        padding: theme.spacing(0.5, 0, 0.5),
        fontWeight: 'bold',
        color: 'white!important'
    },
    text: {

        color: 'black'
    },
    blacktext: {
        color: 'white'
    },
    yellow: {
        backgroundColor: "#2e3975",
        padding: theme.spacing(0.5, 0, 0.5),
        color: 'white!important'
    },
    dividerTitle: {
        backgroundColor: "#2e3975",
        width: '33%',
        marginBottom: '30px',
        height: '3px',
        margin: '20px auto'
    },
    dividerDealer: {
        backgroundColor: "#2e3975",
        width: '33%',
        marginBottom: '15px',
        marginTop: '15px',
        height: '2px',
    },
    fontSmall: {
        fontSize: '0.7rem'
    },
    sideBorder: {
        maxWidth: '48%',
        padding: '20px',
        margin: '10px',
        boxShadow: '2px 0px 15px -2px #adadad',
        '@media(min-width: 0px) and (max-width: 425px)': {
            maxWidth: '100%',
        },
        '@media(min-width: 426px) and (max-width: 900px)': {
            maxWidth: '100%',
        },
        '@media(min-width: 900px) and (max-width: 1023px)': {
            maxWidth: '47%',
        },  

    },
    fontSmall: {
        display: 'flex'
    },
    numberHolder: {
        display: 'flex',
        '@media (max-width: 425px)': {
            flexDirection: 'column'
        }
    },
    mapholder: {
        display: 'flex',
        '@media (max-width: 425px)': {
            flexDirection: 'column'
        }
    }
}));

const OurDealers = () => {
    const [dealerList, setDealerList] = useState([])
    const [location, setLocation] = useState()
    const [province, setProvince] = useState()
    const [district, setDistrict] = useState()
    const [make, setMake] = useState()

    useEffect(() => {

        //TODO: Fix this shitty solution
        var promise1 = axios.get(`${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`, {});

        var promise2 = axios.get(`${BASE_URI}/Dealers/DealerLocations/${MOTORGROUPID}`, {});

        Promise.all([
            promise1,
            promise2
        ]).then(([res1, res2]) => {

            var filtered1 = res1.data.filter(itm => itm.dealerId != MASTER_DEALERID);


            var filtered2 = res2.data.dealers.filter(itm => itm.id != MASTER_DEALERID);
         

            var combined = filtered1.map(itm => { return { ...itm, ...filtered2.filter(itm2 => itm2.id == itm.dealerId)[0] } });

            setDealerList(combined)
          
            setLocation(res2.data)
        });
    }, [])

    function SortArray(x, y){
        if (x.name < y.name) {return -1;}
        return 0;
    }


    
    const getDealerAddress = (dealer) => {

        if (dealer && dealer.address) {
            return dealer.address.streetAddress
        }

        return ""
    }

    const { websiteColors } = useContext(DealerContext)
    const classes = useStyles({ ...websiteColors });

    const history = useHistory();

    const cleanName = (dealerName) => {
        return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }

    return (
        <Container maxWidth="lg">
            {/* <div>
                {dealerInfo.map((info,index)=>{
                          <select>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          </select>
                })}
          

                <select>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                </select>

                <select>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                </select>
            </div> */}
            <div className={classes.content}>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                    OUR DEALER NETWORK
                </Typography>
                <Divider className={classes.dividerTitle} />
        
                <Box mb={6} mt={6}>

                </Box>
                <Grid container >
                    {dealerList.filter(x => x.name?.toLowerCase().indexOf("elt") !== -1).sort(SortArray).map((dealer, index) => {
                        return (
                            <Grid item sm={12} md={6} key={index} className={classes.sideBorder}>
                                <Box >
                                    <Typography variant="h6" >{dealer.name}</Typography>
                                    <Divider className={classes.dividerDealer} />
                                    <Box fontWeight="fontWeightBold">Address</Box>
                                    <Box className={classes.fontSmall}>{getDealerAddress(dealer)}</Box>
                                    <Box mt={2} fontWeight="fontWeightBold">Business Hours</Box>
                                    <Box className={classes.fontSmall}>Monday - Friday: 7:30 - 17:00</Box>
                                    <Box className={classes.fontSmall}>Saturday: 8:00 - 13:00</Box>
                                    <Box className={classes.fontSmall}>Sunday: Closed</Box>

                                    <Box className={classes.black}>
                                        <Button className={classes.text} onClick={() => window.location.href = `/dealer/${cleanName(dealer.name)}/used-vehicles`}>View Used Trucks From {dealer.name}</Button>
                                    </Box>
                                    <Box className={classes.yellow}>
                                        <Button className={classes.blacktext} onClick={() => window.location.href = `/dealer/${cleanName(dealer.name)}`}>Go To The {dealer.name} Website</Button>
                                    </Box>
                                    <div className={classes.mapholder}>
                                        <Grid item sm={12} md={6} >
                                            <img src={"https://c2marketdealer.blob.core.windows.net/" + dealer.websiteBannerImage} width="100%" height="100%" />
                                        </Grid>
                                        <Grid item sm={12} md={6} >
                                            <iframe src={`https://maps.google.com/maps?q=${dealer.addressLongitude},${dealer.addressLatitude}&via=0&z=16&output=embed`} height="100%" width="100%" frameBorder="none"></iframe>
                                        </Grid>
                                    </div>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </Container>
    )
}

export default OurDealers;